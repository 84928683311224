import { actions, definitions } from '@luxuryescapes/contract-svc-agent';

export type List<T> = {
  total: number;
  rows: Array<T>;
};

export type Eoi = definitions['eoiObject'];

export type ListEoiParams = actions['eoiList']['parameters']['query'];

export type ListUserParams = actions['getUsers']['parameters']['query'];

export type ListAgencyParams = actions['agencyList']['parameters']['query'];

export type Agency = definitions['agencySchema'];

export type Affiliation = definitions['maskedAffiliation'];

export type CreateAffiliationParams = actions['affiliationCreate']['parameters']['body']['payload'];

export type Agent = definitions['userSimpleSchema'];

export type MappedAgent = {
  id: string;
  name: string;
  phone: string;
  email: string;
  affiliation: string;
  customerId: string;
  agencyId: string;
  eoiSubmissionId: string;
  agencyName: string;
  status: string;
  verifiedAt: string;
};

export type AvailableAffiliations = definitions['maskedAffiliation'][];

export type Commission = definitions['commissionResponse'];

export type CommissionRule = definitions['commissionResponse']['rules'][];

export type GetAgenciesResponse = actions['agencyList']['responses']['200']['content']['application/json'];
export type GetAgentsResponse = actions['getUsers']['responses']['200']['content']['application/json'];

export type ListAffiliationsResponse = actions['affiliationList']['responses']['200']['content']['application/json'];
export type GetAffiliationResponse = actions['affiliationGet']['responses']['200']['content']['application/json'];
export type UpdateAffiliationResponse = actions['affiliationUpdate']['responses']['200']['content']['application/json'];

export type CreateCommissionParams = actions['createCommission']['parameters']['body']['createCommission'];
export type CreateCommissionResponse = actions['createCommission']['responses']['200']['content']['application/json'];

export type getCommissionsResponse = actions['getCommissionList']['responses']['200']['content']['application/json'];
export type getCommissionsParams = actions['getCommissionList']['parameters']['query'];
export type EditCommissionParams = actions['updateCommission']['parameters']['body']['payload'];

export type EditAgencyParams = actions['agencyUpdate']['parameters']['body']['agencyUpdateSchema'];
export type EditAgencyResponse = definitions['agencySchema'];

export type GetAvailableAffiliationsResponse =
  actions['affiliationListAvailable']['responses']['200']['content']['application/json'];

export type UserEmailLogsResponse =
  actions['userEmailLogs']['responses']['200']['content']['application/json']['result'];

export const EoiStatus = {
  Pending: 'pending',
  Approved: 'approved',
  Rejected: 'rejected',
  Onboarded: 'onboarded',
};
