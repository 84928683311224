import React, { useCallback, useEffect, useState } from 'react';

import currencyFormatter from 'currency-formatter';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { Link as NavLink } from 'react-router-dom';

import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { isOnNewExpiryProcess } from '~/consts/brands';

import {
  addYears,
  formatDateLongISO,
  formatDateLongMonthWithMeridiemNoSeconds,
  formatDateWeekDayShortMonth,
  formatDateWithClock,
} from '~/services/TimeService';

import GridPagination from '../../Common/Elements/GridPagination';
import PageSubheader from '../../Common/Elements/PageSubheader';
import CreditsForm from '../../Common/Forms/CreditsForm';
import PermissionedComponent from '../../Common/PermissionedComponent';
import { withTenant } from '../../hoc';

import ChangeExpiryDateModal from './ChangeExpiryDateModal';
import { BasicUser, CreditCommentMap, Credits, ReconciledCredits } from './types';

type Props = {
  currency: string;
  user: App.User;
  idUser: string;
  currentPage: number;
  initiators: BasicUser[];
  tenant: App.Tenant;
  credits: Credits;
  creditTypes: unknown;
  isLoading: boolean;
  onPageChange: (page: number) => void;
  rerender: () => void;
  reconciledCredits: ReconciledCredits;
  creditCommentMap: CreditCommentMap;
};

const CreditsPage: React.FC<Props & WithSnackbarProps> = (props) => {
  const {
    currency,
    user,
    idUser,
    currentPage,
    initiators,
    tenant,
    credits,
    creditTypes,
    isLoading,
    onPageChange,
    rerender,
    reconciledCredits,
    creditCommentMap,
  } = props;

  const currencyFormat = useCallback(
    (amount: number) => {
      return currencyFormatter.format(amount, {
        code: currency,
        precision: 2,
      });
    },
    [currency],
  );

  const getLatestDate = useCallback((dates: { expires_at: string }[]) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore because TS does't like dates and math
    return formatDateLongISO(Math.max(...dates.map((e) => new Date(e.expires_at))));
  }, []);

  // the expiry date for all user's credits is the latest date from all of their credits,
  // or 1 year from now if there are no credits
  const calculateExpiryDate = useCallback(() => {
    if (credits.result.collection.length > 0) {
      return getLatestDate(credits.result.collection);
    } else {
      return formatDateLongISO(addYears(1));
    }
  }, [credits.result.collection, getLatestDate]);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [creditsSubtitle, setCreditsSubtitle] = useState<string>('');
  const [allExpiresAt, setAllExpiresAt] = useState<string>('');
  const [changeExpiryDateRequestState, setChangeExpiryDateRequestState] = useState<Utils.FetchingState>('idle');

  useEffect(() => {
    setCreditsSubtitle(currencyFormat(Number(credits.metadata.available_credit)));
    setAllExpiresAt(calculateExpiryDate());
  }, [calculateExpiryDate, credits, currencyFormat]);

  const creditFormat = useCallback(
    (value: string) => {
      const amount = parseFloat(value);
      return amount > 0 ? currencyFormat(amount) : '-';
    },
    [currencyFormat],
  );

  const debitFormat = useCallback(
    (value: string) => {
      const amount = parseFloat(value);
      return amount < 0 ? currencyFormat(Math.abs(amount)) : '-';
    },
    [currencyFormat],
  );

  const addedByLink = useCallback(
    (fk_user_id: string) => {
      const initiator = initiators.find((i) => i.id_member === fk_user_id);
      return (
        <Link component={NavLink} to={`/users/${fk_user_id}`}>
          {initiator ? initiator.fullName : 'Unknown'}
        </Link>
      );
    },
    [initiators],
  );

  const creditComments = useCallback(
    (comments: string, { fk_orders }: { fk_orders: string }) => (
      <Box overflow="hidden">
        <Typography whiteSpace="normal">{comments}</Typography>
        {fk_orders && (
          <Typography noWrap>
            Order:{' '}
            <Link component={NavLink} to={`/purchases/${fk_orders}`}>
              {fk_orders}
            </Link>
          </Typography>
        )}
      </Box>
    ),
    [],
  );

  const toggleCreditExtensionModal = useCallback(() => {
    setIsModalOpen((prev) => !prev);
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'created_at',
      headerName: 'Created Date',
      sortable: false,
      width: 150,
      valueFormatter: (value) => {
        return formatDateWeekDayShortMonth(value);
      },
      display: 'flex',
    },
    {
      field: 'amount_credit',
      headerName: 'Credit',
      sortable: false,
      width: 120,
      renderCell: (params) => {
        return creditFormat(params.row.amount);
      },
      display: 'flex',
    },
    {
      field: 'amount_debit',
      headerName: 'Debit',
      sortable: false,
      width: 120,
      renderCell: (params) => {
        return debitFormat(params.row.amount);
      },
      display: 'flex',
    },
    {
      field: 'fk_added_by',
      headerName: 'Added by',
      sortable: false,
      width: 200,
      renderCell: (params) => addedByLink(params.value),
      display: 'flex',
    },
    {
      field: 'credit_type',
      headerName: 'Credit type',
      sortable: false,
      width: 180,
      display: 'flex',
    },
    {
      field: 'expires_at',
      headerName: 'Expiry Date',
      sortable: false,
      valueFormatter: (value: string) => {
        if (isOnNewExpiryProcess(tenant.brand)) {
          return formatDateWithClock(value);
        } else {
          return formatDateWithClock(allExpiresAt);
        }
      },
      width: 180,
      display: 'flex',
    },
    {
      field: 'comments',
      headerName: 'Comment',
      sortable: false,
      flex: 1,
      renderCell: (params) => creditComments(params.value, params.row),
      display: 'flex',
    },
    {
      field: 'currency',
      headerName: 'Currency',
      display: 'flex',
    },
  ];

  return (
    <div>
      <Helmet>
        <title>Users | {user.fullName} | Credits</title>
      </Helmet>
      <PageSubheader title="Add credits" />
      <CreditsForm
        credits={credits}
        creditTypes={creditTypes}
        currency={currency}
        id_user={idUser}
        rerender={rerender}
        updateAllExpiryDate={setAllExpiresAt}
      />
      {!isLoading && <PageSubheader title={`Total Credit: ${creditsSubtitle}`} />}
      <Box my={1}>
        <Stack direction="row" alignItems="center" spacing={1}>
          {!isOnNewExpiryProcess(tenant.brand) && (
            <Stack flexGrow={1} direction="row" spacing={1}>
              <Typography variant="h6">All credit expires at:</Typography>
              <Typography variant="h6" color="textSecondary" fontWeight="normal">
                {formatDateLongMonthWithMeridiemNoSeconds(allExpiresAt)}
              </Typography>
            </Stack>
          )}
          {isOnNewExpiryProcess(tenant.brand) && credits.result.led_next_expiring && (
            <Stack flexGrow={1} direction="row" spacing={1}>
              <Typography variant="h6">Next credit expires at:</Typography>
              <Typography variant="h6" color="textSecondary" fontWeight="normal">
                {formatDateLongMonthWithMeridiemNoSeconds(credits.result.led_next_expiring.expires_at)}
              </Typography>
            </Stack>
          )}
          {isOnNewExpiryProcess(tenant.brand) && !credits.result.led_next_expiring && (
            <Stack flexGrow={1} direction="row" spacing={1}>
              <Typography variant="h6">No credits to expire</Typography>
            </Stack>
          )}
          <PermissionedComponent>
            <Box>
              <Button
                type="submit"
                onClick={toggleCreditExtensionModal}
                variant="contained"
                disabled={changeExpiryDateRequestState === 'loading'}
              >
                Change expiry date
              </Button>
            </Box>
          </PermissionedComponent>
        </Stack>
      </Box>
      <DataGrid
        rows={credits.result.collection}
        rowCount={credits.metadata.total_available}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        loading={isLoading}
        paginationModel={{ page: currentPage - 1, pageSize: 10 }}
        paginationMode="server"
        pageSizeOptions={[10]}
        getRowId={(row) => row.id_credit}
        getRowHeight={() => 'auto'}
        onPaginationModelChange={({ page }) => onPageChange(page + 1)}
        slots={{ pagination: GridPagination }}
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        autoHeight
      />
      <ChangeExpiryDateModal
        reconciledCredits={reconciledCredits}
        setChangeExpiryDateRequestState={setChangeExpiryDateRequestState}
        userId={idUser}
        setIsModalOpen={setIsModalOpen}
        rerender={rerender}
        isModalOpen={isModalOpen}
        currency={currency}
        creditCommentMap={creditCommentMap}
        isOnNewExpiryProcess={isOnNewExpiryProcess}
      />
    </div>
  );
};

export default withTenant(withSnackbar(CreditsPage));
