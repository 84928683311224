import React from 'react';

import ContentWithSidebar from '../ContentWithSidebar';

import CreditExtensions from './CreditExtensions';
import CustomOffersSummaryForm from './CustomOffersSummaryForm';
import EmailChangeReportForm from './EmailChangeReportForm';
import RefundsOutsidePolicy from './RefundsOutsidePolicy';

const DEFAULT_ITEM = 'credit-extensions';

const items = [
  {
    title: 'Credit extensions',
    slug: 'credit-extensions',
    component: CreditExtensions,
  },
  {
    title: 'Custom offers summary',
    slug: 'custom-offers-summary',
    component: CustomOffersSummaryForm,
  },
  {
    title: 'Email change report',
    slug: 'email-change-report',
    component: EmailChangeReportForm,
  },
  {
    title: 'Refunds outside policy',
    slug: 'refunds-outside-policy',
    component: RefundsOutsidePolicy,
  },
];

export default function OtherPageContainer() {
  return <ContentWithSidebar items={items} defaultItem={DEFAULT_ITEM} />;
}
