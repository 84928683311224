import qs from 'qs';

import { definitions, operations } from '@luxuryescapes/contract-svc-promo';

import { dateNow, formatDateDateTimeWidget } from './TimeService';
import { request } from './common';

function basePath() {
  return window.configs.API_HOST + '/api/';
}

export type PromoMeta = operations['getPromoMeta']['responses']['200']['content']['application/json']['result'];

export type ReferralConfig = definitions['GlobalReferralConfig'];

export type PromoToggleKey = PromoMeta['promo_toggles']['0']['key'];
export type PromoToggleConfig = PromoMeta['promo_toggles'];

export type PromoRequestSource =
  operations['calculateDiscount']['parameters']['body']['Discount Request']['requestSource'];

type PromoRequestWarn = {
  message: string;
  severity: 'error' | 'warning' | 'info';
};

export type PromoCondition = Omit<definitions['PromoCodeConditionPresented'], 'id_promo_code_condition'> &
  Partial<Pick<definitions['PromoCodeCondition'], 'id_promo_code_condition'>> & {
    isNew: boolean;
    id?: string;
  };

export type InternalPromoWithDiscounts = definitions['InternalPromoWithDiscounts'] & {
  promo_conditions: PromoCondition[];
};

/**
 * We include 'unknown' to account for <PromoLogs/> 'ALL' view which does not have the promo_type readily available (yet)
 */
export type PromoTypesAndUnknown =
  | operations['calculateDiscount']['responses']['200']['content']['application/json']['result']['promo']['promo_type']
  | 'unknown';

export type PromoRequest = definitions['Discount Request'];

export type GetPromoUserFilters = operations['getPromoUsers']['parameters']['query'];

export type PromoUser =
  operations['getPromoUsers']['responses']['200']['content']['application/json']['result']['promoUsers']['0'];

// TODO: remove with fixed contract type
type RequestItem =
  operations['calculateDiscount']['responses']['200']['content']['application/json']['result']['promo']['items']['0'] & {
    travellers: {
      firstName: string;
      lastName: string;
    };
  };

export type DiscountItemInfo = {
  rId: string;
  item: {
    poffer: {
      itemId?: string | null;
      offerId: string;
      productBK: string;
      categoryBK: string;
      isStaffDiscountEnabled: boolean;
    };
    reqItem: RequestItem;
  };
  warning: string;
  discount: number;
  discountAmount: number;
  discountType?: 'percentage' | 'fixed_amount';
  matchingDiscount?: {
    region: string;
    products?: string[];
    min_spend?: number | null;
    created_at: string;
    updated_at: string;
    modified_by: string;
    max_discount?: number | null;
    id_promo_code: string;
    discount_value: number;
    id_promo_code_discount: string;
    subscription_item_discount_type: string;
    subscription_item_discount_value: number;
  };
  luxPlusDiscountHigher: boolean;
  doesNotQualifyWithMemberPricing: boolean;
  discountHasCombinedWithLuxPlusPricing: boolean;
};

// TODO: update with contract type after merge
function getOrderCompareWarnings(
  order: definitions['Discount Request']['order'],
  orderV2: definitions['Discount Request']['preCheckoutOrder'],
): Array<PromoRequestWarn> {
  //definitions['PromoCodeRequest']
  const warnings: Array<PromoRequestWarn> = [];
  if (!orderV2 || !order) {
    warnings.push({ message: 'No body found', severity: 'error' });
    return warnings;
  }

  if (orderV2.items.length !== order.items.length) {
    warnings.push({
      message: `item count mismatch (new:${orderV2.items.length} != old:${order.items.length})`,
      severity: 'error',
    });
  }

  order.items.forEach((item, index) => {
    const itemV2 = orderV2.items.filter((i) => i?.itemId === item?.itemId || i?.offerId == item?.offerId)[0];

    if (!itemV2) {
      warnings.push({ message: `item not found in new order: ${item.itemId}`, severity: 'error' });
    } else {
      if (itemV2.itemId !== item.itemId) {
        warnings.push({ message: `itemId mismatch (new:${itemV2.itemId} != old:${item.itemId})`, severity: 'error' });
      }

      if (itemV2.offerId !== item.offerId) {
        warnings.push({ message: `offerId mismatch (new:${itemV2.itemId} != old:${item.itemId})`, severity: 'error' });
      }

      if (itemV2.discountableTotal !== item.discountableTotal) {
        warnings.push({
          message: `discountableTotal mismatch (new:${itemV2.discountableTotal} != old:${item.discountableTotal})`,
          severity: 'error',
        });
      }

      if (itemV2.luxPlusPrice !== item.luxPlusPrice) {
        warnings.push({
          message: `luxPlusPrice mismatch (new:${itemV2.luxPlusPrice} != old:${item.luxPlusPrice})`,
          severity: 'error',
        });
      }
    }
  });

  return warnings;
}

export const sortPromoItemByCategoryAndId = (a, b) => {
  if (a.categoryBK === b.categoryBK) {
    const idA = a.offerId || a.itemId || '';
    const idB = b.offerId || b.itemId || '';

    return idA.localeCompare(idB);
  }

  return a.categoryBK.localeCompare(b.categoryBK);
};

export const displayItemType = (i): string => {
  return `${i.categoryBK}
    ${i.parentType !== i.categoryBK ? `/${i.parentType}` : ''}              
    ${i.itemType !== i.categoryBK ? `/${i.itemType}` : ''}`;
};

export const hasAdvanceRestrictions = (promoCode): boolean => {
  if (!promoCode) {
    return false;
  }
  return (
    promoCode?.email_addresses?.length > 0 ||
    promoCode?.email_domains?.length > 0 ||
    promoCode?.offer_whitelist?.length > 0 ||
    promoCode?.offer_blacklist?.length > 0 ||
    promoCode?.bin_numbers?.length > 0 ||
    promoCode?.allowed_payment_methods?.length > 0 ||
    promoCode?.promo_toggles?.length > 0 ||
    promoCode?.device_types?.length > 0 ||
    promoCode?.is_corporate
  );
};

export const getPromoRequestWarnings = (promoRequest: definitions['PromoCodeRequest']): Array<PromoRequestWarn> => {
  const warnings: Array<PromoRequestWarn> = [];
  const reqBody = promoRequest.bodyJSON as unknown as definitions['Discount Request'];

  warnings.concat(getOrderCompareWarnings(reqBody.order, reqBody.preCheckoutOrder));

  if (!reqBody) {
    return warnings;
  } else {
    if (promoRequest.statusCode === 500) {
      warnings.push({ message: 'Internal Server Error', severity: 'error' });
    }
  }
  return warnings;
};
interface ProductTypeOption {
  product_bk: string;
  category_bk: string;
  value: definitions['Item Product BK Options'];
  label: string;

  /**
   * @description If true, this option will be hidden from the UI (but still available if already set on the promo code)
   */
  preferToHide?: boolean;
}

export const getProductTypeOptions = (
  promoProductTypes: definitions['PromoMeta']['promo_product_types'],
  filterFields = [],
): ProductTypeOption[] => {
  return promoProductTypes.flatMap((ppt) => {
    const prods = ppt.products
      .filter((p) => !p.dontShowInAdmin)
      .filter((p) => filterFields.length === 0 || filterFields.includes(p.product_bk));

    return prods.map((product) => {
      const value = `${ppt.category_bk}-${product.product_bk}`;
      return {
        product_bk: product.product_bk,
        category_bk: ppt.category_bk,
        value: value as definitions['Item Product BK Options'],
        label: product.productAdminName,
      };
    });
  });
};

export function getPromoProductDisplayName(
  promoProductTypes: definitions['PromoMeta']['promo_product_types'],
  categoryBK: string,
  subCategoryBK: string,
): string {
  if (!promoProductTypes) {
    return `${categoryBK}-${subCategoryBK}....`;
  }

  const productMatch = promoProductTypes
    .flatMap((ppt) => ppt.products)
    .find((p) => {
      return subCategoryBK === `${categoryBK}-${p.product_bk}`;
    });
  if (productMatch) {
    return productMatch.productAdminName;
  }

  const categoryMatch = promoProductTypes.find((ppt) => ppt.category_bk === categoryBK);
  if (categoryMatch) {
    return categoryMatch.categoryName;
  }

  return `${categoryBK}-${subCategoryBK}`;
}

export async function addPromo(object) {
  return request(basePath() + 'promo', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(object),
  });
}

export async function createOrUpdatePromoCondition(
  payload: operations['createOrUpdatePromoCodeCondition']['parameters']['body']['payload'],
) {
  const url = `${basePath()}${
    payload.promoCodeCondition.id_promo_code_condition
      ? `promo/condition/${payload.promoCodeCondition.id_promo_code_condition}`
      : 'promo/condition'
  }`;
  return request(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });
}

export function deletePromoCodeCondition(id: string) {
  return request(`${basePath()}promo/condition/${id}`, {
    method: 'DELETE',
  });
}

export function deletePromoUser(id: string) {
  return request(`${basePath()}promo/users/${id}`, {
    method: 'DELETE',
  });
}

export type GetPromoCodeProps = {
  page: number;
  codeName: string;
  emailAddress: string;
  brand: string;
  limit: number;
  caseId: string;
  expiresAfter: string;
  noChildPromos: boolean;
  excludeReferralPromos?: boolean;
  userIdInteracted?: string;
  showErrors?: boolean;
  deptTag: string;
  categoryTag: string;
  subCategoryTag: string;
  isDev: boolean;
};

export async function getPromoCodes(props: GetPromoCodeProps) {
  const query: operations['searchPromos']['parameters']['query'] = {
    page: props.page?.toString(),
    limit: props.limit?.toString(),
    brand: props.brand as operations['searchPromos']['parameters']['query']['brand'],
    code_name: props.codeName,
  };

  if (props.page == undefined) {
    query.page = '1';
  }
  if (!props.limit) {
    query.limit = '10';
  }
  if (!props.brand) {
    query.brand = 'luxuryescapes';
  }
  if (props.userIdInteracted?.length > 0) {
    query.user_id_interacted = props.userIdInteracted;
  }
  if (props.caseId?.length > 0) {
    query.case_id = props.caseId;
  }

  if (props.emailAddress?.length > 0) {
    query.email_address = props.emailAddress;
  }
  if (props.expiresAfter?.length > 0) {
    query.expires_after = props.expiresAfter;
  }

  if (props.noChildPromos) {
    query.exclude_child_promos = props.noChildPromos ? 'true' : 'false';
  }
  if (props.excludeReferralPromos) {
    query.exclude_referral_promos = props.excludeReferralPromos ? 'true' : 'false';
  }
  if (props.deptTag) {
    query.dept_tag = props.deptTag;
  }

  if (props.categoryTag) {
    query.category_tag = props.categoryTag;
  }
  if (props.subCategoryTag) {
    query.sub_category_tag = props.subCategoryTag;
  }
  const queryString = qs.stringify(query);
  return request(`${basePath()}promo/v2/codes?${queryString}`, { method: 'GET' });
}

/**
 * @deprecated This legacy endpoint does not support orders with multiple items, or promos with multiple discounts. The log endpoint will always apply new server-side validation and order placed with promos via this endpoint can cause "Promo amount does not match the promo definition" errors. Use with extreme caution and consider the /api/promo/discount endpoint instead.
 */
export async function getPromo(object) {
  const queryParams = {
    brand: object.brand,
    user_id: object.user_id,
  };
  const queryString = qs.stringify(queryParams);
  return request(`${basePath()}promo/codes/${object.code_name}?${queryString}`, {
    method: 'GET',
  });
}

interface GetInternalPromoProps {
  code_name: string;
  brand: string;
}

export async function getInternalPromo({ brand, code_name }: GetInternalPromoProps) {
  const queryParams = {
    brand: brand,
  };
  const encodedCodeName = encodeURIComponent(code_name);
  const queryString = qs.stringify(queryParams);
  return request(`${basePath()}promo/code/${encodedCodeName}?${queryString}`, {
    method: 'GET',
  });
}

type getPromoChildrenProps = {
  id_promo_code: string;
  page: number;
  limit: number;
};

export async function getPromoClones({ id_promo_code, page, limit }: getPromoChildrenProps) {
  const queryParams = {
    page: page,
    limit: limit,
  };
  const queryString = qs.stringify(queryParams);
  return request(`${basePath()}promo/id/${id_promo_code}/clones?${queryString}`, {
    method: 'GET',
    accept: 'application/json',
  });
}

type BulkCreatePromosProps = {
  promo_prefix: string;
  number_to_create: number;
  expires_at: string;
  id_promo_code: string;
};

export async function bulkCreatePromos({
  id_promo_code,
  number_to_create,
  expires_at,
  promo_prefix,
}: BulkCreatePromosProps) {
  return request(`${basePath()}promo/id/${id_promo_code}/bulk`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      promo_prefix,
      number_to_create,
      expires_at,
    }),
  });
}

export async function getReferralPromo({ codeName, regionCode, brand, customerId, cartValue }) {
  return request(`${basePath()}referral/promo`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      code: codeName,
      country_code: regionCode,
      brand,
      id_account: customerId,
      cart_value: cartValue,
    }),
  });
}

export async function getPromoById({ idPromo, brand = undefined }) {
  return request(`${basePath()}promo/id/${idPromo}?${qs.stringify({ brand })}`, {
    method: 'GET',
  });
}

export async function getLogsByPromo(code_name, brand) {
  return request(`${basePath()}promo/log/${code_name}?${brand ? `brand=${brand}` : ''}`, {
    method: 'GET',
  });
}

export async function debugPromoByOrderID(
  code_name: string,
  orderId: string,
  brand: string,
  applyWithOriginalUserAccount: boolean,
) {
  return request(
    `${basePath()}promo/discount/${code_name}/orderId/${orderId}?brand=${brand}&useOrignalUserAccount=${applyWithOriginalUserAccount}`,
    {
      method: 'GET',
    },
  );
}

export type PromoRequestFilters = {
  codeName: string;
  userId: string;
  brand: definitions['Promo Brands'];
  errorCode: string;
  page: number;
  limit: number;
};

type PromoRequestRes = {
  status: number;
  message?: string;
  result: {
    promoRequests: Array<PromoRequest>;
  };
  total: number;
};

export function getPromoCodeRequestsParams(
  filters: PromoRequestFilters,
): operations['getPromoRequests']['parameters']['query'] {
  const config: operations['getPromoRequests']['parameters']['query'] = {
    page: filters.page.toString(),
    limit: filters.limit.toString(),
  };

  if (filters.userId) {
    config.userId = filters.userId;
  }
  if (filters.codeName) {
    config.codeName = filters.codeName;
  }

  if (filters.errorCode) {
    config.errorCode = filters.errorCode as operations['getPromoRequests']['parameters']['query']['errorCode'];
  }

  if (filters.brand) {
    config.brand = filters.brand as operations['getPromoRequests']['parameters']['query']['brand'];
  }
  return config;
}

export type PromoFailure =
  operations['getPromoLogFailures']['responses']['200']['content']['application/json']['result']['promoFailures'];

type PromoFailureRes = {
  status: number;
  message?: string;
  count: number;
  result: {
    promoFailures: Array<PromoFailure>;
  };
  total: number;
};

export type PromoFailureFilters = operations['getPromoLogFailures']['parameters']['query'];

export async function getPromoCodeFailures(qp: PromoFailureFilters): Promise<PromoFailureRes> {
  const queryParams = qs.stringify(getPromoCodeFailuresParams(qp));
  return request(`${basePath()}promo/failures?${queryParams}`, {
    method: 'GET',
  });
}

export type ProcessReferralLogFilters = operations['processReferralLogs']['parameters']['body']['payload'];

export async function processReferralLogs(payload: ProcessReferralLogFilters) {
  return await request(`${basePath()}/referral/logs`, {
    method: 'POST',
    body: payload,
  });
}

export function getPromoCodeFailuresParams(
  filters: PromoFailureFilters,
): operations['getPromoLogFailures']['parameters']['query'] {
  const config: operations['getPromoLogFailures']['parameters']['query'] = {
    page: filters.page,
    limit: filters.limit,
    brand: filters.brand,
  };

  if (filters.user_id) {
    config.user_id = filters.user_id;
  }
  if (filters.code_name) {
    config.code_name = filters.code_name;
  }

  if (filters.brand) {
    config.brand = filters.brand as operations['getPromoLogFailures']['parameters']['query']['brand'];
  }
  return config;
}

export async function getPromoCodeRequests(qp: PromoRequestFilters): Promise<PromoRequestRes> {
  const queryParams = qs.stringify(getPromoCodeRequestsParams(qp));
  return request(`${basePath()}promo/requests?${queryParams}`, {
    method: 'GET',
  });
}

export async function updatePromo(id, brand, promoData) {
  return request(`${basePath()}promo/id/${id}?brand=${brand}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(promoData),
  });
}

export async function expirePromo(id, brand) {
  return request(`${basePath()}promo/id/${id}?${qs.stringify({ brand })}`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      expires_at: dateNow(),
    }),
  });
}

export async function getGiftCardLogs(giftCardCode, brand) {
  return request(`${basePath()}gift-card/log/${giftCardCode}?${qs.stringify({ brand })}`, {
    method: 'GET',
  });
}

export async function redeemGiftCard(code, customerId, brand) {
  return request(`${basePath()}gift-card/code/${code}/redeem?${qs.stringify({ brand })}`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      customerIdFromAdmin: customerId,
      gift_card_code: code,
    }),
  });
}

export async function listInAppPromoMessage(brand, region, status, startAfter, endBefore) {
  return request(
    `${basePath()}promo/feature-schedule/list?${qs.stringify({
      brand,
      region,
      active: status,
      startAfter: formatDateDateTimeWidget(startAfter),
      endBefore: formatDateDateTimeWidget(endBefore),
    })}`,
    {
      method: 'GET',
    },
  );
}

export async function getSchemaForInAppMessagePromo() {
  return request(`${basePath()}promo/feature-schedule`, {
    method: 'OPTIONS',
  });
}

export async function createOrUpdateInAppMessagePromo(
  id,
  codeName,
  region,
  status,
  brand,
  startAt,
  endAt,
  heading,
  details,
) {
  return request(`${basePath()}promo/feature-schedule`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      featuredPromoSchedule: {
        id,
        codeName,
        region,
        active: status,
        brand,
        startAt: formatDateDateTimeWidget(startAt),
        endAt: formatDateDateTimeWidget(endAt),
        heading,
        details,
      },
    }),
  });
}

export async function deleteInAppMessagePromoById(id) {
  return request(`${basePath()}promo/feature-schedule/${id}`, {
    method: 'DELETE',
    ignoreResponse: true,
  });
}

export async function addCompedPackage(object) {
  return request(basePath() + 'promo/comped-package', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(object),
  });
}

export async function getCompedPackages(brand = 'luxuryescapes', page = 1, limit = 10) {
  const params = {
    brand,
    page,
    limit,
  };

  return request(`${basePath()}promo/comped-package?${qs.stringify(params)}`, {
    method: 'GET',
  });
}

export async function getCompedPackageById(id, brand = 'luxuryescapes') {
  return request(`${basePath()}promo/comped-package/${id}?${qs.stringify({ brand })}`, {
    method: 'GET',
  });
}

export async function updateCompedPackage(id, compedData, brand = 'luxuryescapes') {
  return request(`${basePath()}promo/comped-package/${id}?brand=${brand}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(compedData),
  });
}

export async function getBundleByOfferIdRegionAndBrand(offer_id, region, brand) {
  return request(
    `${basePath()}promo/bundle?${qs.stringify({
      offer_id,
      region,
      brand,
    })}`,
    {
      method: 'GET',
    },
  );
}

export async function createOrUpdateBundle(object) {
  return request(`${basePath()}promo/bundle`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(object),
  });
}

export async function deleteBundleById(object) {
  return request(`${basePath()}promo/bundle/`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(object),
  });
}

export async function getPromoUsers(props: GetPromoUserFilters) {
  const queryParameters = {
    page: props.page,
    limit: props.limit,
    code_name: props.code_name ? props.code_name : null,
    user_id: props.user_id ? props.user_id : null,
    id_promo_code: props.id_promo_code ? props.id_promo_code : null,
    brand: props.brand,
    region: props.region,
    expires_after: props.expires_after,
  };

  return request(`${basePath()}promo/users?${qs.stringify(queryParameters, { skipNulls: true })}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
}

export async function createOrUpdatePromoDiscount(discountObject) {
  return request(
    `${basePath()}promo/id/${discountObject.id_promo_code}/discount/${discountObject.id_promo_code_discount}`,
    {
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ promoDiscount: discountObject }),
      ignoreResponse: true,
    },
  );
}

export async function getPromoDiscountById(id_promo_code, brand) {
  return request(`${basePath()}promo/id/${id_promo_code}/discount?brand=${brand}`, {
    method: 'get',
    headers: { 'Content-Type': 'application/json' },
  });
}

export async function getPromoDiscountByIds(
  ids: string[],
): Promise<definitions['Internal Promo Discount Presented'][]> {
  const query = qs.stringify({
    ids: ids,
  });
  return request(`${basePath()}promo/discounts?${query}`, {
    method: 'get',
    headers: { 'Content-Type': 'application/json' },
  }).then(
    (response: operations['getPromoDiscountsById']['responses']['200']['content']['application/json']) =>
      response.result.promoDiscounts,
  );
}

export function deletePromoDiscount(promoId: string, id: string) {
  return request(`${basePath()}promo/id/${promoId}/discount/${id}`, {
    method: 'DELETE',
  });
}

export async function getPromoWarningsByIds(ids: string[]): Promise<definitions['PromoWarning'][]> {
  const query = qs.stringify({
    ids: ids,
  });
  return request(`${basePath()}promo/warnings?${query}`, {
    method: 'get',
    headers: { 'Content-Type': 'application/json' },
  }).then(
    (response: operations['getPromoWarnings']['responses']['200']['content']['application/json']) => response.result,
  );
}

type ReferralLogResponse = {
  config: ReferralConfig;
  result: {
    logs: Array<unknown>;
    total: number;
  };
};

export async function getReferralLogs(
  brand,
  userId,
  logStatus,
  createdAfter,
  orderId,
  pageNum,
  pageSize,
): Promise<ReferralLogResponse> {
  const queryParameters: operations['getReferralLogs']['parameters']['path'] = {
    ...(userId && { user_id: userId }),
    ...(logStatus && logStatus !== 'all' && { referral_log_status: logStatus }),
    ...(createdAfter && { created_before: createdAfter }),
    ...(orderId && { order_id: orderId }),
    page_num: pageNum ?? 1,
    page_size: pageSize ?? 20,
  };

  return request(`${basePath()}referral/logs/debug?${qs.stringify(queryParameters)}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
}

type ReferralLogResetResponse = operations['resetReferralLog']['responses']['200']['content']['application/json'];

export async function resetPromoLog(referralLogId): Promise<ReferralLogResetResponse> {
  return request(`${basePath()}referral/logs/${referralLogId}/reset`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
}

export async function getPromoDiscountSchema() {
  return request(
    `${basePath()}promo/id/241bdbd6-0a4c-4470-8495-4ded391a3728/discount/241bdbd6-0a4c-4470-8495-4ded391a3728`,
    {
      method: 'OPTIONS',
    },
  );
}

export async function quickAddPromo(object) {
  return request(basePath() + 'promo/quickCreate', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(object),
  });
}

export async function quickAddPromoOptions() {
  return request(basePath() + 'promo/quickCreate', {
    method: 'OPTIONS',
  });
}

export async function getPromoMeta(): Promise<
  operations['getPromoMeta']['responses']['200']['content']['application/json']
> {
  return request(`${basePath()}promo/meta`, {
    method: 'get',
    headers: { 'Content-Type': 'application/json' },
  });
}
