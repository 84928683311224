import React from 'react';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Box, Divider, Link, Stack, Typography } from '@mui/material';

import { CruisesContract } from '@luxuryescapes/contract-svc-cruise';

import useCurrentTenant from '~/hooks/useCurrentTenant';

import { formatDateShort } from '~/services/TimeService';
import { customerPortalHost } from '~/services/common';

import currencyFormatter from '~/utils/currencyFormatter';

import { CruiseDepositDetails, CruiseOrderItem } from './constants';

const formatExternalDates = (date: string | Date): string => {
  return `${formatDateShort(date)}`;
};

type Props = {
  cruiseItem: CruiseOrderItem;
  departure: CruisesContract.DepartureByIdResponse;
  bookingInfo: CruisesContract.BookingOrderResponse;
  depositDetails?: CruiseDepositDetails;
};

export default function CruiseOrderItemDetails(props: Props) {
  const { departure, cruiseItem, bookingInfo, depositDetails } = props;

  const { tenant } = useCurrentTenant();

  const hasConversion =
    bookingInfo.baseCurrency &&
    bookingInfo.convertedCurrency &&
    bookingInfo.baseCurrency !== bookingInfo.convertedCurrency;

  const firstTraveller = bookingInfo.travellersInfo[0];

  return (
    <Box p={2}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Box flexGrow={1}>
          <Link
            target="_blank"
            rel="noreferrer"
            href={`${customerPortalHost(tenant)}/cruises/${departure.offerId}`}
            variant="h6"
            underline="hover"
          >
            Offer name: {departure.offer.displayName ?? departure.name}
          </Link>
        </Box>
      </Stack>

      <Stack mt={4} spacing={4} direction="row" divider={<Divider orientation="vertical" flexItem />}>
        <Stack direction="column" spacing={0.5}>
          <Typography variant="h6">Sailing Details</Typography>
          <Typography variant="body1">
            Vendor Code: <b>{bookingInfo.vendorCode}</b>
          </Typography>
          <Typography variant="body1">
            Vendor: <b>{departure.cruiseLine}</b>
          </Typography>

          <Typography variant="body1">
            Ship: <b>{departure.ship.name}</b>
          </Typography>

          <Typography variant="body1">
            Departure Date: <b>{formatDateShort(bookingInfo.departureDate)}</b>
          </Typography>
          <Typography variant="body1">
            Arrival Date: <b>{formatDateShort(cruiseItem.arrival_date)}</b>
          </Typography>
        </Stack>

        <Stack direction="column" spacing={0.5}>
          <Typography variant="h6">Customer details - Booking contact</Typography>
          <Typography variant="body1">
            Type: <b>{firstTraveller.type}</b>
          </Typography>
          <Typography variant="body1">
            Name:{' '}
            <b>
              {firstTraveller.firstName} {firstTraveller.middleName} {firstTraveller.lastName}
            </b>
          </Typography>
          <Typography variant="body1">
            Phone: <b>{firstTraveller.phone}</b>
          </Typography>
          <Typography variant="body1">
            Email: <b>{firstTraveller.email}</b>
          </Typography>
          <Typography variant="body1">
            Date of Birth: <b>{formatDateShort(firstTraveller.dateOfBirth)}</b>
          </Typography>
        </Stack>

        <Stack spacing={3} divider={<Divider orientation="horizontal" flexItem />}>
          <Stack direction="column" spacing={4}>
            <Typography variant="h6">Customer payment schedule</Typography>

            <Stack direction="column" spacing={0.5}>
              <Typography variant="h6" fontWeight="bold">
                {depositDetails ? 'Deposit' : 'Full Payment'}
              </Typography>
              <Typography variant="body1">
                Amount:{' '}
                <b>
                  {depositDetails
                    ? currencyFormatter(depositDetails.depositCurrencyCode, depositDetails.depositAmount, 2, 'code')
                    : currencyFormatter(bookingInfo.convertedCurrency, bookingInfo.totalAmount, 2, 'code')}
                </b>
              </Typography>
              <Typography variant="body1">Charged at checkout</Typography>
            </Stack>
            {depositDetails && (
              <Stack direction="column" spacing={0.5}>
                <Typography variant="h6" fontWeight="bold">
                  Balance
                </Typography>
                <Typography variant="body1">
                  Amount:{' '}
                  <b>
                    {currencyFormatter(depositDetails.depositCurrencyCode, depositDetails.balanceAmount, 2, 'code')}
                  </b>
                </Typography>
                <Typography variant="body1">
                  Customer Due Date: <b>{formatExternalDates(depositDetails.balanceDueDate)}</b>
                </Typography>
              </Stack>
            )}
          </Stack>

          {hasConversion && bookingInfo.convertedCurrency && bookingInfo.baseCurrency && bookingInfo.requiredFxRate && (
            <Stack direction="row" spacing={5}>
              <Typography variant="body2">
                <Stack direction="row" alignItems="center" spacing={5} gap={1}>
                  The fx rate used for this booking was:
                  <Typography variant="subtitle2">
                    <Stack direction="row" alignItems="center">
                      ({bookingInfo.baseCurrency})
                      <ArrowRightAltIcon /> {bookingInfo.requiredFxRate}
                      <ArrowRightAltIcon /> ({bookingInfo.convertedCurrency})
                    </Stack>
                  </Typography>
                </Stack>
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>

      {bookingInfo.failureReason && (
        <Box mt={4}>
          <hr />
          <Box mt={2}>
            <Typography variant="body1">
              Failure Reason: <b>{bookingInfo.failureReason}</b>
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
