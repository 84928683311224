import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TableCell, TableRow, Tooltip } from '@mui/material';

import { FlexTableCell } from '~/components/Content/SearchRanking/FlexTableCell';

import SearchService, { UnifiedSearchList } from '~/services/SearchService';
import { OfferDetails, fetchUnifiedOfferDetails } from '~/services/UnifiedOfferService';

interface IExpectedValueData {
  expectedValue: string;
  expectedValueInfo?: string;
  adjustedExpectedValue: string;
  adjustedExpectedValueInfo?: string;
  salesAmount: string;
  salesCount: string;
  views: string;
  dailySales: string;
  marginAmount: string;
  ctr: string;
}

function SearchUnifiedRow({
  offer,
  region,
  variant,
  isManuallySorted,
  vertical,
}: {
  offer: UnifiedSearchList[0];
  region: string;
  variant: string;
  isManuallySorted?: boolean;
  vertical: 'experience' | 'all' | 'ultra lux';
}) {
  const tenant = useSelector((state: App.State) => state.tenant);
  const isMountedRef = useRef(true);
  const [fetchingState, setFetchingState] = useState<Utils.FetchingState>('idle');
  const [offerDetails, setOfferDetails] = useState<OfferDetails | undefined>();
  const [offerScore, setOfferScore] = useState<App.OfferScore | undefined>();
  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    setFetchingState('loading');
    Promise.all([
      SearchService.getOfferScoreForVariant({
        vertical: offer.vertical,
        offerId: offer.bk,
        region,
        variant,
      }),
      fetchUnifiedOfferDetails(offer, region, tenant.brand),
    ])
      .then(([score, offerDetails]) => {
        if (!isMountedRef.current) return;
        setOfferScore(score.result.score);
        setOfferDetails(offerDetails);
        setFetchingState('success');
      })
      .catch((error) => {
        setFetchingState('failed');
        setError(error);
      });

    return () => {
      isMountedRef.current = false;
    };
  }, [variant, offer, region, tenant.brand]);

  const expectedValueData: Partial<IExpectedValueData> = useMemo(() => {
    if (!offerScore) {
      return {};
    }

    return {
      views: offerScore.numberOfViews.toLocaleString(),
      salesCount: offerScore.salesCount.toLocaleString(),
      salesAmount: offerScore.totalSales.toLocaleString(),
      marginAmount: offerScore.totalMargin.toLocaleString(),
      dailySales: offerScore.dailySales.toLocaleString('en-NZ', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      expectedValue: offerScore.expectedValue.toFixed(3),
      expectedValueInfo: offerScore.expectedValueInfo,
      adjustedExpectedValue: offerScore.adjustedExpectedValue.toFixed(3),
      adjustedExpectedValueInfo: offerScore.adjustedExpectedValueInfo,
      ctr: (offerScore.ctr * 100).toFixed(2) + '%',
    };
  }, [offerScore]);

  return (
    <>
      {fetchingState === 'loading' && (
        <TableRow>
          <TableCell component="th" colSpan={10}>
            Loading...
          </TableCell>
        </TableRow>
      )}
      {fetchingState === 'success' && offerDetails && (
        <TableRow>
          <FlexTableCell component="th" scope="row" sx={{ maxWidth: 300 }}>
            <Link to={offerDetails.url}>{offerDetails.name}</Link>
            {isManuallySorted && (
              <Tooltip title="Commercial ranked" placement="top" arrow>
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            )}
          </FlexTableCell>
          <TableCell align="right">{offer.vertical}</TableCell>
          <TableCell align="right">{offerDetails.isLTS ? 'limited_time_special' : offer.offerType}</TableCell>
          <TableCell align="right">{expectedValueData.views}</TableCell>
          <TableCell align="right">{expectedValueData.salesCount}</TableCell>
          <TableCell align="right">{expectedValueData.salesAmount}</TableCell>
          <TableCell align="right">{expectedValueData.marginAmount}</TableCell>
          <TableCell align="right">{expectedValueData.dailySales}</TableCell>
          {vertical === 'experience' && <TableCell align="right">{expectedValueData.ctr}</TableCell>}
          <FlexTableCell align="right">
            {expectedValueData.expectedValue}
            {expectedValueData.expectedValueInfo && (
              <Tooltip title={expectedValueData.expectedValueInfo} placement="top" arrow>
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            )}
          </FlexTableCell>
          <FlexTableCell align="right">
            {expectedValueData.adjustedExpectedValue}
            {expectedValueData.adjustedExpectedValueInfo && (
              <Tooltip title={expectedValueData.adjustedExpectedValueInfo} placement="top" arrow>
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            )}
          </FlexTableCell>
        </TableRow>
      )}
      {fetchingState === 'failed' && (
        <TableRow>
          <TableCell component="th" colSpan={10}>
            Failed to load {offer.vertical} offer: {offer.bk} {error?.message ? `(${error.message})` : ''}
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
export default SearchUnifiedRow;
