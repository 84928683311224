import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridEditInputCell, GridRowModes, GridRowModesModel } from '@mui/x-data-grid';

import CopyableField from '~/components/Common/CopyableField';
import GridPagination from '~/components/Common/Elements/GridPagination';
import Spinner from '~/components/Common/Spinner';
import DebugModal from '~/components/DebugModal/DebugModal';
import dateFormatterDetailed from '~/components/Purchases/Home/formatters/dateFormatterDetailed';

import { DEFAULT_PAGE_SIZES } from '~/consts/filters';

import useQuery from '~/hooks/useQuery';

import { PromoUser, deletePromoUser } from '~/services/PromoService';

import { PromoCodeName } from '../formatters/PromoCodeNameFormatter';
import PromoCodeUser from '../formatters/PromoCodeUser';
import usePromoUsers from '../hooks/usePromoUsers';

interface Props {
  promoId?: string;
  initCodeName?: string;
  initUserId?: string;
  initPage?: number;
  initLimit?: number;
  handleUserIdFilterSet?: (userId: string) => void;
  showPromoCode?: boolean;
}

const PromoUsers: React.FC<Props> = ({
  promoId,
  initUserId,
  initPage = 1,
  initLimit = 10,
  initCodeName,
  showPromoCode = false,
}) => {
  const brand = useSelector((state: App.State) => state.tenant.brand);
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const isDevMode = !!query.get('isDev');

  const { promoUsers, totalRows, isLoading, promoUsersFilter, setPromoUsersFilter, fetchPromoUsers } = usePromoUsers({
    initPage,
    initLimit,
    initBrand: brand,
    initPromoId: promoId,
    initCodeName,
    initUserId,
  });

  useEffect(() => {
    setPromoUsersFilter({
      ...promoUsersFilter,
      code_name: initCodeName,
      user_id: initUserId,
    });
  }, [initCodeName, initUserId, setPromoUsersFilter]);

  const onPageChange = async (page, pageSize) => {
    setPromoUsersFilter({
      ...promoUsersFilter,
      page,
      limit: pageSize,
    });
  };

  const handleCancelUserEdit = () => {
    setRowModesModel({});
  };

  const handleEditUser = (params) => {
    setRowModesModel({
      ...rowModesModel,
      [params.id]: { mode: GridRowModes.Edit },
    });
  };

  const handleUserDeleteUser = async (promoUser: PromoUser) => {
    let response;
    try {
      response = await deletePromoUser(promoUser.id_promo_code_user);

      if (response.status == 200) {
        enqueueSnackbar('Promo User deleted successfully', {
          variant: 'success',
        });
        fetchPromoUsers();
        setRowModesModel({});
      } else {
        enqueueSnackbar(
          `Error deleting promo user - ${'message' in response.body ? response.body.message : ''} - ${JSON.stringify(
            response.body,
          )}`,
          {
            variant: 'error',
          },
        );
      }
    } catch (err) {
      enqueueSnackbar(`Error deleting promo user - ${JSON.stringify({ err, response }, null, 4)}`, {
        variant: 'error',
      });
    }
  };

  const sharedOpts: Partial<GridColDef<PromoUser>> = {
    editable: false,
    sortable: false,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    flex: 1,
  };

  const getColumns = (): GridColDef<PromoUser>[] => [
    {
      field: 'updated_at',
      headerName: 'Updated',
      editable: true,
      renderCell: (params) => dateFormatterDetailed(params.row.updated_at),
      ...sharedOpts,
    },
    {
      field: 'code_name',
      renderCell: (params) => PromoCodeName({ codeName: params.row.code_name }),
      headerName: 'Code',
      ...sharedOpts,
    },
    {
      field: 'id_promo_code_user',
      headerName: 'ID',
      renderCell: (params) => <Typography>{params.row.id_promo_code_user}</Typography>,
      ...sharedOpts,
    },
    {
      field: 'created_at',
      headerName: 'Created',
      editable: true,
      renderCell: (params) => dateFormatterDetailed(params.row.created_at),
      ...sharedOpts,
    },
    {
      field: 'promo_code_user_type',
      headerName: 'Type',
      renderCell: (params) => <Typography>{params.row.promo_code_user_type}</Typography>,
      renderEditCell: (params) => <input value={params.row.promo_code_user_type}></input>,
      ...sharedOpts,
    },
    {
      field: 'external_ids',
      headerName: 'External Ids',
      renderCell: (params) => <Typography>{params.row.external_ids}</Typography>,
      renderEditCell: (params) => (
        <GridEditInputCell
          {...params}
          inputProps={{
            min: 1,
          }}
          sx={{
            border: '1px solid',
            borderColor: 'rgb(190,190,190)',
            height: '83%',
            borderRadius: '4px',
            paddingLeft: '1rem',
          }}
          fullWidth
        />
      ),
      ...sharedOpts,
    },
    {
      field: 'user_id',
      headerName: 'User ID',
      renderCell: (params) => <Typography>{params.row.user_id}</Typography>,
      ...sharedOpts,
    },
    {
      field: 'remaining_uses',
      headerName: 'Remaining Uses',
      editable: true,
      renderCell: (params) => <Typography>{params.row.remaining_uses}</Typography>,
      renderEditCell: (params) => (
        <GridEditInputCell
          {...params}
          inputProps={{
            min: 1,
          }}
          sx={{
            border: '1px solid',
            borderColor: 'rgb(190,190,190)',
            height: '83%',
            borderRadius: '4px',
            paddingLeft: '1rem',
          }}
          fullWidth
        />
      ),
      ...sharedOpts,
    },
    {
      field: 'expires_at',
      headerName: 'Expires',
      editable: true,
      renderCell: (params) => dateFormatterDetailed(params.row.expires_at),
      ...sharedOpts,
    },
    {
      field: 'modified_by',
      headerName: 'Modified By',
      renderCell: (params) => PromoCodeUser({ userId: params.row.modified_by }),
      ...sharedOpts,
    },
    {
      field: 'debug',
      headerName: 'Raw Data',
      renderCell: (params) => (
        <CopyableField
          value={JSON.stringify(params.row, null, 4)}
          label={<DebugModal type="generic" data={params.row ?? { data: 'None' }} />}
        />
      ),
      ...sharedOpts,
    },
    {
      ...sharedOpts,
      flex: 2,
      editable: false,
      field: 'action',
      headerName: 'Actions',
      renderCell: (params) => {
        if (rowModesModel[params.id]?.mode === GridRowModes.Edit) {
          return (
            <ButtonGroup variant="contained" size="small" disableElevation>
              <Button key={`${params.id}-cancel`} onClick={() => handleCancelUserEdit()}>
                Cancel
              </Button>
              <Button
                key={`${params.id}-delete`}
                color="error"
                onClick={() => {
                  if (confirm('Are you sure you want to delete this promo code user?')) {
                    handleUserDeleteUser(params.row);
                  }
                }}
              >
                Delete
              </Button>
            </ButtonGroup>
          );
        }
        return (
          <ButtonGroup variant="text" size="small">
            <Button key={`${params?.id}-edit`} onClick={() => handleEditUser(params)}>
              Edit
            </Button>
          </ButtonGroup>
        );
      },
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
    },
  ];

  return (
    <Box m={2}>
      {!isLoading ? (
        <DataGrid
          columns={getColumns()}
          autoHeight
          getRowId={(row) => row.id_promo_code_user}
          rowCount={totalRows}
          rows={promoUsers || []}
          rowModesModel={rowModesModel}
          pagination
          paginationMode="server"
          paginationModel={{ page: promoUsersFilter.page, pageSize: promoUsersFilter.limit }}
          slots={{ pagination: GridPagination }}
          pageSizeOptions={DEFAULT_PAGE_SIZES}
          onPaginationModelChange={({ page, pageSize }) => onPageChange(page, pageSize)}
          columnVisibilityModel={{
            id_promo_code_user: isDevMode,
            code_name: showPromoCode,
          }}
          getRowHeight={() => 'auto'}
        />
      ) : (
        <Spinner size={36} />
      )}
    </Box>
  );
};

export default PromoUsers;
