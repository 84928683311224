import React from 'react';

import { withSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Button, Typography } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import { OFFER_TYPE_HOTEL, OFFER_TYPE_LAST_MINUTE_HOTEL, OFFER_TYPE_TACTICAL_AO_HOTEL } from '~/consts/offerTypes';

import featureToggle from '~/utils/featureToggle';
import { getHotelUniquePropertyDetails } from '~/utils/hotelUtils';

import OffersService from '../../../services/OffersService';
import { reportError } from '../../../utils/reportError';
import ImagesForm from '../../Common/Forms/ImagesForm';

import ApiListContainer from './ApiListContainer';
import CopyOfferImagesModal from './CopyOfferImagesModal';

class ImagesEditContainer extends React.Component {
  constructor(props) {
    super(props);

    const { match } = props;
    const { id_offer: offerId } = match.params;

    this.state = {
      isCopyModalVisible: false,
      images: [],
      offerDetails: {},
      offerId: offerId,
      isReady: false,
      propertyDetails: {},
      propertyImages: [],
      fullPropertyImages: [],
      overridableContent: false,
    };
  }

  filterImages = (fullPropertyImages, mappedImages) => {
    const mappedImageIds = new Set(mappedImages.map((image) => image.id_cloudinary_external));
    return fullPropertyImages.filter((image) => !mappedImageIds.has(image.id_cloudinary_external));
  };

  fetchData = () => {
    Promise.all([OffersService.getImages(this.state.offerId), OffersService.getOffer(this.state.offerId)])
      .then(async ([offerImagesRaw, offer]) => {
        const offerImages = offerImagesRaw.result.map((image) =>
          Object.assign({}, image, {
            id: image.id_image,
          }),
        );

        this.originalOfferDetails = offer.result;
        const propertyDetails = await getHotelUniquePropertyDetails(this.originalOfferDetails);
        let propertyImages = [];
        let fullPropertyImages = [];
        if (propertyDetails) {
          fullPropertyImages = propertyDetails.images;
          propertyImages =
            this.filterImages(fullPropertyImages, offerImages).map((image) => ({ ...image, selected: false })) ?? [];
        }
        const overridableContent =
          featureToggle.availableToShow('ADDITIONAL_PROPERTY_CONTENT_ENABLED') &&
          [OFFER_TYPE_TACTICAL_AO_HOTEL, OFFER_TYPE_HOTEL, OFFER_TYPE_LAST_MINUTE_HOTEL].includes(
            this.originalOfferDetails.type,
          );

        this.setState({
          offerDetails: offer.result,
          propertyDetails,
          images: offerImages,
          fullPropertyImages: fullPropertyImages,
          propertyImages: propertyImages,
          isReady: true,
          overridableContent,
        });
      })
      .catch((error) => {
        reportError(error);
      });
  };

  onAddImage = async (cloudinaryId, filename) => {
    const uploadedImage = await OffersService.createImage(
      {
        id_cloudinary_external: cloudinaryId,
        filename: filename ? filename : undefined,
      },
      this.state.offerId,
    ).then((newImage) => {
      newImage.id = newImage.id_image;
      return newImage;
    });
    const updatedPropertyImages = this.state.propertyImages.filter(
      (image) => image.id_cloudinary_external !== cloudinaryId,
    );
    const offerImages = this.state.images;
    offerImages.push({ uploadedImage, id_cloudinary_external: cloudinaryId, filename });
    this.setState({
      propertyImages: updatedPropertyImages,
      images: offerImages,
    });
    return uploadedImage;
  };

  onUpdateImages = async (newImageList) => {
    const updatePayload = newImageList.map((image) => ({
      id_image: image.id,
      id_cloudinary_external: image.id_cloudinary_external,
      order: image.order,
      title: image.title,
    }));

    return await OffersService.updateImages(updatePayload, this.state.offerId);
  };

  onDeleteImage = async (imageId) => {
    const newImages = this.state.images.filter((i) => i.id !== imageId);
    newImages.forEach((image, index) => {
      image.order = index + 1;
    });
    const propertyImages = this.filterImages(this.state.fullPropertyImages, newImages);
    this.setState({
      images: newImages,
      propertyImages,
    });
    return await OffersService.deleteImage(this.state.offerId, imageId);
  };

  // This function is used to toggle the selection of an image within an array of images
  // We loop over all images, only adjusting the image of concern, otherwise leaving the selected value as is
  toggleImageSelection = (id, currentSelectedValue) => {
    const newImages = this.state.propertyImages.map((p) => ({
      ...p,
      selected: p.id === id ? !currentSelectedValue : p.selected,
    }));
    this.setState({ propertyImages: newImages });
  };

  copyImagesToOffer = () => {
    this.state.propertyImages
      .filter((i) => i.selected)
      .map((p) => this.onAddImage(p.id_cloudinary_external, p.filename));
  };

  selectAllPropertyImages = () => {
    const newImages = this.state.propertyImages.map((p) => ({
      ...p,
      selected: true,
    }));
    this.setState({ propertyImages: newImages });
  };

  showCopyModal = () => {
    this.setState({
      isCopyModalVisible: true,
    });
  };

  copyOfferImagesData = () => {
    OffersService.getImages(this.state.offerId)
      .then((images) => {
        const mappedImages = images.result.map((image) =>
          Object.assign({}, image, {
            id: image.id_image,
          }),
        );

        this.setState({
          images: mappedImages,
          isReady: true,
        });

        this.hideCopyModal();
      })
      .catch((error) => {
        reportError(error);
      });
  };

  hideCopyModal = () => {
    this.setState({
      isCopyModalVisible: false,
    });
  };

  componentDidMount = () => {
    this.fetchData();
  };

  setAsNotReady = () => {
    this.setState({
      isReady: false,
    });
  };

  render() {
    const { offerId, images, isCopyModalVisible, isReady } = this.state;

    const formImages = images.map((i) => ({
      ...i,
      publicImageId: i.id_cloudinary_external,
    }));

    return (
      <Box className="offer-edit container">
        <Helmet>
          <title>Offers | {this.state.offerDetails?.name || offerId} | Edit Offer Images</title>
        </Helmet>
        <Button component={Link} startIcon={<ChevronLeftIcon />} to={'/offers/' + offerId}>
          Return to offer
        </Button>
        <h1 className="page-header">
          Edit Offer Images
          <Button startIcon={<ContentCopyIcon size="large" />} onClick={this.showCopyModal} className="copy-button">
            <span className="sr-only">Copy Offer</span>
          </Button>
        </h1>
        {isReady && (
          <Box>
            <ImagesForm
              images={formImages}
              onAddImage={this.onAddImage}
              onUpdateImages={this.onUpdateImages}
              onDeleteImage={this.onDeleteImage}
            />
            <CopyOfferImagesModal
              isCopyModalVisible={isCopyModalVisible}
              setAsNotReady={this.setAsNotReady}
              hideCopyModal={this.hideCopyModal}
              copyOfferImagesData={this.copyOfferImagesData}
              onAddImage={this.onAddImage}
              currentOfferStatus={this.state.offerDetails.status}
            />

            {this.state.overridableContent && Object.keys(this.state.propertyImages).length > 0 && (
              <>
                <Box mt={7}>
                  <Typography variant="h6">Available Image(s) from property to offer</Typography>
                </Box>
                <ApiListContainer
                  images={this.state.propertyImages}
                  toggleImageSelection={this.toggleImageSelection}
                  axis="xy"
                  distance={3}
                  helperClass="sortable-helper"
                />
                <Box sx={{ justifyContent: 'end' }}>
                  <Button variant="contained" color="primary" onClick={this.copyImagesToOffer}>
                    Copy to Offer images
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.selectAllPropertyImages}
                    style={{ marginLeft: '10px' }}
                  >
                    Select All
                  </Button>
                </Box>
              </>
            )}

            {this.state.overridableContent && !Object.keys(this.state.fullPropertyImages).length && (
              <Typography> No property has been linked to the offer yet!</Typography>
            )}
          </Box>
        )}
        {!isReady && <Spinner />}
      </Box>
    );
  }
}

export default withSnackbar(ImagesEditContainer);
