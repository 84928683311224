/**TimeService's goal is to have a single place for all
 * time functionality in Admin portal
 *
 * All functions should work with and return (where appropriate) the Javascript
 * Date type
 */
import { Dayjs } from 'dayjs';
import pluralize from 'pluralize';
import dayjs from '~/timeInit';

/** Export Dayjs type */
export { Dayjs };

/**Returns dates in format: Thursday */
export function formatDateFullDay(value?: Date): string {
  return dayjs(value).format('dddd');
}

/**Returns dates in format: 2018-Sep*/
export function formatDateReportsByMonth(value?: Date): string {
  return dayjs(value).format('YYYY-MMM');
}

/**Returns dates in format: 2018-Sep-08*/
export function formatDateReportsByDate(value?: Date): string {
  return dayjs(value).format('YYYY-MMM-DD');
}

/**Returns dates in format: September*/
export function formatDateMonth(value?: Date): string {
  return dayjs(value).format('MMMM');
}

/**Returns dates in format: Thu*/
export function formatDateDayShortDayName(value?: Date): string {
  return dayjs(value).format('ddd');
}

/**Returns dates in format: 26*/
export function formatDateDay(value?: Date): string {
  return dayjs(value).format('D');
}

/**Returns dates in format: September 1994 */
export function formatDateCalendarTitle(value?: Date): string {
  return dayjs(value).format('MMMM YYYY');
}

/**Returns dates in format: 1994-04 */
export function formatDateCalendar(value?: Date): string {
  return dayjs(value).format('YYYY-MM');
}

/**Returns dates in format:  September 4, 1986 8:30 PM */
export function formatDateLongMonthWithMeridiemNoSeconds(value?: Date | string): string {
  return dayjs(value).format('LLL');
}

/**Returns dates in format: 2022-09-12-16-24 */
export function formatDateISOWithTime(value?: Date): string {
  return dayjs(value).format('YYYY-MM-DD-HH-mm');
}

/**Returns dates in format: September 12, 2022 4:22:31 PM */
export function formatDateLongMonthWithMeridiem(value?: Date): string {
  return dayjs(value).format('LL LTS');
}

/**Returns dates in format: Monday, September 12th 2022 */
export function formatDateFormal(value?: Date): string {
  return dayjs(value).format('dddd, MMMM Do YYYY');
}

/** Returns dates in format: 6 January 2018 */
export function formatDateLong(value?: Date | string | number): string {
  return dayjs(value).format('D MMMM YYYY');
}

export function formatDateShortDateAndMonth(value?: Date): string {
  return dayjs(value).format('D MMM');
}

/**Returns dates in format: 31st Jan 1994 16:23:21 */
export function formatDateOrdinalWithClock(value?: Date | string): string {
  return dayjs(value).format('Do MMM YYYY HH:mm:ss');
}

/**Returns dates in format: 1973-03-03 */
export function formatDateISO(value?: Date | string): string {
  return dayjs(value).format('YYYY-MM-DD');
}

/**Returns UTC dates in format: 1973-03-03 */
export function formatDateISOUTC(value?: Date | string): string {
  return dayjs(value).utc().format('YYYY-MM-DD');
}

/**Returns dates in format: 2025-09-12T01:59:00+00:00Z just for DateTimeWidget */
export function formatDateDateTimeWidget(value?: Date): string {
  return dayjs(value).format('YYYY-MM-DDTHH:mm:ss[Z]');
}

/**Returns dates in format: 2025-09-12T01:59:00+00:00Z just for DateTimeWidget in UTC */
export function formatUTCDateDateTimeWidget(value?: Date): string {
  return dayjs.utc(value).format('YYYY-MM-DDTHH:mm:ss[Z]');
}

/** Returns dates in format: 2022-08-18T19:45:55+10:00 */
export function formatDateLongISO(value?: Date): string {
  return dayjs(value).format('YYYY-MM-DDTHH:mm:ssZ');
}

/**Returns dates in format: 02 Jan 1970 13:46 */
export function formatDateWithClock(value?: Date | string | Dayjs): string {
  return dayjs(value).format('DD MMM YYYY HH:mm');
}

/**Returns dates in format: 09 Jan 1994 */
export function formatDateShortDD(value?: Date | string | number): string {
  return dayjs(value).format('DD MMM YYYY');
}

/**Returns dates in format: 9 Jan 1994 */
export function formatDateShort(value?: Date | string): string {
  return dayjs(value).format('D MMM YYYY');
}

/**Returns dates in format: 14th Apr 1970 */
export function formatDateOrdinalShortMonth(value?: Date): string {
  return dayjs(value).format('Do MMM YYYY');
}

/**Returns dates in format: 03-03-1994 */
export function formatDateNumeralMonths(value?: Date | string): string {
  return dayjs(value).format('DD-MM-YYYY');
}

/**Returns dates in format: Thu 1 Jan 1970  */
export function formatDateWeekDayShortMonth(value?: Date | string): string {
  return dayjs(value).format('ddd D MMM YYYY');
}

/**Returns dates in format: 01/01/1970 */
export function formatDateSlashes(value?: Date | string): string {
  return dayjs(value).format('DD/MM/YYYY');
}

/**Returns dates in format: 01/01/1970 13:46 */
export function formatDateSlashesWithClock(value?: Date | string): string {
  return dayjs(value).format('DD/MM/YYYY HH:mm');
}

/** takes date and its format to create correct date object */
export function newDate(value?: Date | string, format = 'YYYY-MM-DD') {
  return dayjs(value, format);
}

/** Returns true if between_date is inbetween the two dates
 *
 * between_date defaults to dateNow's value if no argument is provided
 *
 * @from_date from_date < to_date
 * @to_date
 */
export function isBetween(from_date: string | Date, to_date: string | Date, between_date?: string | Date): boolean {
  if (!between_date) {
    return dayjs().isBetween(from_date, to_date);
  }
  return dayjs(between_date).isBetween(from_date, to_date);
}

/** Takes in a TimeValue and returns a Date
 *
 * Returns null if timestamp is null/undefined
 *
 * https://tc39.es/ecma262/#sec-time-values-and-time-range
 */
export function unixToDate(timeStamp: number): Date {
  if (timeStamp === undefined || timeStamp === null) {
    return null;
  }
  return dayjs.unix(timeStamp).toDate();
}

/** Takes in a date and returns its TimeValue
 *
 * Defaults to dateNow()'s output if no input
 */
export function unix(date?: Date | Dayjs): number {
  return dayjs(date).unix();
}

/** Converts inputted date to local time
 *
 * Returns current local date and time if no argument is provided.
 */
export function dateNow(date?: Date | string): Date {
  return dayjs(date).toDate();
}

/** Converts inputted date to UTC time
 *
 * Returns current date and time in UTC if no argument is provided.
 */
export function dateNowUtc(date?: Date | string): Date {
  return dayjs.utc(date).toDate();
}

/** checks if two dates have same day, month and year */
export function datesHaveSameDayMonthYear(date1: Date | string, date2?: Date | string): boolean {
  return dayjs.utc(date1).isSame(dayjs.utc(date2), 'day');
}

/** Returns true if date1 > date2
 *
 * date2 will default to the value of dateNow() if no input
 */
export function isAfter(date1: Date | string, date2?: Date | string): boolean {
  return dayjs.utc(date1).isAfter(dayjs.utc(date2));
}

/** Returns true if date1 < date2
 *
 * date2 will default to the value of dateNow() if no input
 */
export function isBefore(date1: Date | string, date2?: Date | string): boolean {
  return dayjs.utc(date1).isBefore(dayjs.utc(date2));
}

/** Returns the timezone offset in hours between
 * the client's timezone and UTC.
 *
 * It is positive if the local time is ahead, and vice-versa. */
export function getTimezoneOffset(): number {
  return (new Date().getTimezoneOffset() / 60) * -1;
}

/** Adds the number of days to the date
 *
 * Defaults to dateNow()'s date if no date input
 */
export function addHours(hoursToAdd: number, date?: Date | string): Date {
  //double return is because date is not assignable to dayjs type
  if (!date) {
    return dayjs().add(hoursToAdd, 'hour').toDate();
  }
  return dayjs(date).add(hoursToAdd, 'hour').toDate();
}

/** Subtractss the number of hours of a date
 *
 * Defaults to dateNow()'s date if no date input
 */
export function subtractHours(hoursToSubtract: number, date?: Date | string): Date {
  //double return is because date is not assignable to dayjs type
  if (!date) {
    return dayjs().subtract(hoursToSubtract, 'hour').toDate();
  }
  return dayjs(date).subtract(hoursToSubtract, 'hour').toDate();
}

/** Adds the number of days to the date
 *
 * Defaults to dateNow()'s date if no date input
 */
export function addDays(daysToAdd: number, date?: Date | string): Date {
  //double return is because date is not assignable to dayjs type
  if (!date) {
    return dayjs().add(daysToAdd, 'day').toDate();
  }
  return dayjs(date).add(daysToAdd, 'day').toDate();
}

/**
 * Adds the number of days to the date
 *
 * @param days The number of days to add
 * @param date The date to add days to. If not provided, the current date is used.
 * @returns The new date (DayJs object) with the number of days added.
 */
export function addDaysRaw(days: number, date?: string | number | Date | Dayjs): Dayjs {
  return dayjs(!date ? undefined : date).add(days, 'day');
}

/** Adds the number of months to the date
 *
 * Defaults to dateNow()'s date if no date input
 */
export function addMonths(monthsToAdd: number, date?: Date | string): Date {
  if (!date) {
    return dayjs().add(monthsToAdd, 'month').toDate();
  }
  return dayjs(date).add(monthsToAdd, 'month').toDate();
}

/** Adds the number of years to the date
 *
 * Defaults to dateNow()'s date if no date input
 */
export function addYears(yearsToAdd: number, date?: Date | string): Date {
  if (!date) {
    return dayjs().add(yearsToAdd, 'year').toDate();
  }
  return dayjs(date).add(yearsToAdd, 'year').toDate();
}

/** Adds duration
 *
 * Defaults to dateNow()'s date if no date input
 */
export function addDuration(durationOptions: Record<string, number>, date?: Date | string): Date {
  if (!date) {
    return dayjs().add(dayjs.duration(durationOptions)).toDate();
  }
  return dayjs(date).add(dayjs.duration(durationOptions)).toDate();
}

/** Suctracts the number of days from the date
 *
 * Defaults to dateNow()'s date if no date input
 */
export function subDays(daysToSubtract: number, date?: Date | string): Date {
  //double return is because date is not assignable to dayjs type
  if (!date) {
    return dayjs().subtract(daysToSubtract, 'day').toDate();
  }
  return dayjs(date).subtract(daysToSubtract, 'day').toDate();
}

/** Subtracts the number of days from the date
 *
 * Defaults to dateNow()'s date if no date input
 */
export function subMonths(monthsToSubtract: number, date?: Date | string): Date {
  if (!date) {
    return dayjs().subtract(monthsToSubtract, 'month').toDate();
  }
  return dayjs(date).subtract(monthsToSubtract, 'month').toDate();
}

/**Calculates the number of days difference between 2 dates
 *
 * Second date defaults to dateNow() if no input
 */
export function diffDays(
  date1: Date | string,
  date2?: Date | string,
  unitOfTime: dayjs.UnitType = undefined,
  decimal = false,
) {
  if (!date2) {
    return dayjs(date1).diff(dateNow(), unitOfTime as dayjs.UnitType, decimal);
  }
  return dayjs(date1).diff(date2, unitOfTime as dayjs.UnitType, decimal);
}

/** Sets time to 23:59:59
 *
 * Defaults to dateNow()'s date if no date input
 */
export function endOfDay(date?: Date | string): Date {
  if (!date) {
    return dayjs().endOf('day').toDate();
  }
  return dayjs(date).endOf('day').toDate();
}

/** Sets time to 00:00:00
 *
 * Defaults to dateNow()'s date if no date input
 */
export function startOfDay(date?: Date | string): Date {
  if (!date) {
    return dayjs().startOf('day').toDate();
  }
  return dayjs(date).startOf('day').toDate();
}

/** Sets day to 0
 *
 * Defaults to dateNow()'s date if no date input
 */
export function startOfMonth(date?: Date | string): Date {
  if (!date) {
    return dayjs().startOf('month').toDate();
  }
  return dayjs(date).startOf('month').toDate();
}

/** Creates a 2D array of arrays representing the weeks of a month
 * for use in the calendar representation of a month
 *
 * https://www.timeanddate.com/calendar/?year=1999&country=29
 *
 * The above website would use a similar algorithm to generate the
 * layout of the days of each month, notice that February of that year takes up
 * 4 rows with Monday as the first day of the week
 *
 * Algorithm taken from https://github.com/react-dates/react-dates/blob/master/src/utils/getCalendarMonthWeeks.js
 */
export function getCalendarMonthWeeks(date, enableOutsideDays) {
  const firstDayOfWeek = dayjs.localeData().firstDayOfWeek();
  const month = dayjs(date);

  // set utc offset to get correct dates in future (when timezone changes)
  const firstOfMonth = month.clone().startOf('month').hour(12);
  const lastOfMonth = month.clone().endOf('month').hour(12);

  // calculate the exact first and last days to fill the entire matrix
  // (considering days outside month)
  const prevDays = (firstOfMonth.day() + 7 - firstDayOfWeek) % 7;
  const nextDays = (firstDayOfWeek + 6 - lastOfMonth.day()) % 7;
  const firstDay = firstOfMonth.clone().subtract(prevDays, 'day');
  const lastDay = lastOfMonth.clone().add(nextDays, 'day');

  const totalDays = lastDay.diff(firstDay, 'days') + 1;

  let currentDay = firstDay.clone();
  const weeksInMonth = [];

  for (let i = 0; i < totalDays; i += 1) {
    if (i % 7 === 0) {
      weeksInMonth.push([]);
    }

    let day = null;
    if ((i >= prevDays && i < totalDays - nextDays) || enableOutsideDays) {
      day = currentDay.clone();
    }

    weeksInMonth[weeksInMonth.length - 1].push(day ? day.format() : null);

    currentDay = currentDay.add(1, 'day');
  }

  return weeksInMonth;
}

/** Checks that the string is in format DD/MM/YYYY and is valid */
export function validateDateOfBirth(value: string) {
  if (value.length !== 10) {
    return {
      valid: false,
      date: null,
    };
  }

  const m = dayjs(value, 'DD/MM/YYYY', true);

  if (m.isValid()) {
    return {
      valid: true,
      date: formatDateISO(value),
    };
  }

  return {
    valid: false,
    date: null,
  };
}

export function rangeOverlap(aStart, aEnd, bStart, bEnd) {
  return dayjs.max(dayjs(aStart), dayjs(bStart)) < dayjs.min(dayjs(aEnd), dayjs(bEnd));
}

export function sortByDate(a: string, b: string) {
  return unix(newDate(b, 'DD/MM/YYYY')) - unix(newDate(a, 'DD/MM/YYYY'));
}

export function getDateStartOf(unit: dayjs.OpUnitType, format = 'YYYY-MM-DD', timezone = 'Australia/Sydney'): string {
  return dayjs().tz(timezone).startOf(unit).format(format);
}

export function toDayJs(date: Date | string): Dayjs {
  return dayjs(date);
}

export function getTimeDifference(bookByDate: Date, currentDate: Date) {
  const timeDifference = dayjs.duration(dayjs(bookByDate).diff(dayjs(currentDate)));
  const years = timeDifference.years();
  const months = timeDifference.months();
  const days = timeDifference.days();
  return `${years > 0 ? `${years} ${pluralize('year', years)} ` : ''}${
    months > 0 ? `${months} ${pluralize('month', months)} ` : ''
  }${days > 0 ? `${days} ${pluralize('day', days)}` : ''}`;
}

export const formatSeconds = (seconds: number) => {
  if (seconds === 0) {
    return '-';
  }

  const totalHours = Math.floor(seconds / (60 * 60));
  const leftoverSeconds = seconds - totalHours * 60 * 60;

  const totalMinutes = Math.floor(leftoverSeconds / 60);
  const totalSeconds = leftoverSeconds - totalMinutes * 60;

  return `${totalHours}h ${totalMinutes}m ${totalSeconds}s`;
};

/**
 * Creates a new DayJs instance based on the input date.
 */
export function getDayJs(date?: string | number | Date | Dayjs) {
  return dayjs(date);
}
