import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Alert, Box, Container, Grid } from '@mui/material';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

import useQuery from '~/hooks/useQuery';

import { InternalPromoWithDiscounts, getInternalPromo, getPromoById } from '../../services/PromoService';
import PageHeader from '../Common/Elements/PageHeader';
import Spinner from '../Common/Spinner';
import PromoFailures from '../Promo/PromoFailure/PromoFailures';
import PromoRequests from '../Promo/PromoRequests/PromoRequests';
import PromoUsers from '../Promo/PromoUsers';
import usePromoMeta from '../Promo/hooks/usePromoMeta';

import PromoBulkCreate from './PromoBulkCreate';
import PromoFormV3 from './PromoFormV3';
import PromoLogs from './PromoLogs';

const PromoDetailContainer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery();
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [promo, setPromo] = useState<InternalPromoWithDiscounts>(null);
  const [parentPromo, setParentPromo] = useState<InternalPromoWithDiscounts>(null);

  const [userIdFilter, setUserIdFilter] = useState(query.get('userId') ?? '');
  const isDev = !!query.get('isDev');

  const brand = useSelector((state: App.State) => state.tenant.brand);
  const { promoMeta } = usePromoMeta();

  const fetchData = useCallback(
    async (id_promo_code, code_name) => {
      setLoading(true);

      if (id_promo_code && id_promo_code.length > 0) {
        // Fetch data by ID
        const response = await getPromoById({
          idPromo: id_promo_code,
          brand,
        });

        const promoCode: InternalPromoWithDiscounts = response.result;

        setPromo(promoCode);
      } else if (code_name && code_name.length > 0) {
        // Fetch data by name (codeName)
        const response = await getInternalPromo({
          code_name,
          brand,
        }).catch((err) => {
          enqueueSnackbar(err.message, { variant: 'error' });
        });

        if (response) {
          const promoCode: InternalPromoWithDiscounts = response.result;
          setPromo(promoCode);
        }
      } else {
        enqueueSnackbar(`No ${id_promo_code} or ${code_name} provided?`, { variant: 'error' });
      }
      setLoading(false);
      if (promo?.cloned_from_promo_id) {
        const clonedPromo = await getPromoById({
          idPromo: promo.cloned_from_promo_id,
        });

        setParentPromo(clonedPromo.result);
      }
    },
    [brand, enqueueSnackbar, promo?.cloned_from_promo_id],
  );

  const {
    params: { id_promo, id_code },
  } = useRouteMatch<{ id_promo: string; id_code: string }>();

  useEffect(() => {
    if (!promo) {
      fetchData(id_promo, id_code);
    }
  }, [fetchData, promo, brand, id_code, id_promo]);

  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  if (!promo) {
    return (
      <Container maxWidth="xl">
        <Helmet>
          <title>Promos | Promo not found</title>
        </Helmet>
        <PageHeader title={`Promo code not found?`} />
        <Alert severity="warning">No promo found. It could not exist for this brand ({brand})?</Alert>
      </Container>
    );
  }

  const canBulkCreate = !promo.cloned_from_promo_id && !promo.fk_referral_code_id;

  return (
    <Container maxWidth={false}>
      <Container>
        <Helmet>
          <title>Promos | {promo.code_name}</title>
        </Helmet>
        <PageHeader title={`Promo code ${promo.code_name}`} />
        <PromoFormV3
          promoCode={promo}
          parentPromo={parentPromo}
          rerender={() => fetchData(id_promo, id_code)}
          promoMeta={promoMeta}
          isDev={isDev}
        />
        <>
          <PageSubheader title={'Users'}>
            <Box component="span" color="text.secondary" alignItems="left">
              Users that qualify for this promo
            </Box>
          </PageSubheader>
          <PromoUsers promoId={promo.id_promo_code} />
        </>

        {canBulkCreate && (
          <Grid mt={8}>
            <PromoBulkCreate promoId={promo.id_promo_code} codeName={promo.code_name} expires_at={promo.expires_at} />
          </Grid>
        )}
      </Container>
      <Box mt={8} maxWidth="100%">
        <PageSubheader title={`Recent Requests for ${promo.code_name}`}>
          <Box component="span" color="text.secondary" alignItems="left"></Box>
        </PageSubheader>
        {promoMeta && (
          <PromoRequests
            initCodeName={promo.code_name}
            initPage={0}
            initLimit={10}
            initUserId={userIdFilter}
            brand={brand}
            handleUserIdFilterSet={(userId) => {
              setUserIdFilter(userId);
            }}
            promoMeta={promoMeta}
          />
        )}
      </Box>
      <Box mt={8}>
        <PageSubheader title={`Usage of promo code ${promo.code_name}`}>
          <Box component="span" color="text.secondary" alignItems="left">
            {`(${
              promo?.code_limit != null && promo.code_limit > 0
                ? `${promo.code_limit - (promo.usage_count ?? 0)}/${promo.code_limit} remaining`
                : `${promo.usage_count} used/unlimited`
            })`}
          </Box>
        </PageSubheader>

        <PromoLogs promo={promo} userIdFilter={userIdFilter} meta={promoMeta} />
        <PageSubheader title={`View promo failures ${userIdFilter ? `- userId: ${userIdFilter}` : ''}`} />
        <PromoFailures initCodeName={promo.code_name} brand={brand} promoMeta={promoMeta} initUserId={userIdFilter} />
      </Box>
    </Container>
  );
};

export default PromoDetailContainer;
