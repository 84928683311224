import QueryString from 'qs';

import type {
  Affiliation,
  Agency,
  Agent,
  AvailableAffiliations,
  Commission,
  CreateAffiliationParams,
  CreateCommissionParams,
  CreateCommissionResponse,
  EditAgencyParams,
  EditAgencyResponse,
  EditCommissionParams,
  Eoi,
  GetAffiliationResponse,
  GetAgenciesResponse,
  GetAgentsResponse,
  GetAvailableAffiliationsResponse,
  List,
  ListAffiliationsResponse,
  ListAgencyParams,
  ListEoiParams,
  ListUserParams,
  MappedAgent,
  UpdateAffiliationResponse,
  UserEmailLogsResponse,
  getCommissionsParams,
  getCommissionsResponse,
} from '~/types/services/agentHub';

import { formatDateWithClock } from '../TimeService';
import { request } from '../common';

export function basePath() {
  return window.configs.API_HOST + '/api/agent';
}

export async function listEOI(params: ListEoiParams): Promise<List<Eoi>> {
  const { result } = await request(`${basePath()}/eoi?${QueryString.stringify(params)}`, { method: 'GET' });

  return {
    total: result.total,
    rows: result.rows.map((eoi) => ({
      id: eoi.id,
      agencyName: eoi.agencyName,
      name: eoi.name,
      status: eoi.status,
      submittedAt: formatDateWithClock(eoi.submittedAt),
      updatedAt: getUpdatedAtDate(eoi),
      onboardedAt: eoi.onboardedAt ? formatDateWithClock(eoi.onboardedAt) : undefined,
    })),
  };
}

export async function getEOI(id: string): Promise<Eoi> {
  const { result } = await request(`${basePath()}/eoi/${id}`, { method: 'GET' });
  return result;
}

export async function updateEOIStatus(id: string, status: 'approved' | 'rejected'): Promise<Eoi> {
  const requestBody = {
    status,
  };

  const { result } = await request(`${basePath()}/eoi/${id}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify(requestBody),
  });
  return result;
}

export async function listAffiliations(params): Promise<List<Affiliation>> {
  const { result }: ListAffiliationsResponse = await request(
    `${basePath()}/affiliation?${QueryString.stringify(params)}`,
    { method: 'GET' },
  );
  return result;
}

export async function listAgencies(params: ListAgencyParams): Promise<List<Agency>> {
  const { result }: GetAgenciesResponse = await request(`${basePath()}/agency?${QueryString.stringify(params)}`, {
    method: 'GET',
  });

  const agenciesResult: List<Agency> = {
    total: result.total,
    rows: result.rows.map((agency) => ({
      id: agency.id,
      name: agency.name,
      businessCodeType: agency.businessCodeType,
      businessCodeValue: agency.businessCodeValue,
      address: agency.address,
      city: agency.city,
      state: agency.state,
      postcode: agency.postcode,
      country: agency.country,
      affiliation: agency.affiliation,
    })),
  };

  return agenciesResult;
}

export async function listUsers(params: ListUserParams): Promise<List<MappedAgent>> {
  const { result }: GetAgentsResponse = await request(`${basePath()}/user?${QueryString.stringify(params)}`, {
    method: 'GET',
  });

  const agentsResult: List<MappedAgent> = {
    total: result.total,
    rows: result.rows.map((agent) => ({
      id: agent.id,
      name: agent.name ?? '',
      phone: agent.phone ?? '',
      email: agent.email ?? '',
      affiliation: agent.affiliation,
      customerId: agent.customerId ?? '',
      agencyId: agent.agencyId ?? '',
      eoiSubmissionId: agent.eoiSubmissionId ?? '',
      agencyName: agent.agencyName ?? '',
      status: agent.status,
      verifiedAt: agent.verifiedAt,
    })),
  };

  return agentsResult;
}

export async function getAgency(id: string): Promise<Agency> {
  const { result } = await request(`${basePath()}/agency/${id}`, { method: 'GET' });
  return result;
}

export async function getAffiliation(id: string): Promise<Affiliation> {
  const { result }: GetAffiliationResponse = await request(`${basePath()}/affiliation/${id}`, { method: 'GET' });
  return result;
}

export async function getAffiliationList(): Promise<AvailableAffiliations> {
  const { result }: GetAvailableAffiliationsResponse = await request(`${basePath()}/available-affiliations`, {
    method: 'GET',
  });

  return result;
}

export async function createAffiliation(data: CreateAffiliationParams): Promise<Affiliation> {
  const { result }: GetAffiliationResponse = await request(`${basePath()}/affiliation`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return result;
}

export async function deleteAffiliation(id: string): Promise<void> {
  await request(`${basePath()}/affiliation/${id}`, { method: 'DELETE' });
}

export async function updateAffiliation(id: string, data: Affiliation): Promise<Affiliation> {
  const { result }: UpdateAffiliationResponse = await request(`${basePath()}/affiliation/${id}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return result;
}

export async function updateAgency(id: string, data: EditAgencyParams): Promise<EditAgencyResponse> {
  const { result } = await request(`${basePath()}/agency/${id}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return result;
}

export async function listUserEmailLogs(id: string, params): Promise<UserEmailLogsResponse> {
  const { result } = await request(`${basePath()}/user/${id}/email-logs?${QueryString.stringify(params)}`, {
    method: 'GET',
  });
  return result;
}

export async function resendActivationEmail(id: string): Promise<boolean> {
  const { result } = await request(`${basePath()}/user/${id}/resend-activation-email`, {
    method: 'POST',
  });
  return result;
}

export async function getUser(id: string): Promise<Agent> {
  const { result } = await request(`${basePath()}/user/${id}`, {
    method: 'GET',
  });
  return result;
}

export async function createCommission(params: CreateCommissionParams): Promise<CreateCommissionResponse> {
  const { result } = await request(`${basePath()}/commission`, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return result;
}

export async function getCommissions(params: getCommissionsParams): Promise<List<Commission>> {
  const res: getCommissionsResponse = await request(`${basePath()}/commission?${QueryString.stringify(params)}`, {
    method: 'GET',
  });

  res.result.rows = res.result.rows.map((rule) => {
    return {
      id: rule.id,
      description: rule.description,
      commissionPercentage: rule.commissionPercentage,
      commissionType: rule.type,
      startDate: rule.startDate,
      endDate: rule.endDate,
      type: rule.type,
      regions: rule.regions,
      rules: rule.rules,
      createdAt: rule.createdAt,
      updatedAt: rule.updatedAt,
      createdBy: rule.createdBy,
      updatedBy: rule.updatedBy,
    };
  });

  return res.result;
}

export async function updateCommission(commissionId: string, data: EditCommissionParams) {
  await request(`${basePath()}/commission/${commissionId}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
export async function deleteCommission(id: string) {
  await request(`${basePath()}/commission/${id}`, { method: 'DELETE' });
}

function getUpdatedAtDate(eoi: Eoi) {
  if (eoi.approvedAt) {
    return formatDateWithClock(eoi.approvedAt);
  }
  if (eoi.rejectedAt) {
    return formatDateWithClock(eoi.rejectedAt);
  }

  return undefined;
}
