import React, { useEffect, useMemo } from 'react';

import { sum } from 'lodash';

import { Box, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';

import { formatDateWithClock } from '~/services/TimeService';

import currencyFormatter from '~/utils/currencyFormatter';

import { CreditCommentMap, SingleReconciledCredit } from './types';

interface Props {
  unspentCredits: Array<SingleReconciledCredit>;
  creditCommentMap: CreditCommentMap;
  creditsToExtend: GridRowSelectionModel;
  setCreditsToExtend: (newCredits: GridRowSelectionModel) => void;
  totalToExtend: number;
  setTotalToExtend: (newTotal: number) => void;
  currency: string;
}

export default function UnspentCreditsTable({
  unspentCredits,
  creditCommentMap,
  creditsToExtend,
  setCreditsToExtend,
  totalToExtend,
  setTotalToExtend,
  currency,
}: Props) {
  const mappedUnspentCredits: Record<string, number> = useMemo(() => {
    const mappedCredits = {};
    unspentCredits.forEach((credit) => {
      mappedCredits[credit.id_credit] = credit.amount;
    });
    return mappedCredits;
  }, [unspentCredits]);

  useEffect(() => {
    const mappedAmounts: Array<number> = creditsToExtend.map((value) => mappedUnspentCredits[value]);
    const total = sum(mappedAmounts);
    setTotalToExtend(total);
  }, [creditsToExtend, mappedUnspentCredits, setTotalToExtend]);

  const columns: GridColDef[] = [
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      valueFormatter: (value: number) => {
        return currencyFormatter(currency, value, 2);
      },
      maxWidth: 100,
    },
    {
      field: 'expires_at',
      headerName: 'Expires At',
      flex: 1,
      valueFormatter: (value: string) => {
        return formatDateWithClock(value);
      },
      maxWidth: 200,
    },
    {
      field: 'comment',
      headerName: 'Comment',
      sortable: false,
      flex: 1,
      valueGetter: (_, row) => {
        return creditCommentMap[row.id_credit] ?? '';
      },
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      flex: 1,
      valueFormatter: (value: string) => {
        return formatDateWithClock(value);
      },
      maxWidth: 200,
    },
  ];

  return (
    <>
      <Typography variant="h6">Amount To Extend: {currencyFormatter(currency, totalToExtend, 2)}</Typography>
      <Box height={'200px'}>
        <DataGrid
          rows={unspentCredits}
          columns={columns}
          checkboxSelection
          getRowId={(credit) => credit.id_credit}
          getRowHeight={() => 'auto'}
          disableRowSelectionOnClick
          rowSelectionModel={creditsToExtend}
          onRowSelectionModelChange={(value) => {
            setCreditsToExtend(value);
          }}
          density="compact"
          hideFooterSelectedRowCount={true}
          hideFooter={true}
        />
      </Box>
    </>
  );
}
