import React, { useEffect } from 'react';

import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import { ArrowForwardIos } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import { Checkbox, Input } from '~/components/Common/Forms/fields';
import DebugModal from '~/components/DebugModal/DebugModal';

import { addQuery } from '~/utils/url';

import Spinner from '../../Common/Spinner';
import usePromoSimulator from '../hooks/usePromoSimulator';

interface Props {
  initCodeName?: string;
  initOrderId?: string;
  initApplyWithOriginalUserAccount?: boolean;
  tenant: App.Tenant;
}

const PromoTester = ({ initCodeName, initOrderId, initApplyWithOriginalUserAccount, tenant }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { orderDiscount, promoCodeErrors, isLoading, reSimulateDiscountEvent } = usePromoSimulator({
    initOrderId,
    initCodeName,
    initApplyWithOriginalUserAccount,
  });

  const { control, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      codeName: initCodeName || '',
      orderId: initOrderId || '',
      brand: tenant.brand,
      applyWithOriginalUserAccount: initApplyWithOriginalUserAccount,
    },
  });

  useEffect(() => {
    setValue('codeName', initCodeName || '');
    setValue('orderId', initOrderId || '');
  }, [initCodeName, initOrderId, setValue]);

  const setCode = (code) => {
    setValue('codeName', code);
    const values = getValues();
    reSimulateDiscountEvent({
      codeName: code,
      ...values,
    });
  };

  const setOrder = (order) => {
    setValue('orderId', order);
    const values = getValues();
    reSimulateDiscountEvent({
      orderId: order,
      ...values,
    });
  };

  const onSubmit = async (formData) => {
    if (formData.orderId === '' || formData.codeName === '') {
      enqueueSnackbar('Please enter both Order ID and Code Name', { variant: 'error' });
      return;
    }
    try {
      const newLocation = addQuery(location, formData);
      history.push(newLocation);
      reSimulateDiscountEvent({
        codeName: formData.codeName,
        orderId: formData.orderId,
        brand: tenant.brand,
        applyWithOriginalUserAccount: formData.applyWithOriginalUserAccount,
      });
    } catch (err) {
      enqueueSnackbar(err.message || 'Error! Unable to update filters.', { variant: 'error' });
    }
  };

  return (
    <>
      <Box mb={2} component="form" onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="body1" fontSize={14}>
          This form allows for testing promo code against orders to allow for a shorter feedback loop when testing promo
          logic. It works best when using against orders that don't have any existing promo codes applied.
          <br />
          Note: This is testing the promo log behavior and not the promo code request behavior (which depends on the
          information be passed to /api/promo/discount when the promo is being retrieved - see{' '}
          <a href="/marketing/promo-requests">promoRequests</a> for more info here)
          <Button onClick={() => setCode('e2etestall')}>Set e2etestall Promo</Button>
          <Button onClick={() => setCode('e2etest')}>Set e2etest Promo</Button>
          <Button onClick={() => setOrder('test')}>Set test order</Button>
        </Typography>
        <Stack direction="row" spacing={3} alignItems="center">
          <Input fullWidth control={control} muiLabel="Code Name" name="codeName" />
          <Input fullWidth control={control} muiLabel="Order ID" name="orderId" />
          <Checkbox control={control} label="Use Original Account" name="useOrignalUserAccount" />
          <Button fullWidth variant="contained" type="submit">
            Simulate for {tenant.title}
          </Button>
        </Stack>
      </Box>
      <Box>
        {isLoading && <Spinner />}
        {orderDiscount && (
          <Stack direction="row" spacing={3} alignItems="center">
            <DebugModal title={`Order`} type="generic" data={orderDiscount.internals.svcOrderOrder} />
            <ArrowForwardIos />
            <DebugModal title={`Promo Order`} type="generic" data={orderDiscount.internals.promoOrder} />
            <ArrowForwardIos />
            <DebugModal
              title={`${'discount_total' in orderDiscount.promo ? orderDiscount.promo.discount_total : ''} ${
                orderDiscount.promo.currency
              } - Promo Code ${orderDiscount.promo.code_name} `}
              type="generic"
              data={orderDiscount.promo}
            />
            <DebugModal title={`All details`} type="generic" data={orderDiscount} />
          </Stack>
        )}
        {promoCodeErrors?.length > 0 &&
          promoCodeErrors.map((pc, i) => <ErrorDisplay key={i} severity="warning" message={JSON.stringify(pc)} />)}
      </Box>
    </>
  );
};

export default PromoTester;
