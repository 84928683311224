import React from 'react';

import { WithSnackbarProps } from 'notistack';
import fileDownload from 'react-file-download';
import { Helmet } from 'react-helmet';
import { Link as RouterLink, withRouter } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import PermissionedComponent from '~/components/Common/PermissionedComponent';
import OrderDetailAccountingData from '~/components/Purchases/OrderDetail/OrderDetailAccountingData';
import CancellationPolicyWarningModal from '~/components/Refund/CancellationPolicyWarningModal';
import NewRefundModal from '~/components/Refund/NewModal';
import RefundItemsReminderModal from '~/components/Refund/RefundItemsReminderModal';
import OrderDetailVirtualCreditCards from '~/components/VirtualCreditCards/OrderDetail/OrderDetailPage';
import { withTenant } from '~/components/hoc';

import { TRAVELLER_REFUND_STATUS_OPTIONS_MAP } from '~/consts/flight';
import { ITEM_STATUS_CANCELLED } from '~/consts/order';

import BedbankService from '~/services/BedbankService';
import { getBusiness, getEmployee } from '~/services/BusinessTraveller/BusinessTravellerService';
import { determineLoyaltyStatus, getEarnOrder, getPartnerships } from '~/services/LoyaltyService';
import { getFlightDetailsByOrderAndItem, getReservationByOrder, getTaxInvoiceDetails } from '~/services/OrdersService';
import { getTaxInvoicePDF } from '~/services/PDFService';
import { getPaymentTotalsByDate } from '~/services/PaymentHelper';
import {
  callDepositTriggers,
  callInstalmentTriggers,
  callReserveForZeroTriggers,
  getDepositDetails,
  getInstalmentDetails,
  getPaymentPlans,
  getReserveForZeroDetails,
} from '~/services/PaymentsService';
import { formatDateShortDD, formatDateShortDateAndMonth } from '~/services/TimeService';
import * as TourService from '~/services/ToursService';
import UsersService from '~/services/UsersService';

import featureToggle from '~/utils/featureToggle';
import getEarnOrderPartnerships from '~/utils/getEarnOrderPartnerships';
import isOrderRefundable from '~/utils/isOrderRefundable';
import { isAllCancelled } from '~/utils/order';
import { calcOrderPayments } from '~/utils/refund';
import { reportError } from '~/utils/reportError';

import AddonsList from './AddonsList/list';
import BookingProtectionItem from './BookingProtection/BookingProtectionItem';
import OrderItemCredit from './BusinessTraveller/OrderItemBusinessCredit';
import CarHireOrderListDetails from './CarHire/CarHireOrderListDetails';
import CruiseOrderListDetails from './Cruises/CruiseOrderListDetails';
import CustomOfferOrderList from './CustomOffer/CustomOfferOrderList';
import ExperiencesList from './ExperiencesList/ExperiencesList';
import OrderInstalmentDetails from './Instalments/OrderInstalmentDetails';
import OrderDepositDetails from './OrderDepositDetails';
import OrderDetailBedbank from './OrderDetailBedbank';
import OrderDetailBookingRequests from './OrderDetailBookingRequests';
import OrderDetailBusinessCredits from './OrderDetailBusinessCredits';
import OrderDetailNotes from './OrderDetailNotes';
import OrderDetailOffer from './OrderDetailOffer';
import OrderDetailPartnerships from './OrderDetailPartnerships';
import OrderDetailPaymentErrorLogs from './OrderDetailPaymentErrorLogs';
import OrderDetailPaymentPlanLogs from './OrderDetailPaymentPlanLogs';
import OrderDetailPayments from './OrderDetailPayments';
import OrderDetailRewards from './OrderDetailRewards';
import OrderDetailTour from './OrderDetailTour';
import OrderFlightItemContainer from './OrderFlightItems';
import { getPerPersonBreakdown, getPerPersonBreakdownWithJourneyFares } from './OrderFlightItems/Item';
import OrderFlightFees from './OrderFlightItems/OrderFlightFees';
import OrderGift from './OrderGift';
import OrderInsuranceItemList from './OrderInsuranceItemList';
import OrderMerchantFeeDetails from './OrderMerchantFeeDetails';
import OrderMeta from './OrderMeta';
import OrderOfflineFlightItemContainer from './OrderOfflineFlightItemContainer';
import OrderServiceFeeItem from './OrderServiceFeeItem';
import OrderTermsAndConditions from './OrderTermsAndConditions';
import RebookModal from './RebookModal';
import ReconfirmModal from './ReconfirmModal';
import OrderReserveForZeroDetails from './ReserveForZero/OrderReserveForZeroDetails';
import SubscriptionItem from './SubscriptionOffer/SubscriptionItem';

interface Props {
  addOfferToCart: (pkg?: App.Package, currencyCode?: string, offer?: App.Offer, orderItem?: App.OrderItem) => void;
  allowToggleReservationType: (allow: boolean) => void;
  customer: App.User;
  customerEmailToAgentState: any;
  emailToCustomerState: any;
  emailToVendorState: any;
  flightsCreditReservationId: string;
  getTravellers: () => void;
  history: string[];
  isBookingDates: boolean;
  isChangingDates: boolean;
  isConvertingToBNBL: boolean;
  offers: Array<{ items: Array<App.OrderItem>; offer: App.AccommodationOffer }>;
  onEmptyCart: () => void;
  order: App.Order;
  refreshData: () => void;
  refunds: any;
  resendCustomerEmail: () => void;
  resendCustomerEmailToAgent: () => void;
  resendOrderGiftDeliveryEmail: () => void;
  resendVendorEmail: () => void;
  resendVendorEmailToAgent: () => void;
  selectOffer: (offerId: string) => void;
  setIsBookingDates: (isBookingDates: boolean) => void;
  setIsChangingDates: (isChangingDates: boolean) => void;
  setIsConvertingToBNBL: (isConvertingToBNBL: boolean) => void;
  setIsPuttingOnHold: (isPuttingOnHold: boolean) => void;
  setOrderId: (orderId: string) => void;
  setReservationType: (reservationType: string) => void;
  travellers: App.OrderTourTraveller[];
  vendorEmailToAgentState: any;
  tenant: App.Tenant;
  subscriptions: App.MembershipSubscriptions | null;
}

interface IReservations {
  [key: string]: {
    vcc_reservation: IVCCReservation;
  };
}

interface IVCCReservation {
  card_id: string;
  currency: string;
  deposit_amount: number;
  deposit_percent: number;
  deposit_state: string;
  total: number;
  type_of_pay: string;
}

interface State {
  // App state
  showingRefundDetail: boolean;
  showingRebookDetail: string | null;
  showingReconfirmDetail: string | null;
  loyaltyStatus: any;
  bedbankRoomsInfo: {
    [reservationRoomId: string]: {
      id: string;
      status: string;
      reservationStatus: string;
      reservationRoomStatus: string;
      rebook?: App.Bedbank.ReservationRebookInfo;
    };
  };
  tourRoomsInfo: any;
  hasAllowedRefund: boolean;
  paymentPlanLoaded: boolean;
  paymentPlanData: any;
  pointsRequestStatus: string;
  purchaser: string;
  partnerships: any;
  invoiceDownloading: boolean;
  downloadFailed: boolean;
  showingRefundReminderModal: boolean;
  depositDetails: any;
  instalmentDetails: any;
  reserveForZeroDetails: any;
  reserveForZeroTriggerSuccess: boolean | null;
  itemType: string;
  accountingData: any[];
  refundItemId: any;
  reservationsFetched: boolean;
  reservations: IReservations;
  earnOrder: any;
  refundAutomatic: any;
  refundRoomId: any;
  expendedTransactionHistory: boolean;
  isForceBundleAlertVisible: boolean;
  isAfterChangeDate: boolean;
  orderCreditItem: any;
  businessInfo: {
    businessName: string;
    businessCreditCustomerId: string;
    employeePersonalAccountInfo: string;
    personalName: string;
    personalEmail: string;
  };
  isAccommodationRefundable: boolean;
  shouldShowRefundWarningModal: boolean;
  refundApprover: string;
}

class OrderDetailPage extends React.Component<Props & WithSnackbarProps, State> {
  static defaultProps: Partial<Props> = {
    emailToCustomerState: {},
    emailToVendorState: {},
    customerEmailToAgentState: {},
    vendorEmailToAgentState: {},
  };

  constructor(props) {
    super(props);

    const hasAllowedRefund =
      calcOrderPayments(props.order.payments).sum > 0 ||
      props.order.payments[0]?.deferredPaymentDetails?.is_active ||
      props.order.car_hire_items.length > 0;

    this.state = {
      showingRefundDetail: false,
      showingRebookDetail: null,
      showingReconfirmDetail: null,
      loyaltyStatus: {},
      bedbankRoomsInfo: {},
      tourRoomsInfo: {},
      hasAllowedRefund,
      paymentPlanLoaded: false,
      paymentPlanData: null,
      pointsRequestStatus: '',
      purchaser: '',
      partnerships: null,
      invoiceDownloading: false,
      downloadFailed: false,
      showingRefundReminderModal: false,
      depositDetails: {},
      instalmentDetails: {},
      reserveForZeroDetails: {},
      reserveForZeroTriggerSuccess: null,
      itemType: null,
      accountingData: [],
      refundItemId: null,
      reservations: null,
      reservationsFetched: false,
      earnOrder: null,
      refundAutomatic: null,
      refundRoomId: null,
      expendedTransactionHistory: true,
      isForceBundleAlertVisible: this.props.offers.some((offer) => offer.offer.bundled_with_flights_only),
      isAfterChangeDate: false,
      orderCreditItem: null,
      businessInfo: {
        businessName: undefined,
        businessCreditCustomerId: undefined,
        employeePersonalAccountInfo: undefined,
        personalName: undefined,
        personalEmail: undefined,
      },
      isAccommodationRefundable: true,
      shouldShowRefundWarningModal: false,
      refundApprover: undefined,
    };
  }

  async componentDidMount() {
    const purchaser = await this.fetchPurchaserData();
    this.setState({ purchaser: purchaser.fullName });
    try {
      getReservationByOrder(this.props.order).then((reservations) => {
        const isAccommodationRefundable = isOrderRefundable(this.props.order, reservations);

        this.setState({
          reservationsFetched: true,
          reservations,
          isAccommodationRefundable: isAccommodationRefundable,
        });
      });

      let data = await getPaymentPlans(this.props.order.id_orders);

      if (data.plans.length == 0) {
        data = null;
      }

      let bedbankRoomsInfo = {};

      if (this.props.order.bedbank_items.length) {
        const bedbankItemsInfoData = await Promise.all(
          this.props.order.bedbank_items.flatMap((bedbankItem) =>
            BedbankService.getBookingInfo(bedbankItem.id_reservation),
          ),
        );

        const bedbankItemsRebookInfoData = await Promise.all(
          this.props.order.bedbank_items.map((bedbankItem) =>
            BedbankService.getRebookableInfo([bedbankItem.id_reservation]),
          ),
        );

        bedbankRoomsInfo = bedbankItemsInfoData.reduce((acc, { result }) => {
          const rooms = result.rooms;

          for (const room of rooms) {
            acc[room.id] = {
              id: room.id,
              status: room.status,
              reservationStatus: result.reservationStatus,
              reservationRoomStatus: room.reservationRoomStatus,
            };
          }

          return acc;
        }, {});

        bedbankRoomsInfo = bedbankItemsRebookInfoData.reduce((acc, { result }) => {
          const rooms = result;

          for (const room of rooms) {
            if (acc[room.reservationRoomId] && !room.newReservationId) {
              acc[room.reservationRoomId] = {
                ...acc[room.reservationRoomId],
                rebook: room,
              };
            }
          }

          return acc;
        }, bedbankRoomsInfo);
      }

      let tourRoomsInfo = {};
      if (this.props.order.tour_items?.length) {
        const tourItemsInfoData = await Promise.all(
          this.props.order.tour_items.map((tourItem) => TourService.getBookingInfo(tourItem.reservation_id)),
        );

        tourRoomsInfo = tourItemsInfoData.reduce((acc, { result }) => {
          acc[result.reservationId] = result;

          return acc;
        }, {});
      }

      const partnershipsData = await getPartnerships(this.props.order.region_code, this.props.order.brand);
      const allPartnerships = (partnershipsData && partnershipsData.result) || [];
      const loyaltyStatus = determineLoyaltyStatus(this.props.order, allPartnerships);

      const [depositDetails, instalmentDetails, reserveForZeroDetails] = await Promise.all([
        getDepositDetails(this.props.order.id),
        getInstalmentDetails(this.props.order.id),
        getReserveForZeroDetails(this.props.order.id),
      ]);

      if (this.props.order.business_id !== null) {
        const business = await getBusiness(this.props.order.business_id);
        this.setState({
          businessInfo: { ...this.state.businessInfo, businessName: business.name },
        });
        const employeeInfo = await getEmployee(this.props.order.fk_customer_id);
        this.setState({
          businessInfo: {
            ...this.state.businessInfo,
            businessCreditCustomerId: employeeInfo.creditCustomerId ?? employeeInfo.customerId,
          },
        });
        const employeeCreditCustomerInfo: App.User = await UsersService.getUser(
          employeeInfo.creditCustomerId ?? employeeInfo.customerId,
        );
        this.setState({
          businessInfo: {
            ...this.state.businessInfo,
            personalName: employeeCreditCustomerInfo.fullName,
            personalEmail: employeeCreditCustomerInfo.email,
          },
        });
      }

      const earnOrder = await Promise.all(getEarnOrder(this.props.order, getEarnOrderPartnerships(allPartnerships)));
      this.setState({
        paymentPlanLoaded: true,
        paymentPlanData: data,
        partnerships: allPartnerships,
        loyaltyStatus: loyaltyStatus,
        bedbankRoomsInfo,
        tourRoomsInfo,
        depositDetails,
        instalmentDetails,
        reserveForZeroDetails,
        earnOrder,
      });
    } catch (error) {
      reportError(error);
    }
  }

  debugFormatter(cell) {
    return JSON.stringify(cell);
  }

  fetchPurchaserData = () => {
    const { order } = this.props;

    return UsersService.getUser(order.fk_purchaser_id);
  };

  triggerRefund = () => {
    this.setState({
      showingRefundDetail: true,
    });
    window.hj =
      window.hj ||
      function () {
        // I honestly have no idea what this line does.
        // eslint-disable-next-line prefer-rest-params
        (window.hj.q = window.hj.q || []).push(arguments);
      };
    //hotjar recording trigger
    window.hj('trigger', 'refund');
  };

  setRefundComment = (comment: string) => {
    this.setState({ refundApprover: comment });
  };

  closeRefundWarningModal = () => {
    this.setState({
      shouldShowRefundWarningModal: false,
    });
  };

  showRefundModal = async ({ itemId, roomId = null, auto = null, itemType = null }) => {
    this.setState({
      refundItemId: itemId,
      refundRoomId: roomId,
      refundAutomatic: auto,
      itemType: itemType,
    });
    if (!this.state.isAccommodationRefundable) {
      this.setState({
        shouldShowRefundWarningModal: true,
      });
    } else {
      this.triggerRefund();
    }
  };

  showRebookingModal = ({ itemId }) => {
    this.setState({
      showingRebookDetail: itemId,
    });
  };

  showReconfirmModal = ({ itemId }) => {
    this.setState({
      showingReconfirmDetail: itemId,
    });
  };

  hasRemindingItem = (key) => {
    const { order } = this.props;
    return !!order[key]?.some((item) => item.status !== ITEM_STATUS_CANCELLED);
  };

  showWarningModal = () => {
    const hasExperiencesToBeReminded =
      this.hasRemindingItem('addon_items') || this.hasRemindingItem('experience_items');
    const hasCruiseToBeReminded = this.hasRemindingItem('cruise_items');
    const hasInsuranceToBeReminded = this.hasRemindingItem('insurance_items');
    const hasFlightsToBeReminded = this.hasRemindingItem('flight_items');

    if (hasExperiencesToBeReminded || hasInsuranceToBeReminded || hasFlightsToBeReminded || hasCruiseToBeReminded) {
      // postpones refreshData to when modal is dismissed
      this.setState({
        showingRefundReminderModal: true,
        showingRefundDetail: false,
      });
    } else {
      this.props.refreshData();
    }
  };

  onRefund = () => {
    const { order } = this.props;
    const isAccommodationItem = order.accommodation_items.find((item) => item.id === this.state.refundItemId);

    if (!isAccommodationItem) {
      this.props.refreshData();
      return;
    }

    this.showWarningModal();
  };

  hideRefundDetail = () => {
    if (window.confirm(`Are you sure? Data will be wiped out`)) {
      this.setState({
        showingRefundDetail: false,
      });

      this.props.refreshData();
    }
  };

  downloadTax = async () => {
    this.setState({
      invoiceDownloading: true,
      downloadFailed: false,
    });

    try {
      const invoiceList = await this.createTaxInvoiceDetails();
      const pdf = await getTaxInvoicePDF(invoiceList);
      const fileName = `LuxuryEscapes-Tax-Invoice-${this.props.order.id}.pdf`;
      await fileDownload(pdf, fileName);
    } catch (error) {
      console.warn(error);
      this.setState({
        downloadFailed: true,
        invoiceDownloading: false,
      });
    } finally {
      this.setState({
        invoiceDownloading: false,
      });
    }
  };

  newPointsRequest = (status) => {
    this.setState({
      pointsRequestStatus: status,
    });
  };

  packagesInvoiceDetailsList = (billingCountry, isLEBT) => {
    const { offers } = this.props;
    const result = offers.reduce(
      (acc, { offer, items }) =>
        acc.concat(
          items
            .filter((item) => isLEBT || item.status !== 'cancelled')
            .map((item) => {
              const itemPackage = offer.packages.find(
                (p) =>
                  p.id_salesforce_external === item.offer_package.id_salesforce_external ||
                  p.le_package_id === item.offer_package.le_package_id,
              );
              return {
                description: [
                  `Offer Details: ${item.offer_package.name}, ${itemPackage.deal_option_name}`,
                  `Location: ${offer.location}`,
                  `Booking Number: ${item.booking_number}`,
                ],
                total_price: Number(item.total),
                billing_country: billingCountry,
                status: item.status,
              };
            }),
        ),
      [],
    );
    return result;
  };

  getFlightBillingCountry = (): string => {
    const { order } = this.props;
    const flights = order.flight_items[0].journey.departing.flights;
    if (flights[0].departing_country === flights[flights.length - 1].arrival_country) {
      return flights[0].departing_country;
    } else {
      return 'International';
    }
  };

  getFlightDetails = async (isLEBT, refunds) => {
    const { order } = this.props;

    const mapPassengerPlural = {
      child: 'children',
      adult: 'adults',
      infant: 'infants',
    };

    if (order.has_flight) {
      const flightDetailsList = [];
      const serviceFee = [];
      const bookingFee = [];
      let billingCountry;

      if (order.flight_items.length === 2) {
        let flightDetails;
        for (let index = 0; index < order.flight_items.length; index++) {
          const description = [];
          const flight_item = order.flight_items[index];
          flightDetails = await getFlightDetailsByOrderAndItem(flight_item.fk_order_id, flight_item.id);

          const flights = flightDetails.journey.journeyFares.fares[0].flightGroups[0].flights;
          const flight = flights[0];
          const arrivalFlight = flights[flights.length - 1];
          const flightDetailsMessage = `${flight.departureCity} (${flight.departureAirport}) > ${arrivalFlight.arrivalCity} (${arrivalFlight.arrivalAirport})`;
          const legNumber = flightDetails.journey.legNumber;
          if (legNumber === 0) {
            description.push(`Departing Flight: ${flightDetailsMessage}`);
            description.push(`Booking Ref: ${order.flight_items[0].pnr_id}`);
          } else if (legNumber === 1) {
            description.push(`Returning Flight: ${flightDetailsMessage}`);
            description.push(`Booking Ref: ${order.flight_items[0].pnr_id}`);
          }
          const perPersonPriceBreakdown = getPerPersonBreakdownWithJourneyFares(
            flightDetails.journey.journeyFares.fares,
            flightDetails.travellers,
          );

          billingCountry = flight.arrivalCountry === flight.departureCountry ? flight.arrivalCountry : 'International';

          const nonCancelledFlightDetails = flightDetails.travellers.filter(
            (traveller) => isLEBT || traveller.refund_status !== 'internal_refunded',
          );
          nonCancelledFlightDetails.map((traveller) => {
            flightDetailsList.push({
              description: [
                `Traveller: ${traveller.given_name} ${traveller.middlename ?? ''} ${traveller.surname}`,
                ...description,
              ],
              total_price: Number(perPersonPriceBreakdown[mapPassengerPlural[traveller.type]]),
              billing_country: billingCountry,
              status: TRAVELLER_REFUND_STATUS_OPTIONS_MAP[traveller.refund_status],
            });
          });

          if (flightDetails.journey.serviceFee) {
            serviceFee.push({
              description: ['Flights Service Fee'],
              total_price: Number(flightDetails.journey.serviceFee),
              billing_country: billingCountry,
              status: refunds.find(
                (refund) =>
                  refund.fk_items === flight_item.id &&
                  Number(refund.accounting_amount) === Number(flightDetails.journey.serviceFee),
              )
                ? 'Refunded'
                : 'Completed',
            });
          }

          if (flightDetails.journey.bookingFee) {
            bookingFee.push({
              description: ['Flights Booking Fee'],
              total_price: Number(flightDetails.journey.bookingFee),
              billing_country: billingCountry,
              status: refunds.find(
                (refund) =>
                  refund.fk_items === flight_item.id &&
                  Number(refund.accounting_amount) === Number(flightDetails.journey.bookingFee),
              )
                ? 'Refunded'
                : 'Completed',
            });
          }

          const travellers = flightDetails.travellers;
          travellers.map((traveller) => {
            if (traveller.extras) {
              // Baggage V1
              for (let i = 0; i < traveller.extras.baggage.length; i++) {
                let baggageMessage: string;
                let totalPrice: number;
                const currentBaggageOption = traveller.extras.baggage[i];
                const baggageId = currentBaggageOption.id;
                const departingBaggageItem = flightDetails.journey.departing.extras.baggage.filter(
                  (baggage) => baggage.id === baggageId,
                )[0];
                const flightWayMessage =
                  legNumber === 0
                    ? `Departing Flight: ${flightDetailsMessage}`
                    : `Returning Flight: ${flightDetailsMessage}`;

                if (currentBaggageOption.departing.count > 1) {
                  baggageMessage = `Baggage: ${currentBaggageOption.departing.count} x ${baggageId}`;
                  totalPrice = Number(currentBaggageOption.departing.count * departingBaggageItem.amount);
                } else if (currentBaggageOption.returning.count > 1) {
                  baggageMessage = `Baggage: ${currentBaggageOption.returning.count} x ${baggageId}`;
                  totalPrice = Number(currentBaggageOption.returning.count * departingBaggageItem.amount);
                }

                flightDetailsList.push({
                  description: [
                    `Traveller: ${traveller.given_name} ${traveller.middlename ?? ''} ${traveller.surname}`,
                    baggageMessage,
                    flightWayMessage,
                  ],
                  total_price: totalPrice,
                  billing_country: billingCountry,
                  status,
                });
              }
            } else if (traveller.baggage) {
              // Baggage V2 - TwoOneWays
              for (const currentBaggageOption of traveller.baggage) {
                let totalPrice: number;
                let baggageMessage: string;
                let flightWayMessage: string;
                const baggageId = currentBaggageOption.baggage_type;
                const departingBaggageItem = flightDetails.journey.departing.extras.baggage.filter(
                  (baggage) => baggage.id === baggageId,
                )[0];
                const departingBaggageItemAmount = departingBaggageItem[`per_${traveller.type}`].cost;

                if (currentBaggageOption.departing_count > 0 && legNumber === 0) {
                  flightWayMessage = `Departing Flight: ${flightDetailsMessage}`;
                  totalPrice = Number(currentBaggageOption.departing_count * departingBaggageItemAmount);
                  baggageMessage = `Baggage: ${currentBaggageOption.departing_count} x ${baggageId}`;
                } else if (currentBaggageOption.returning_count > 0 && legNumber === 1) {
                  flightWayMessage = `Returning flight: ${flightDetailsMessage}`;
                  totalPrice = Number(currentBaggageOption.returning_count * departingBaggageItemAmount);
                  baggageMessage = `Baggage: ${currentBaggageOption.returning_count} x ${baggageId}`;
                }

                if (baggageMessage && flightWayMessage && totalPrice) {
                  flightDetailsList.push({
                    description: [
                      `Traveller: ${traveller.given_name} ${traveller.middlename ?? ''} ${traveller.surname}`,
                      baggageMessage,
                      flightWayMessage,
                    ],
                    total_price: totalPrice,
                    billing_country: billingCountry,
                    status,
                  });
                }
              }
            }
          });
        }
      } else if (order.flight_items.length === 1) {
        const description = [];

        const flight_item = order.flight_items[0];
        const flightDetails = await getFlightDetailsByOrderAndItem(flight_item.fk_order_id, flight_item.id);

        const departingFlights = flightDetails.journey.journeyFares.fares[0].flightGroups[0].flights;
        const departingFlight = departingFlights[0];
        const departingArrivalFlight = departingFlights[departingFlights.length - 1];
        const departingFlightDetailsMessage = `Departing Flight: ${departingFlight.departureCity} (${departingFlight.departureAirport}) > ${departingArrivalFlight.arrivalCity} (${departingArrivalFlight.arrivalAirport})`;
        description.push(departingFlightDetailsMessage);

        let returningFlightDetailsMessage;
        if (flightDetails.journey.journeyFares.fares.length > 1) {
          const returningFlights = flightDetails.journey.journeyFares.fares[1].flightGroups[0].flights;
          const returningFlight = returningFlights[0];
          const returningArrivalFlight = returningFlights[returningFlights.length - 1];
          returningFlightDetailsMessage = `Returning Flight: ${returningFlight.departureCity} (${returningFlight.departureAirport}) > ${returningArrivalFlight.arrivalCity} (${returningArrivalFlight.arrivalAirport})`;
          description.push(returningFlightDetailsMessage);
        }

        description.push(`Booking Ref: ${order.flight_items[0].pnr_id}`);

        const perPersonPriceBreakdown = getPerPersonBreakdown(
          flightDetails.journey.price_breakdown,
          flightDetails.travellers,
        );

        billingCountry =
          departingFlight.arrivalCountry === departingFlight.departureCountry
            ? departingFlight.arrivalCountry
            : 'International';

        const nonCancelledFlightDetails = flightDetails.travellers.filter(
          (traveller) => isLEBT || traveller.refund_status !== 'internal_refunded',
        );
        nonCancelledFlightDetails.map((traveller) => {
          flightDetailsList.push({
            description: [
              `Traveller: ${traveller.given_name} ${traveller.middlename ?? ''} ${traveller.surname}`,
              ...description,
            ],
            total_price: Number(perPersonPriceBreakdown[mapPassengerPlural[traveller.type]]),
            billing_country: billingCountry,
            status: TRAVELLER_REFUND_STATUS_OPTIONS_MAP[traveller.refund_status],
          });
        });

        if (flightDetails.journey.serviceFee) {
          serviceFee.push({
            description: ['Flights Service Fee'],
            total_price: Number(flightDetails.journey.serviceFee),
            billing_country: billingCountry,
            status: refunds.find(
              (refund) =>
                refund.fk_items === flight_item.id &&
                Number(refund.accounting_amount) === Number(flightDetails.journey.serviceFee),
            )
              ? 'Refunded'
              : 'Completed',
          });
        }
        if (flightDetails.journey.bookingFee) {
          bookingFee.push({
            description: ['Flights Booking Fee'],
            total_price: Number(flightDetails.journey.bookingFee),
            billing_country: billingCountry,
            status: refunds.find(
              (refund) =>
                refund.fk_items === flight_item.id &&
                Number(refund.accounting_amount) === Number(flightDetails.journey.bookingFee),
            )
              ? 'Refunded'
              : 'Completed',
          });
        }

        const travellers = flightDetails.travellers;
        travellers.map((traveller) => {
          if (traveller.extras) {
            // Baggage V1
            for (let i = 0; i < traveller.extras.baggage.length; i++) {
              const currentBaggageOption = traveller.extras.baggage[i];
              const baggageId = currentBaggageOption.id;
              const departingBaggageItem = flightDetails.journey.departing.extras.baggage.filter(
                (baggage) => baggage.id === baggageId,
              )[0];
              if (currentBaggageOption.departing.count > 0) {
                flightDetailsList.push({
                  description: [
                    `Traveller: ${traveller.given_name} ${traveller.middlename ?? ''} ${traveller.surname}`,
                    `Baggage: ${currentBaggageOption.departing.count} x ${baggageId}`,
                    departingFlightDetailsMessage,
                  ],
                  total_price: Number(currentBaggageOption.departing.count * departingBaggageItem.amount),
                  billing_country: billingCountry,
                });
              }
              if (currentBaggageOption.returning.count > 0) {
                flightDetailsList.push({
                  description: [
                    `Traveller: ${traveller.given_name} ${traveller.middlename ?? ''} ${traveller.surname}`,
                    `Baggage: ${currentBaggageOption.returning.count} x ${baggageId}`,
                    returningFlightDetailsMessage,
                  ],
                  total_price: Number(currentBaggageOption.returning.count * departingBaggageItem.amount),
                  billing_country: billingCountry,
                });
              }
            }
          }
        });
      }

      if (serviceFee.length > 0) {
        flightDetailsList.push(...serviceFee);
      }
      if (bookingFee.length) {
        flightDetailsList.push(...bookingFee);
      }

      return flightDetailsList;
    } else if (order.has_offline_flight) {
      const description = ['Offline flight, Booking Ref(s): ' + order.offline_flight_items[0].pnr_ids.join(', ')];
      const total_price = Number(order.offline_flight_items[0].total);
      return [
        {
          description,
          total_price,
        },
      ];
    }
    return [];
  };

  getInsuranceDetails = (billingCountry, isLEBT) => {
    const { order } = this.props;
    const nonCancelledInsuranceDetails = order.insurance_items.filter((item) => isLEBT || item.status !== 'cancelled');

    return (nonCancelledInsuranceDetails || []).map((item) => ({
      description: [
        `Travel Insurance: Insurance ${item.product_name}`,
        `Coverage dates: ${formatDateShortDateAndMonth(new Date(item.start_date))} - ${formatDateShortDD(
          new Date(item.end_date),
        )}`,
        `Policy ID: ${item.contract_number}`,
      ],
      total_price: Number(item.total),
      billing_country: billingCountry,
      status: item.status,
      claim_status: item.claim_status,
    }));
  };

  // Not sure do we have addons with price more than 0
  // if no can remove this part
  getAddonsDetails = (billingCountry, isLEBT) => {
    const { order } = this.props;
    const nonCancelledAddonDetails = order.addon_items.filter(
      (item) => isLEBT || (item.status !== 'cancelled' && Number(item.total) > 0),
    );
    const addonDetailsList = [];
    (nonCancelledAddonDetails || []).forEach((item) =>
      addonDetailsList.push({
        description: [`Experiences: ${item.name}`],
        total_price: Number(item.total),
        billing_country: billingCountry,
        status: item.status,
      }),
    );

    return addonDetailsList;
  };

  getTourDetails = (billingCountry, isLEBT) => {
    const { order } = this.props;
    const nonCancelledTourDetails = order.tour_items.filter((item) => isLEBT || item.status !== 'cancelled');
    const nonCancelledServiceFeeDetails = order.service_fee_items.filter(
      (item) => isLEBT || item.status !== 'cancelled',
    );

    const tourDetails = [];
    (nonCancelledTourDetails || []).forEach((item) => {
      tourDetails.push({
        description: [`Tours: ${item.tour_name}`],
        total_price: Number(item.total),
        billing_country: billingCountry,
        status: item.status,
      });
    });
    (nonCancelledServiceFeeDetails || []).forEach((item) => {
      const description = item.name ? [`Tours Service Fee: ${item.name}`] : ['Tours Service Fee'];
      tourDetails.push({
        description,
        total_price: Number(item.total),
        billing_country: billingCountry,
        status: item.status,
      });
    });
    return tourDetails;
  };

  getExperienceDetails = (billingCountry, isLEBT) => {
    const { order } = this.props;
    const nonCancelledExperienceDetails = [];
    (order.experience_items || [])
      .filter((item) => isLEBT || item.status !== 'cancelled')
      .forEach((item) => {
        nonCancelledExperienceDetails.push({
          description: [
            `Experiences: ${item.title}`,
            `Fare Type: ${item.ticket.fareType}`,
            `Booking Number: ${item.booking_number}`,
          ],
          total_price: Number(item.total),
          billing_country: billingCountry,
          status: item.status,
        });
      });
    return nonCancelledExperienceDetails;
  };

  getPdfTotals = async (allItems, billingCountry, isLEBT) => {
    const totals = getPaymentTotalsByDate(
      this.props.order,
      this.props.refunds.result,
      this.state.reserveForZeroDetails,
      this.state.instalmentDetails,
    );

    // We can have some items in order to which gst is not applies, mainly flights.
    let totalNonGst = 0;
    allItems.flat().forEach((item) => {
      if (item.billing_country !== 'Australia') {
        totalNonGst += item.total_price;
      }
    });

    const grandTotal = isLEBT ? totals.subTotal : totals.subTotal - totals.refundedTotal;
    const totalPaid = isLEBT
      ? totals.subTotal + totals.totalBusinessTravellerCredits
      : totals.grandTotal - totals.refundedTotal;
    const gst = billingCountry === 'Australia' ? Number((grandTotal - totalNonGst) / 11) : 0;
    const subtotal = isLEBT ? Number(grandTotal - gst - totals.refundedTotal) : Number(grandTotal - gst);

    return {
      subtotal,
      GST: gst,
      total_amount: grandTotal,
      promo_used: totals.promoTotal,
      credit_used: totals.creditsTotal,
      total_paid: totalPaid,
      le_credit: totals.totalBusinessTravellerCredits,
      total_refunded: totals.refundedTotal,
    };
  };

  getBedbankDetails = (billingCountry, isLEBT) => {
    const { order } = this.props;
    const nonCancelledBedbankDetails = order.bedbank_items.filter((item) => isLEBT || item.status !== 'cancelled');
    const bedbankDetailsList = [];
    (nonCancelledBedbankDetails || []).forEach((item) => {
      bedbankDetailsList.push({
        description: [
          `Accommodation: ${item.offer.name}`,
          `Location: ${item.offer.address.countryName}, ${item.offer.address.stateProvinceName} ${item.offer.address.city}`,
          `Booking Number: ${item.booking_number}`,
        ],
        total_price: Number(item.total),
        billing_country: billingCountry,
        status: item.status,
      });
    });

    return bedbankDetailsList;
  };

  createTaxInvoiceDetails = async () => {
    const { order } = this.props;
    const result = getTaxInvoiceDetails(order.id_orders);
    return result;
  };

  callDepositTrigger = async (action) => {
    try {
      await callDepositTriggers(action, {
        orderId: this.props.order.id_orders,
      });
      alert('Done! Refresh the page to see updates.');
    } catch (err) {
      alert('Something went wrong!');
    }
  };

  callInstalmentTrigger = async (action) => {
    try {
      await callInstalmentTriggers(action, {
        orderId: this.props.order.id_orders,
      });
      alert('Done! Refresh the page to see updates.');
    } catch (err) {
      alert('Something went wrong!');
    }
  };

  callReserveForZeroTrigger = async (action) => {
    try {
      await callReserveForZeroTriggers(action, {
        orderId: this.props.order.id_orders,
      });
      this.setState({
        reserveForZeroTriggerSuccess: true,
      });
    } catch (err) {
      this.setState({
        reserveForZeroTriggerSuccess: false,
      });
    }
  };

  showForceBundleAlert = () => {
    this.setState({ isForceBundleAlertVisible: true });
  };

  render() {
    const {
      order,
      customer,
      offers,
      refreshData,
      isBookingDates,
      isChangingDates,
      emailToCustomerState,
      emailToVendorState,
      resendOrderGiftDeliveryEmail,
      customerEmailToAgentState,
      vendorEmailToAgentState,
      flightsCreditReservationId,
      subscriptions,
    } = this.props;
    const { showingRefundDetail, loyaltyStatus } = this.state;
    const earnOrderPartnerships = getEarnOrderPartnerships(this.state.partnerships);
    const {
      invoiceDownloading,
      downloadFailed,
      depositDetails,
      instalmentDetails,
      reserveForZeroDetails,
      reservationsFetched,
      reservations,
      reserveForZeroTriggerSuccess,
      isForceBundleAlertVisible,
    } = this.state;

    const showVirtualCreditCardsDetails =
      order.items.length > 0 || (order.bedbank_items && order.bedbank_items.length > 0);

    // In case of refund we need to pass an offer to refund modal
    const getAccommodationOfferFromItemId = (itemId) => {
      let offerSalesforceId = null;
      let item: any = order.items.find((i) => i.id === itemId);
      if (item) {
        offerSalesforceId = item.offer.id_salesforce_external;
      } else {
        item = order.addon_items.find((i) => i.id === itemId);
        if (item) {
          offerSalesforceId = item.offer_salesforce_id;
        }
      }

      // If not an accommodation or addon item, i.e. flights etc - this will just return the first offer
      const result = this.props.offers.find(
        ({ offer }) => !offerSalesforceId || offer.id_salesforce_external == offerSalesforceId,
      );
      return result?.offer;
    };

    const showDownloadInvoice =
      (!order.offline_flight_items || order.offline_flight_items.length === 0) &&
      (order.payments[0]?.instalmentDetails
        ? ['payment_due_balance_manual_debit_taken', 'payment_due_balance_auto_debit_taken'].includes(
            order.payments[0]?.instalmentDetails?.instalment_status,
          )
        : true) &&
      order.status !== 'cancelled' &&
      order.status !== 'abandoned';

    const hasRemindingExperiences = this.hasRemindingItem('addon_items') || this.hasRemindingItem('experience_items');
    const hasRemindingInsurances = this.hasRemindingItem('insurance_items');
    const hasRemindingFlights = this.hasRemindingItem('flight_items');
    const hasRemindingCruises = this.hasRemindingItem('cruise_items');
    const hasRemindingCarHire = this.hasRemindingItem('car_hire_items');

    const hasCruisesToShow = featureToggle.availableToShow('SHOW_CRUISES') && order.cruise_items?.length > 0;

    const hasCarhireToShow = featureToggle.availableToShow('SHOW_CAR_HIRE') && order.car_hire_items?.length > 0;

    const hasExperiencesToShow = window.configs.SHOW_EXPERIENCES === 'true' && order.experience_items?.length > 0;

    const flightItem = order?.flight_items?.[0];
    const showBookingDetails = flightItem?.provider === 'amadeus' && flightItem?.pnr_id !== null;

    // We need to get the last flight item for LEBT.
    // The credits and budget for a flight is stored on the last flight_item in svc-business
    const lastFlightItem = order?.flight_items?.[order?.flight_items?.length - 1];

    const showAtolDetails = order?.region_code === 'GB' && flightItem?.pnr_id !== null;

    const showCreditReservation = !!flightsCreditReservationId;

    const allAccommodationsCancelled =
      order.items.length > 0 || order.bedbank_items.length > 0
        ? isAllCancelled(order.items.concat(order.bedbank_items))
        : false;

    const hasServiceFee = Array.isArray(order?.service_fee_items) && order.service_fee_items.length > 0;
    const hasSubscriptions =
      featureToggle.availableToShow('SHOW_SUBSCRIPTIONS') &&
      Array.isArray(order?.subscription_items) &&
      order.subscription_items.length > 0;

    const isOpenJaw = flightItem?.journey?.fareType === 'OpenJaw';

    // For OpenJaw flights, we want to display a single flight item twice
    // with each element representing a leg of the journey
    const sortedFlightItems = isOpenJaw
      ? [flightItem, flightItem]
      : order.flight_items?.sort((a, b) => a.journey?.legNumber - b.journey?.legNumber);

    return (
      <Stack px={2} className="order-detail">
        <Helmet>
          <title>Orders |{customer.fullName ? ` ${customer.fullName} |` : ''} Order Summary</title>
        </Helmet>

        <PageHeader title="Order details">
          <Stack direction="row" spacing={2} alignItems="center">
            {showDownloadInvoice && (
              <Button
                variant="text"
                disabled={invoiceDownloading}
                onClick={this.downloadTax}
                startIcon={invoiceDownloading ? <CircularProgress size={16} color="inherit" /> : undefined}
              >
                {!invoiceDownloading && !downloadFailed && <span>Download Invoice</span>}
                {invoiceDownloading && <span>Downloading Invoice</span>}
                {!invoiceDownloading && downloadFailed && <span>Download Failed! Try Again</span>}
              </Button>
            )}

            <PermissionedComponent>
              <Button variant="text" component={RouterLink} to={`/edit-purchases/${order.id_orders}`}>
                Edit
              </Button>
            </PermissionedComponent>
          </Stack>
        </PageHeader>

        <Box>
          {emailToVendorState.successSending && <Alert severity="success">{emailToVendorState.successSending}</Alert>}
          {emailToVendorState.errorSending && <Alert severity="error">{emailToVendorState.errorSending}</Alert>}
          {emailToCustomerState.successSending && (
            <Alert severity="success">{emailToCustomerState.successSending}</Alert>
          )}
          {emailToCustomerState.errorSending && <Alert severity="error">{emailToCustomerState.errorSending}</Alert>}
          {customerEmailToAgentState.successSending && (
            <Alert severity="success">{customerEmailToAgentState.successSending}</Alert>
          )}
          {customerEmailToAgentState.errorSending && (
            <Alert severity="error">{customerEmailToAgentState.errorSending}</Alert>
          )}
          {vendorEmailToAgentState.successSending && (
            <Alert severity="success">{vendorEmailToAgentState.successSending}</Alert>
          )}
          {vendorEmailToAgentState.errorSending && (
            <Alert severity="error">{vendorEmailToAgentState.errorSending}</Alert>
          )}

          {allAccommodationsCancelled && hasRemindingExperiences && (
            <Alert severity="warning">
              This order has all accommodation items cancelled but some addons/experiences still active. Please check
              them below and cancel if needed.
            </Alert>
          )}

          {isForceBundleAlertVisible && (
            <Alert severity="warning">
              <p>This is a force bundle purchase</p>
            </Alert>
          )}

          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={reserveForZeroTriggerSuccess === true}
            onClose={() => this.setState({ reserveForZeroTriggerSuccess: null })}
            key="reserveForZeroTriggerSuccess"
            autoHideDuration={6000}
          >
            <Alert severity="success">Done! Refresh the page to see updates.</Alert>
          </Snackbar>

          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={reserveForZeroTriggerSuccess === false}
            onClose={() => this.setState({ reserveForZeroTriggerSuccess: null })}
            key="reserveForZeroTriggerFailure"
            autoHideDuration={6000}
          >
            <Alert severity="error">Something went wrong!</Alert>
          </Snackbar>

          <OrderMeta
            customer={customer}
            order={order}
            purchaser={this.state.purchaser}
            subscriptions={subscriptions}
            businessInfo={this.state.businessInfo}
          />

          {/* Order details section */}
          {offers && (
            <OrderDetailOffer
              addOfferToCart={this.props.addOfferToCart}
              allowToggleReservationType={this.props.allowToggleReservationType}
              customer={this.props.customer}
              flightsCreditReservationId={this.props.flightsCreditReservationId}
              history={this.props.history}
              isBookingDates={this.props.isBookingDates}
              isChangingDates={this.props.isChangingDates}
              offers={this.props.offers}
              onEmptyCart={this.props.onEmptyCart}
              order={this.props.order}
              refreshData={this.props.refreshData}
              refunds={this.props.refunds}
              customerEmailToAgentState={this.props.customerEmailToAgentState}
              emailToCustomerState={this.props.emailToCustomerState}
              resendCustomerEmail={this.props.resendCustomerEmail}
              resendCustomerEmailToAgent={this.props.resendCustomerEmailToAgent}
              vendorEmailToAgentState={this.props.vendorEmailToAgentState}
              emailToVendorState={this.props.emailToVendorState}
              resendVendorEmail={this.props.resendVendorEmail}
              resendVendorEmailToAgent={this.props.resendVendorEmailToAgent}
              selectOffer={this.props.selectOffer}
              setOrderId={this.props.setOrderId}
              setReservationType={this.props.setReservationType}
              tenant={this.props.tenant}
              travellers={this.props.travellers}
              showRefundModal={this.showRefundModal}
              hasAllowedRefund={this.state.hasAllowedRefund}
              showWarningModal={this.showWarningModal}
              getTravellers={this.props.getTravellers}
              isConvertingToBNBL={this.props.isConvertingToBNBL}
              setIsBookingDates={this.props.setIsBookingDates}
              setIsChangingDates={this.props.setIsChangingDates}
              setIsConvertingToBNBL={this.props.setIsConvertingToBNBL}
              setIsPuttingOnHold={this.props.setIsPuttingOnHold}
            />
          )}

          {order.custom_offer_items &&
            order.custom_offer_items.length > 0 &&
            order.custom_offer_items.map((customItem) => (
              <Accordion defaultExpanded key={customItem.id} variant="outlined">
                <AccordionSummary>Custom Offer item</AccordionSummary>
                <AccordionDetails>
                  <CustomOfferOrderList customOfferItem={customItem} showRefundModal={this.showRefundModal} />
                </AccordionDetails>
              </Accordion>
            ))}

          {order.has_booking_protection && (
            <BookingProtectionItem
              order={order}
              bookingProtectionItems={order.booking_protection_items}
              showRefundModal={this.showRefundModal}
              hasAllowedRefund={this.state.hasAllowedRefund}
            />
          )}

          {hasSubscriptions && <SubscriptionItem order={order} />}

          {order.bedbank_items &&
            order.bedbank_items.length > 0 &&
            order.bedbank_items.map((bedbankItem) => (
              <OrderDetailBedbank
                key={bedbankItem.id}
                item={bedbankItem}
                order={order}
                showRebookingModal={this.showRebookingModal}
                showReconfirmModal={this.showReconfirmModal}
                showRefundModal={this.showRefundModal}
                hasAllowedRefund={this.state.hasAllowedRefund}
                bedbankRoomsInfo={this.state.bedbankRoomsInfo}
                customerEmailToAgentState={this.props.customerEmailToAgentState}
                emailToCustomerState={this.props.emailToCustomerState}
                resendCustomerEmail={this.props.resendCustomerEmail}
                resendCustomerEmailToAgent={this.props.resendCustomerEmailToAgent}
              />
            ))}

          {order.tour_items?.map((tourItem) => (
            <OrderDetailTour
              key={tourItem.id}
              item={tourItem}
              order={order}
              tourRoomInfo={this.state.tourRoomsInfo[tourItem.reservation_id]}
              customerEmailToAgentState={this.props.customerEmailToAgentState}
              emailToCustomerState={this.props.emailToCustomerState}
              resendCustomerEmail={this.props.resendCustomerEmail}
              resendCustomerEmailToAgent={this.props.resendCustomerEmailToAgent}
              vendorEmailToAgentState={this.props.vendorEmailToAgentState}
              emailToVendorState={this.props.emailToVendorState}
              resendVendorEmail={this.props.resendVendorEmail}
              resendVendorEmailToAgent={this.props.resendVendorEmailToAgent}
              showRefundModal={this.showRefundModal}
            />
          ))}

          {order.has_flight && (
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  backgroundColor: 'grey.200',
                  height: '60px',
                }}
              >
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                  <Typography>Flights</Typography>
                  {showBookingDetails && (
                    <Link
                      href={`/purchases/${order.id_orders}/booking-details`}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      underline="hover"
                      target="_blank"
                      rel="noreferrer"
                    >
                      PNR
                    </Link>
                  )}
                  {showAtolDetails && (
                    <Link
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      underline="hover"
                      target="_blank"
                      rel="noreferrer"
                      href={`/purchases/${order.id_orders}/atol-details`}
                    >
                      ATOL
                    </Link>
                  )}
                  {showCreditReservation && (
                    <Link
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      underline="hover"
                      target="_blank"
                      rel="noreferrer"
                      href={`/flights/credit-reservation/${flightsCreditReservationId}/details`}
                    >
                      Credit Reservation
                    </Link>
                  )}
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                {sortedFlightItems.map((flightItem, index) => (
                  <>
                    <Divider>
                      <Typography fontWeight="bold">Flight # {index + 1}</Typography>
                    </Divider>
                    <OrderFlightItemContainer
                      key={flightItem.id}
                      item={flightItem}
                      refreshData={refreshData}
                      showForceBundleAlert={this.showForceBundleAlert}
                      segmentIndex={isOpenJaw ? index : undefined}
                    />
                  </>
                ))}

                <OrderFlightFees flightItems={order.flight_items} currencyCode={order.currency_code} />

                {this.props.tenant.brand === 'lebusinesstraveller' && order.status !== 'abandoned' && (
                  <>
                    <Divider>
                      <Typography fontWeight="bold">LEBT Credits</Typography>
                    </Divider>
                    <OrderItemCredit
                      orderItemId={lastFlightItem.id}
                      customerId={order.fk_customer_id}
                      location={
                        flightItem.journey?.departing?.flights[flightItem.journey?.departing?.flights?.length - 1]
                          .arrival_airport_name
                      }
                    />
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          )}

          {order.has_offline_flight && (
            <OrderOfflineFlightItemContainer item={order.offline_flight_items[0]} refreshData={refreshData} />
          )}

          {order.insurance_items && order.insurance_items.length > 0 && (
            <OrderInsuranceItemList
              order={order}
              items={order.insurance_items}
              showRefundModal={this.showRefundModal}
              hasAllowedRefund={this.state.hasAllowedRefund}
              purchaseDate={order.created_at}
              refreshData={refreshData}
              refunds={this.props.refunds}
            />
          )}

          {hasCruisesToShow && (
            <CruiseOrderListDetails
              order={order}
              cruiseItems={order.cruise_items}
              currencyCode={order.currency_code}
              showRefundModal={this.showRefundModal}
              hasAllowedRefund={this.state.hasAllowedRefund}
              depositDetails={
                depositDetails && {
                  depositAmount: depositDetails?.paid_amount,
                  balanceAmount: depositDetails?.balance_amount,
                  balanceDueDate: depositDetails?.balance_due_date,
                  depositCurrencyCode: depositDetails?.currency,
                }
              }
            />
          )}

          {hasCarhireToShow && (
            <CarHireOrderListDetails
              carHireItems={order.car_hire_items}
              currencyCode={order.currency_code}
              showRefundModal={this.showRefundModal}
              refreshData={refreshData}
              hasAllowedRefund={this.state.hasAllowedRefund}
            />
          )}

          {hasExperiencesToShow && (
            <ExperiencesList
              hasAllowedRefund={this.state.hasAllowedRefund}
              order={order}
              showRefundModal={this.showRefundModal}
              refreshData={this.props.refreshData}
            />
          )}

          {order.addon_items?.length > 0 && (
            <AddonsList
              payments={order.payments}
              addons={order.addon_items}
              refunds={this.props.refunds}
              isBookingDates={isBookingDates}
              isChangingDates={isChangingDates}
              showRefundModal={this.showRefundModal}
              checkInStatuses={offers.reduce(
                (acc, { items }) => acc.concat(items.map((i) => i?.checkInStatus?.addons)),
                [],
              )}
            />
          )}

          {hasServiceFee && (
            <OrderServiceFeeItem order={order} showRefundModal={this.showRefundModal} refunds={this.props.refunds} />
          )}

          {depositDetails?.id_deposit && <OrderDepositDetails depositDetails={depositDetails} />}

          {instalmentDetails?.id_instalments && (
            <Accordion defaultExpanded>
              <AccordionSummary
                sx={{
                  backgroundColor: 'grey.200',
                  height: '60px',
                }}
              >
                Instalment Details
              </AccordionSummary>
              <AccordionDetails>
                <OrderInstalmentDetails instalmentDetails={instalmentDetails} payments={order.payments} />
              </AccordionDetails>
            </Accordion>
          )}

          {reserveForZeroDetails?.id_deferred_payment && (
            <OrderReserveForZeroDetails reserveForZeroDetails={reserveForZeroDetails} />
          )}

          {order.merchant_fees?.length > 0 && <OrderMerchantFeeDetails order={order} />}

          <OrderDetailNotes orderId={order.id_orders} refreshData={this.props.refreshData} customer={customer} />

          {this.state.showingRebookDetail && (
            <RebookModal
              order={order}
              refreshData={this.props.refreshData}
              itemId={this.state.showingRebookDetail}
              bedbankRoomsInfo={this.state.bedbankRoomsInfo}
              onClose={() => this.showRebookingModal({ itemId: null })}
            />
          )}

          {this.state.showingReconfirmDetail && (
            <ReconfirmModal
              order={order}
              refreshData={this.props.refreshData}
              itemId={this.state.showingReconfirmDetail}
              onClose={() => this.showReconfirmModal({ itemId: null })}
            />
          )}

          {order.payments.length > 0 && (
            <Box mt={2}>
              <OrderDetailPayments payments={order.payments} />

              <NewRefundModal
                show={showingRefundDetail}
                onHide={this.hideRefundDetail}
                refreshData={this.onRefund}
                order={order}
                offer={getAccommodationOfferFromItemId(this.state.refundItemId)}
                itemId={this.state.refundItemId}
                roomId={this.state.refundRoomId}
                automatic={this.state.refundAutomatic}
                itemType={this.state.itemType}
                isAfterChangeDate={this.state.isAfterChangeDate}
                refundApprover={this.state.refundApprover}
                isRefundable={this.state.isAccommodationRefundable}
              />

              <RefundItemsReminderModal
                show={this.state.showingRefundReminderModal}
                hasCruises={hasRemindingCruises}
                hasExperiences={hasRemindingExperiences}
                hasInsurance={hasRemindingInsurances}
                hasFlights={hasRemindingFlights}
                hasCarHire={hasRemindingCarHire}
                onClose={this.props.refreshData}
              />
            </Box>
          )}

          {order.payments[0]?.depositDetails && window.configs.ENABLE_DEPOSIT_TRIGGERS === 'true' && (
            <Accordion defaultExpanded>
              <AccordionSummary
                sx={{
                  backgroundColor: 'grey.200',
                  height: '60px',
                }}
              >
                <Typography>Deposit Triggers (TEST ONLY)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid xs={4}>
                    <Button variant="contained" onClick={() => this.callDepositTrigger('deposit_reminder')}>
                      Send Reminder Email
                    </Button>
                    <Typography mt={2}>
                      5 days before the final payment is due, we send the customer an email. Click to send this reminder
                      email now.
                    </Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Button variant="contained" onClick={() => this.callDepositTrigger('auto_debit_success')}>
                      Simulate Auto-Debit Success
                    </Button>
                    <Typography mt={2}>
                      When the remaining balance is charged, Stripe will send us a message saying the payment succeeded
                      (or failed). This simulates that success message, though it doesn't actually take the payment in
                      stripe. On clicking this button, the order's Deposit Status should update from "deposit_taken" to
                      "due_balance_auto_debit_taken", and a new transaction should appear under Transaction Details to
                      reflect the payment of the remaining balance. You should also receive a confirmation email.
                    </Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Button variant="contained" onClick={() => this.callDepositTrigger('auto_debit_failure')}>
                      Simulate Auto-Debit Failure
                    </Button>
                    <Typography mt={2}>
                      If the charge for the remaining balance fails, Stripe will send us a message saying the payment
                      failed. This simulates a single payment failure, though it doesn't actually take a failed payment
                      in Stripe. We will only cancel the order after 3 failures, so on clicking this button 3 times, the
                      order's deposit status should change to "due_balance_auto_debit_failed" and the order should be
                      cancelled.
                    </Typography>
                    <Typography>
                      Current Auto-Debit Failure Count:{' '}
                      {order.payments[0]?.depositDetails.due_balance_auto_debit_failed_count}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}

          <OrderDetailBookingRequests orderId={order.id_orders} />

          <OrderTermsAndConditions order={order} />

          {this.props.refunds?.result && this.props.refunds.result.length > 0 && (
            <OrderDetailAccountingData
              order={order}
              offers={offers}
              refunds={this.props.refunds}
              refreshData={this.props.refreshData}
              reservationsFetched={reservationsFetched}
              reservations={reservations as unknown as App.OrderItemReservation[]}
            />
          )}

          {order.payments[0]?.instalmentDetails && window.configs.ENABLE_INSTALMENT_TRIGGERS === 'true' && (
            <Accordion defaultExpanded>
              <AccordionSummary
                sx={{
                  backgroundColor: 'grey.200',
                  height: '60px',
                }}
              >
                <Typography>Instalment Triggers (TEST ONLY)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid xs={4}>
                    <Button variant="contained" onClick={() => this.callInstalmentTrigger('instalment_reminder')}>
                      Send Monthly Round-up Email
                    </Button>
                    <Typography mt={2}>
                      Every month we send the customer an email to show all the instalments they have paid. Click to
                      send this reminder email now.
                    </Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Button variant="contained" onClick={() => this.callInstalmentTrigger('auto_debit_success')}>
                      Simulate Auto-Debit Success
                    </Button>
                    <Typography mt={2}>
                      When each instalment amount is charged, Stripe will send us a message saying the payment succeeded
                      (or failed). This simulates that success message, though it doesn't actually take the payment in
                      stripe. On clicking this button, the Instalment Status of the current instalment should update to
                      "payment_auto_debit_taken", and a new transaction should appear under Transaction Details to
                      reflect the payment of the instalment amount.
                    </Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Button variant="contained" onClick={() => this.callInstalmentTrigger('auto_debit_failure')}>
                      Simulate Auto-Debit Failure
                    </Button>
                    <Typography mt={2}>
                      If the charge for the instalment amount fails, Stripe will send us a message saying the payment
                      failed. This simulates a single payment failure, though it doesn't actually take a failed payment
                      in Stripe. We will only cancel the order after 3 failures for an individual instalment, so on
                      clicking this button 3 times, the order's instalment status should change to
                      "payment_auto_debit_failed" and the order should be cancelled.
                    </Typography>
                    <Typography>
                      Current Auto-Debit Failure Count:{' '}
                      {this.state.instalmentDetails.instalmentPaymentDetails?.length > 0
                        ? this.state.instalmentDetails.instalmentPaymentDetails[
                            this.state.instalmentDetails.instalmentPaymentDetails.length - 1
                          ].due_balance_auto_debit_failed_count
                        : 0}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}

          {window.configs.ENABLE_RESERVE_FOR_ZERO_TRIGGERS === 'true' && order.payments[0]?.deferredPaymentDetails && (
            <Accordion defaultExpanded>
              <AccordionSummary
                sx={{
                  backgroundColor: 'grey.200',
                  height: '60px',
                }}
              >
                <Typography>Reserve for $0 Triggers (TEST ONLY)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid xs={6}>
                    <Button variant="contained" onClick={() => this.callReserveForZeroTrigger('auto_debit_success')}>
                      Simulate Auto-Debit Success
                    </Button>
                    <Typography mt={2}>
                      When Reserve for $0 amount is charged, Stripe will send us a message saying the payment succeeded.
                      On clicking this button, the Booking Status in the Reserve for $0 Details section should update to
                      "payment_auto_debit_taken", and a new transaction should appear under Transaction Details to
                      reflect the payment of the payable amount.
                    </Typography>
                  </Grid>
                  <Grid xs={6}>
                    <Button variant="contained" onClick={() => this.callReserveForZeroTrigger('auto_debit_failure')}>
                      Simulate Auto-Debit Failure
                    </Button>
                    <Typography mt={2}>
                      If the charge for the Reserve for $0 amount fails, Stripe will send us a message saying the
                      payment failed. This simulates a single payment failure. We cancel the order after a failed
                      payment, so clicking this button once will result in the order being cancelled and the Booking
                      Status in the Reserve for $0 Details section to be 'payment_auto_debit_failed'.
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}

          {this.state.partnerships && (
            <OrderDetailPartnerships customer={customer} partnerships={this.state.partnerships} order={order} />
          )}

          {earnOrderPartnerships && earnOrderPartnerships.length > 0 && this.state.earnOrder.length > 0 && (
            <Accordion defaultExpanded>
              <AccordionSummary
                sx={{
                  backgroundColor: 'grey.200',
                  height: '60px',
                }}
              >
                <Typography> Rewards History</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {this.state.earnOrder?.map((reward) => (
                  <OrderDetailRewards
                    key={reward.prefix}
                    newPointsRequest={this.newPointsRequest}
                    customer={customer}
                    partnership={this.state.partnerships.find((partnership) => partnership.prefix === reward.prefix)}
                    order={order}
                    data={reward}
                    status={loyaltyStatus[reward.prefix]}
                    showForm={
                      order.items.length &&
                      loyaltyStatus[reward.prefix].isPostThirtyDays &&
                      loyaltyStatus[reward.prefix].isEligible
                    }
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          )}

          {showVirtualCreditCardsDetails && (
            <OrderDetailVirtualCreditCards
              items={order.items}
              reservationsFetched={reservationsFetched}
              reservations={reservations}
            />
          )}
        </Box>

        {this.state.paymentPlanLoaded && this.state.paymentPlanData && (
          <>
            <OrderDetailPaymentPlanLogs data={this.state.paymentPlanData.plans} />

            {this.state.paymentPlanData.error_logs?.length > 0 && (
              <OrderDetailPaymentErrorLogs data={this.state.paymentPlanData.error_logs} />
            )}
          </>
        )}

        {order.order_gift && <OrderGift order={order} resendRedemptionEmail={resendOrderGiftDeliveryEmail} />}

        {this.props.order.business_id !== null && (
          <OrderDetailBusinessCredits businessCreditItems={order.business_credit_items} />
        )}
        <CancellationPolicyWarningModal
          showModal={this.state.shouldShowRefundWarningModal}
          closeModal={this.closeRefundWarningModal}
          showRefundModal={this.triggerRefund}
          setRefundComment={this.setRefundComment}
        />
      </Stack>
    );
  }
}

// Exported without router & redux HOC for testing
export const OrderDetailPageComponent = OrderDetailPage;

export default withRouter(withTenant(OrderDetailPage));
