/* eslint-disable prettier/prettier */
import React from 'react';

import { useHistory } from 'react-router';

import { Alert, Box, Grid, Stack, Typography } from '@mui/material';

import { definitions } from '@luxuryescapes/contract-svc-promo';

import { DiscountItemInfo, getPromoProductDisplayName } from '~/services/PromoService';

import currencyFormatter from '~/utils/currencyFormatter';

import BorderedGridItem from '../Common/BorderedGridItem';

type PromoItemResultProps = {
  discountItem: DiscountItemInfo;
  isDevMode: boolean;
  currency: string;
  meta: definitions['PromoMeta'];
};

const PromoItemResult = ({ discountItem, isDevMode, currency, meta }: PromoItemResultProps) => {
  const {
    item,
    discountType,
    warning,
    discountAmount,
    matchingDiscount,
    luxPlusDiscountHigher,
    doesNotQualifyWithMemberPricing,
    discountHasCombinedWithLuxPlusPricing,
  } = discountItem;
  const { poffer, reqItem } = item;
  const { discountableTotal, luxPlusPrice, offerId, itemId } = reqItem;
  const hasLuxPlusPrice = luxPlusPrice && luxPlusPrice > 0;
  const originalPrice = hasLuxPlusPrice ? luxPlusPrice : discountableTotal;

  const history = useHistory();

  const handleGridClick = (item: DiscountItemInfo) => {
    if (item.item.reqItem.categoryBK === 'subscription') return;
    history.push(`/offers/${item.item.reqItem.offerId}`);
  };

  return (
    <Box>
      <Grid
        container
        key={`${offerId}_${itemId}`}
        spacing={2}
        sx={{
          padding: '1px',
          margin: '1px',
        }}
        title={`${JSON.stringify(discountItem, null, 4)}`}
      >
        <BorderedGridItem item>
          <Stack direction="row" gap={1}>
            {discountAmount > 0 ? (
              <>
                <Typography sx={{ color: hasLuxPlusPrice ? 'blue' : '' }}>
                  {currencyFormatter(currency, originalPrice - discountAmount)}
                </Typography>

                <Typography sx={{ textDecoration: 'line-through', color: hasLuxPlusPrice ? 'blue' : '' }}>
                  {' '}
                  {currencyFormatter(currency, originalPrice)}
                </Typography>
                <Typography sx={{ color: 'green', marginLeft: 1 }}>
                  ({currencyFormatter(currency ?? 'AUD', discountAmount)})
                </Typography>
              </>
            ) : (
              <Typography>{currencyFormatter(currency, originalPrice)}</Typography>
            )}
            {isDevMode && warning && (
              <Typography variant="body2" component="div">
                {warning}
              </Typography>
            )}
          </Stack>
        </BorderedGridItem>
        {matchingDiscount && matchingDiscount.discount_value > 0 && (
          <BorderedGridItem>
            <Stack direction="column">
              <Typography variant="body2" component="div">
                {discountType == 'percentage' && `${matchingDiscount.discount_value}%`}
                {discountType == 'fixed_amount' && `${currencyFormatter(currency, matchingDiscount.discount_value)}`}
              </Typography>
              {luxPlusDiscountHigher && (
                <Typography variant="body2" component="div">
                  <strong>luxPlusDiscountHigher:</strong>
                </Typography>
              )}
              {discountHasCombinedWithLuxPlusPricing && (
                <Typography variant="body2" component="div">
                  <strong>(discountHasCombinedWithLuxPlusPricing)</strong>
                </Typography>
              )}
              {doesNotQualifyWithMemberPricing && (
                <Typography variant="body2" component="div">
                  <strong>(doesNotQualifyWithMemberPricing)</strong>
                </Typography>
              )}
            </Stack>
            {isDevMode && (
              <Stack direction="column">
                <Typography variant="body2" component="div">
                  <strong>Region:</strong> {matchingDiscount.region}
                </Typography>
                <Typography variant="body2" component="div">
                  <strong>Products:</strong>{' '}
                  {matchingDiscount?.products && matchingDiscount?.products.length > 0 ? (
                    matchingDiscount.products.join(', ')
                  ) : (
                    <Alert severity="warning">a matching discount? This is unexpected</Alert>
                  )}
                </Typography>
                {matchingDiscount.min_spend && (
                  <Typography variant="body2" component="div">
                    <strong>Minimum Spend:</strong> {currencyFormatter(currency, matchingDiscount.min_spend)}
                  </Typography>
                )}
                {matchingDiscount.max_discount && (
                  <Typography variant="body2" component="div">
                    <strong>Maximum Discount:</strong> {currencyFormatter(currency, matchingDiscount.max_discount)}
                  </Typography>
                )}
                {reqItem.travellers && (
                  <Typography variant="body2" component="div" title="travellers">
                    <strong>Travellers:</strong> {reqItem.travellers.firstName} {reqItem.travellers.lastName}
                  </Typography>
                )}
              </Stack>
            )}
          </BorderedGridItem>
        )}
        <BorderedGridItem item>
          <Stack onClick={() => handleGridClick(discountItem)} title={JSON.stringify(reqItem, null, 4)}>
            <Typography>
              {meta && getPromoProductDisplayName(meta.promo_product_types, reqItem.categoryBK, reqItem.subCategoryBK)}
            </Typography>
          </Stack>
        </BorderedGridItem>
        {matchingDiscount?.subscription_item_discount_type !== 'none' &&
          matchingDiscount?.subscription_item_discount_value > 0 && (
            <BorderedGridItem border="thin">
              <Typography variant="body2" component="div">
                <strong>Sub Discount:</strong>
                {matchingDiscount.subscription_item_discount_type == 'fixed_amount'
                  ? currencyFormatter(currency, matchingDiscount.subscription_item_discount_value)
                  : `${matchingDiscount.subscription_item_discount_value}%`}
              </Typography>
            </BorderedGridItem>
          )}
        {reqItem.itemCountryCode && (
          <BorderedGridItem title="Item Country Code">
            <Typography>{reqItem.itemCountryCode}</Typography>
          </BorderedGridItem>
        )}
        {reqItem.numberOfNights && (
          <BorderedGridItem title="Number of Nights">
            <Typography>N:{reqItem.numberOfNights}</Typography>
          </BorderedGridItem>
        )}
        {reqItem.numberOfAdults + reqItem.numberOfChildren > 0 && (
          <BorderedGridItem title="Occupancy">
            <Typography>
              {reqItem.numberOfAdults ? `A:${reqItem.numberOfAdults}` : ''}
              {reqItem.numberOfChildren ? ` C:${reqItem.numberOfChildren}` : ''}
            </Typography>
          </BorderedGridItem>
        )}
      </Grid>
    </Box>
  );
};

export default PromoItemResult;
