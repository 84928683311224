import qs from 'qs';
import fileDownload from 'react-file-download';

import { Order } from '@luxuryescapes/contract-svc-order';

import { MultiFilterState } from '~/components/Common/Forms/OrderSearchForm';

import { OPERATION_SOFT_DELETE } from '~/consts/notes';
import { OPERATION_CANCEL_CUSTOM_ORDER_ADMIN } from '~/consts/order';

import type { OrderItemLogsResponse } from '~/types/responses';

import isUUID from '~/utils/isUUID';

import ReactStormpath from '../stormpath';

import { fetchInsuranceClaim } from './InsuranceService';
import offersService from './OffersService';
import { getMerchantFeeDetails, getPayments } from './PaymentsService';
import { request } from './common';

function getBaseURL() {
  return window.configs.API_HOST + '/api';
}

function dataRequest(path, data, method) {
  return request(path, {
    method,
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  });
}

function postRequest(path, data) {
  return dataRequest(path, data, 'POST');
}

function patchRequest(path, data) {
  return dataRequest(path, data, 'PATCH');
}

function putRequest(path, data) {
  return dataRequest(path, data, 'PUT');
}

export async function createOrder(data) {
  const baseURL = getBaseURL();

  return postRequest(`${baseURL}/orders`, data);
}

export function createItem(orderId, data) {
  const baseURL = getBaseURL();

  return postRequest(`${baseURL}/orders/${orderId}/items`, data);
}

export function finalizeOrder(orderId) {
  const baseURL = getBaseURL();
  const data = { op: 'finalize' };
  return patchRequest(`${baseURL}/orders/${orderId}`, data);
}

export function updateOrder(orderId, data) {
  if (orderId && data) {
    const baseURL = getBaseURL();
    return patchRequest(`${baseURL}/orders/${orderId}`, data);
  }
}

function getBookingNumbers(purchases) {
  for (let i = 0; i < purchases.length; i++) {
    purchases[i]['booking_numbers'] = purchases[i].items.map((item) => item.booking_number).join(', ');
  }
}

function isOrderId(input) {
  return /^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/.test(input);
}

function isOfferId(input) {
  return /^[0-9a-zA-Z]{18}$/.test(input);
}

interface GetPurchasesQueryParams {
  filterBy?: string;
  page: number;
  per_page: number;
  brand: string;
  customer_id: string;
  start_date?: string;
  end_date?: string;
  q?: string;
  item_type?: string;
  item_types?: [string];
  type?: string;
  departure_id?: string;
  sortBy?: string;
  business_id?: string;
}

interface IOptions {
  page: number;
  per_page: number;
  brand: string;
  customer_id?: string;
  filterBy?: string;
  filterValue?: string;
  additionalFilter?: MultiFilterState;
  filter?: { [key: string]: string | string[] };
  sort?: { [key: string]: 'asc' | 'desc' };
  startDate?: string;
  endDate?: string;
  item_type?: string | string[];
  offer_type?: string;
  business_id?: string;
}

type SearchOptions = {
  page?: number;
  perPage?: number;
  orderBy?: string;
  orderDirection?: string;
  searchId: string;
};

type MultiSearchOptions = {
  page: number;
  perPage: number;
  orderBy: string;
  orderDirection: string;
  additionalFilter?: MultiFilterState;
};

export async function multiSearch({ page, perPage, orderBy, orderDirection, additionalFilter }: MultiSearchOptions) {
  const baseURL = getBaseURL();
  const url = `${baseURL}/orders/multi-search?`;

  const queryParams = {
    page,
    perPage,
    itemType: additionalFilter.itemType,
    offerId: additionalFilter.offerId,
    checkInDate: additionalFilter.checkInDate,
    checkOutDate: additionalFilter.checkOutDate,
    orderDirection,
    orderBy,
  };

  const purchases = await request(`${url}${qs.stringify(queryParams)}`);

  getBookingNumbers(purchases.result);

  return purchases;
}

export async function searchPurchases({ page, perPage, orderBy, orderDirection, searchId }: SearchOptions) {
  const baseURL = getBaseURL();
  const url = `${baseURL}/orders/search?`;

  const queryParams = {
    page,
    perPage,
    searchId,
    orderDirection,
    orderBy,
  };

  const purchases = await request(`${url}${qs.stringify(queryParams)}`);

  // collect all booking numbers per order
  getBookingNumbers(purchases.result);

  return purchases;
}

export async function getPurchases(options: IOptions) {
  const { page, per_page, brand, customer_id, filterBy, filterValue, additionalFilter, business_id } = options;

  const baseURL = getBaseURL();
  const url = `${baseURL}/orders?`;
  const sortBy = additionalFilter?.sortField;
  const sortType = additionalFilter?.sortType;

  // if customer_id is null convert it to undefined
  // because qs only ignores undefined
  const queryParams: GetPurchasesQueryParams = {
    page,
    per_page,
    brand,
    customer_id: customer_id || undefined,
    business_id: business_id || undefined,
  };

  switch (sortBy) {
    case 'null':
    case null:
    case '':
      break;
    default:
      {
        queryParams['order_by'] = sortBy;
        queryParams['order_direction'] = sortType;
      }
      break;
  }

  switch (filterBy) {
    case 'null':
    case null:
      break;
    case 'booking_numbers':
      {
        const allValues = filterValue
          .toString()
          .split(',')
          .map((i) => i.trim());
        const id = allValues.toString();
        if (isOrderId(id)) {
          queryParams['orders_id'] = allValues.filter((b) => isOrderId(b)).toString();
        } else {
          queryParams['booking_numbers'] = filterValue.toString();
        }
      }
      break;
    case 'order_number':
      {
        const allValues = filterValue
          .toString()
          .split(',')
          .map((i) => i.trim());
        const id = allValues.toString();
        if (isOrderId(id)) {
          queryParams['orders_id'] = allValues.filter((b) => isOrderId(b)).toString();
        } else {
          queryParams['order_number'] = filterValue.toString();
        }
      }
      break;
    case 'offer_id':
      {
        const allValues = filterValue
          .toString()
          .split(',')
          .map((i) => i.trim());
        queryParams[filterBy] = allValues.filter((b) => isOfferId(b) || isUUID(b)).toString();
      }
      break;
    case 'sold_by':
      {
        const allValues = filterValue
          .toString()
          .split(',')
          .map((i) => i.trim());
        queryParams[filterBy] = allValues.filter((b) => isUUID(b)).toString();
      }
      break;
    case 'created_at':
      {
        queryParams.start_date = additionalFilter.startDate;
        queryParams.end_date = additionalFilter.endDate;
      }
      break;
    case 'customer_details':
      {
        queryParams.filterBy = filterBy.toString();
        queryParams.q = filterValue.toString();
      }
      break;
    default:
      {
        queryParams[filterBy] = filterValue.toString();
      }
      break;
  }

  if (options?.offer_type) {
    queryParams.type = options?.offer_type;
  }

  if (options?.item_type) {
    if (Array.isArray(options.item_type)) {
      queryParams.item_types = options.item_type as [string];
    } else {
      queryParams.item_type = options.item_type;
    }
  }

  const purchases = await request(`${url}${qs.stringify(queryParams)}`);

  // collect all booking numbers per order
  getBookingNumbers(purchases.result);

  return purchases;
}

export function getOrder(id_orders) {
  const baseURL = getBaseURL();
  const url = `${baseURL}/orders/${id_orders}`;
  return request(url);
}

export function getOrderCombinedItemIds({ result }) {
  return [].concat(
    result.accommodation_items.map((i) => i.id),
    result.addon_items.map((i) => i.id),
    result.flight_items.map((i) => i.id),
    result.offline_flight_items.map((i) => i.id),
    result.gift_card_items.map((i) => i.id),
    result.bedbank_items.map((i) => i.id),
    result.insurance_items.map((i) => i.id),
    result.tour_items.map((i) => i.id),
  );
}

async function getInsuranceClaimStatus(order: App.Order) {
  if (order.insurance_items.length === 0) return;

  // An order contains a unique insurance item
  const insuranceItem = order.insurance_items[0];
  const contractId = insuranceItem.id_contract_number;

  const claim = await fetchInsuranceClaim(contractId);
  return claim.claim_status;
}

function includeInsuranceClaimStatus(order: App.Order, claimStatus: string) {
  if (order.insurance_items.length === 0) return;

  // An order contains a unique insurance item
  order.insurance_items[0] = {
    ...order.insurance_items[0],
    claim_status: claimStatus,
  };
}

export async function getOrderDetail(id_orders) {
  const orderResponse = await getOrder(id_orders);

  const order = orderResponse.result;
  const reservations = await getReservationByOrder(order);

  order.items.forEach((item) => {
    const reservation = reservations[item.id_items];
    item.fullReservation = reservation || {};
  });

  const payments = await getPayments(id_orders);
  order.payments = payments.result;

  const merchantFees = await getMerchantFeeDetails(id_orders);
  order.merchant_fees = merchantFees.result?.merchantFeeDetails;

  const claimStatus = await getInsuranceClaimStatus(order);
  includeInsuranceClaimStatus(order, claimStatus);

  try {
    const createOrderTransaction = await request(`${window.configs.API_HOST}${order._links.status.href}`);

    order.create_order_transaction = createOrderTransaction.result;
    return order;
  } catch (error) {
    return order;
  }
}

export async function getReservationByOrderAndItem(id_orders, id_items) {
  const baseURL = getBaseURL();
  let data;
  try {
    data = await request(`${baseURL}/orders/${id_orders}/items/${id_items}/reservation`);
  } catch (e) {
    // some orders return 404 for reservation endpoint but have reservation data in the order item
    try {
      data = await request(`${baseURL}/orders/${id_orders}/items/${id_items}`);
      data = { result: data.result.reservation };
    } catch (e) {
      data = { result: [] };
    }
  }

  // This is horrible. It's also the lesser evil.
  data.id_item = id_items;
  data.id_order = id_orders;
  return data;
}

export async function getFlightDetailsByOrderAndItem(id_orders, id_items) {
  const baseURL = getBaseURL();

  const data = await request(`${baseURL}/orders/${id_orders}/items/${id_items}/flight-details`);

  return data.result;
}

export async function getRebookStatus(id_orders, id_items) {
  const baseURL = getBaseURL();

  const data = await request(`${baseURL}/orders/${id_orders}/items/${id_items}/rebook/status`);

  return data.result;
}

// N+1 queries; no way will this be a perf problem
export async function getReservationByOrder(order) {
  const promises = [];
  for (const item of order.items) {
    promises.push(getReservationByOrderAndItem(order.id_orders, item.id));
  }
  const out = {};
  const data = await Promise.all(promises);
  for (const datum of data) {
    out[datum.id_item] = datum.result;
  }
  return out;
}

export async function getAddonsByOrder(order) {
  const promises = [];
  const out = {};
  for (const item of order.addon_items) {
    if (typeof out[item.salesforce_id] === 'undefined') {
      out[item.salesforce_id] = null;
      promises.push(
        offersService.getAddon(item.offer_salesforce_id, item.offer_package_salesforce_id, item.salesforce_id),
      );
    }
  }
  const data = await Promise.all(promises);
  for (const datum of data) {
    out[datum.result.id_salesforce_external] = datum.result;
  }
  return out;
}

export async function cancelCustomOfferOrder(orderId: string, itemId: string) {
  const baseURL = getBaseURL();
  const payload = {
    op: OPERATION_CANCEL_CUSTOM_ORDER_ADMIN,
  };
  return postRequest(`${baseURL}/orders/${orderId}/items/${itemId}/cancel`, payload);
}

export async function cancelOrder(orderId, itemId, data) {
  const baseURL = getBaseURL();
  const payload = {
    ...data,
    amount: parseFloat(data.amount),
    accounting_metadata: data.accounting_metadata.map((x) => ({
      ...x,
      accounting_amount: parseFloat(x.accounting_amount),
      cash_amount: parseFloat(x.cash_amount),
      refund_fee: parseFloat(x.refund_fee),
    })),
  };

  return patchRequest(`${baseURL}/orders/${orderId}/items/${itemId}/refund`, payload);
}

export async function refundStatus(orderId, itemId) {
  const baseURL = getBaseURL();

  const data = await request(`${baseURL}/orders/${orderId}/items/${itemId}/refund/status`);

  return data.result;
}

export async function refundFlight(orderId, itemId, data) {
  const baseURL = getBaseURL();
  const payload = {
    ...data,
    amount: parseFloat(data.amount),
    accounting_metadata: data.accounting_metadata.map((x) => ({
      ...x,
      accounting_amount: parseFloat(x.accounting_amount),
      cash_amount: parseFloat(x.cash_amount),
      refund_fee: parseFloat(x.refund_fee),
    })),
  };

  return patchRequest(`${baseURL}/orders/${orderId}/flights/${itemId}/refund`, payload);
}

interface InsuranceUpdate {
  subscriptionId: string;
  startDate: string;
  endDate: string;
  createdDate: string;
  travellerDetails: App.InsuranceTraveller[];
  coveredAmount?: number;
}

export async function getQuoteUpdate(order: App.Order, updateData: InsuranceUpdate) {
  const baseURL = getBaseURL();
  const url = `${baseURL}/orders/${order.id}/insurance/quote-upgrade`;
  const data = {
    brand: 'luxuryescapes',
    subscription_id: updateData.subscriptionId,
    trip_start_date: updateData.startDate,
    trip_end_date: updateData.endDate,
    sales_channel: 'Sales Agent',
    travellers_details: updateData.travellerDetails,
    ...(updateData.coveredAmount && {
      cover_amount: updateData.coveredAmount,
    }),
  };

  return postRequest(url, data);
}

interface InsuranceUpgradeRequest {
  updateId: string;
  coverAmount: number;
  transactionKey: string;
  total: number;
}

export async function confirmInsuranceUpdate(order: App.Order, updates: InsuranceUpgradeRequest) {
  const baseURL = getBaseURL();
  return postRequest(`${baseURL}/orders/${order.id}/insurance/upgrade`, {
    brand: 'luxuryescapes',
    ...updates,
  });
}

export async function updateOrderItem(orderId, itemId, data) {
  const baseURL = getBaseURL();

  return putRequest(`${baseURL}/orders/${orderId}/items/${itemId}`, data);
}

export async function getItemCheckInStatus(orderId, itemId) {
  const baseURL = getBaseURL();

  return request(`${baseURL}/orders/${orderId}/items/${itemId}/check-in-details`);
}

export async function getOrderNotes(orderId) {
  const baseURL = getBaseURL();
  const response = await request(`${baseURL}/orders/${orderId}/notes`);
  return response.result;
}

export async function getOrderDatesRequest(orderId: string) {
  const baseURL = getBaseURL();
  const response = await request(`
    ${baseURL}/orders/dates-request?orderId=${orderId}&limit=50`);
  return response.result;
}

export async function getOrderRefundInfo(orderId, itemId, roomId = null, refundType = null) {
  const baseURL = getBaseURL();
  const response = await request(
    `${baseURL}/orders/${orderId}/items/${itemId}/refund?room_id=${roomId}&refund_type=${refundType}`,
  );
  return response.result;
}

export async function addOrderNote(orderId, data) {
  const baseURL = getBaseURL();

  if (data instanceof FormData) {
    return request(`${baseURL}/orders/${orderId}/notes`, {
      method: 'POST',
      body: data,
    });
  }

  return postRequest(`${baseURL}/orders/${orderId}/notes`, data);
}

export async function deleteOrderNote(orderId, noteId) {
  const baseURL = getBaseURL();
  return patchRequest(`${baseURL}/orders/${orderId}/notes/${noteId}`, {
    op: OPERATION_SOFT_DELETE,
  });
}

export async function gdprRemoval(accountId, data) {
  const baseURL = getBaseURL();
  return postRequest(`${baseURL}/orders/gdpr-removal/${accountId}`, data);
}

export async function gdprBulkRemoval(data) {
  const baseURL = getBaseURL();
  return postRequest(`${baseURL}/orders/gdpr-removal`, data);
}

export async function getGdprBulkRemovalJobStatus(jobId) {
  const baseURL = getBaseURL();
  return request(`${baseURL}/orders/queues/gdpr-bulk-removal/${jobId}`);
}

export async function updateFlightDetails(orderId, itemId, data) {
  const baseURL = getBaseURL();
  return patchRequest(`${baseURL}/orders/${orderId}/items/${itemId}/flight-details`, data);
}

type ExperienceRefundResponse = {
  [experienceId: string]: {
    refundFee?: number;
    refundAmount?: number;
  };
};

export const getExperienceRefund = async (experienceId: string): Promise<ExperienceRefundResponse> => {
  const baseURL = getBaseURL();

  try {
    return await request(`${baseURL}/orders/items/${experienceId}/refund-policies`);
  } catch (error) {
    console.error(`EXPERIENCE REFUND: ${error.message}`);
  }
};

export function getOrdersByFlightItems(property, keys) {
  const baseURL = getBaseURL();

  return postRequest(`${baseURL}/orders/flight-items-by-${property}`, { keys });
}

export async function getItemCountForOffer(offerId) {
  const url = getBaseURL() + `/orders/offer/${offerId}/items-counter/`;

  const orders = await request(`${url}`);
  return orders;
}

export function resendConfirmationEmail(orderId, offerId = null) {
  const url = `${getBaseURL()}/orders/${orderId}/resend`;
  return postRequest(url, { id_offer: offerId });
}

export async function getInvoiceUrl(orderId, noteId, comment) {
  const baseURL = getBaseURL();
  const url = baseURL + `/orders/${orderId}/notes/${noteId}/attachment`;
  const token = await ReactStormpath.getOrRefreshAccessToken();

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf',
      Authorization: `Bearer ${token}`,
    },
  });
  const body = await response.blob();
  const fileNameHeader = `${comment}-${noteId}.pdf`;
  await fileDownload(body, fileNameHeader);
}

export async function getOrderItemBySubscriptionPeriodId(
  subscriptionPeriodId: string,
): Promise<Order.SubscriptionItem> {
  const baseURL = getBaseURL();
  const response = await request(`${baseURL}/orders/subscription-items?subscription_period_id=${subscriptionPeriodId}`);
  return response;
}

export async function bulkRefundExpiredBnblOrders(data) {
  const baseURL = getBaseURL();
  return postRequest(`${baseURL}/orders/bulk-refund-expired-bnbl`, data);
}

export async function getOrderItemLogs(orderId: string, itemId: string): Promise<OrderItemLogsResponse> {
  const baseURL = getBaseURL();
  return request(`${baseURL}/orders/${orderId}/items/${itemId}/logs`);
}

export async function getCustomOrderByCustomOffer(customOfferId: string): Promise<Order.Order> {
  const customOrderResponse = await getPurchases({
    item_type: 'custom_offer',
    filter: { offer_id: customOfferId },
    page: 1,
    per_page: 1,
    brand: 'luxuryescapes',
  });

  if (customOrderResponse && customOrderResponse.result.length === 1) {
    return customOrderResponse.result[0];
  }
  return null;
}

export async function getTaxInvoiceDetails(orderId: string) {
  const baseURL = getBaseURL();
  return request(`${baseURL}/orders/${orderId}/tax-invoice-details`);
}

export async function getRefundRequests(page = 1, limit = 10) {
  const baseURL = getBaseURL();

  const queryParams = {
    page,
    limit,
  };

  const response = await request(`${baseURL}/orders/refund-request?${qs.stringify(queryParams)}`);

  return response;
}

export async function getRefundRequestsByOrderId(orderId: string): Promise<Order.RefundRequest[]> {
  const baseURL = getBaseURL();
  const response = await request(`${baseURL}/orders/refund-request/order/${orderId}`);

  return response.result;
}

export async function getRefundRequestsByOrderItemId(orderId: string, itemId: string): Promise<Order.RefundRequest> {
  const baseURL = getBaseURL();
  const response = await request(`${baseURL}/orders/refund-request/order/${orderId}/item/${itemId}`);

  if (response.result && response.result.length > 0) {
    // Can only have one refund request per item
    return response.result[0];
  }
  return null;
}

export async function updateRefundRequest(refundRequestId, data) {
  const baseURL = getBaseURL();
  const url = `${baseURL}/orders/refund-request/${refundRequestId}`;

  return patchRequest(url, data);
}

export async function getOrdersByIds(id_orders: string[], brand: string) {
  const baseURL = getBaseURL();

  const queryParams = {
    orders_id: id_orders.join(','),
    brand: brand,
  };

  const url = `${baseURL}/orders?${qs.stringify(queryParams)}`;

  const orders = await request(url);
  return orders.result;
}
