import React from 'react';

import { useHistory } from 'react-router';

import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { Box, Chip, Grid, Stack, Typography } from '@mui/material';

import { definitions } from '@luxuryescapes/contract-svc-promo';

import BorderedGridItem from '~/components/Common/BorderedGridItem';

import useQuery from '~/hooks/useQuery';

import { getPromoProductDisplayName, sortPromoItemByCategoryAndId } from '~/services/PromoService';

import currencyFormatter from '~/utils/currencyFormatter';
import { getCurrencyCodeByRegionCode } from '~/utils/getCurrencyCodeByRegionCode';

type PromoOrderTableCellProps = {
  order: definitions['Discount Request']['order'] | definitions['Discount Request']['preCheckoutOrder'] | null;
  meta: definitions['PromoMeta'];
};

function PromoOrderTableCell({ order, meta }: PromoOrderTableCellProps) {
  const history = useHistory();
  const query = useQuery();

  const isDevMode = query.get('dev');

  if (!order?.region) {
    return null;
  }

  const currencyCode = getCurrencyCodeByRegionCode(order.region);

  const handleGridClick = (item: PromoOrderTableCellProps['order']['items']['0']) => {
    if (item.categoryBK == 'subscription') return;
    history.push(`/offers/${item.offerId}`);
  };

  return (
    <Box title={JSON.stringify(order, null, 4)}>
      <Stack direction="row">
        {order.isGiftOrder ?? (
          <Box title="Request is for gift order">
            <CardGiftcardIcon />
          </Box>
        )}
        {order.hasBedbankPromotion && (
          <Box title="Has Bedbank Promotion">
            <CardGiftcardIcon />
          </Box>
        )}
        {'clientOrderVersion' in order && isDevMode && (
          <Box title="(the version of the client order used)">
            <Chip component="span" color="primary" label={order.clientOrderVersion} />
          </Box>
        )}
      </Stack>

      {order.items.sort(sortPromoItemByCategoryAndId).map((item, i) => (
        <Grid container key={`${item.offerId}_${i}`} spacing={1} title={`${JSON.stringify(item, null, 4)}`}>
          <BorderedGridItem>
            {currencyFormatter(currencyCode, item.luxPlusPrice || item.discountableTotal)}
            {item.luxPlusPrice > 0 && (
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  display: 'inline',
                  fontSize: 14,
                  fontWeight: 300,
                  textDecoration: 'line-through',
                  marginLeft: 1,
                }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {currencyFormatter(currencyCode, item.discountableTotal)}
              </Typography>
            )}
          </BorderedGridItem>
          <BorderedGridItem onClick={() => handleGridClick(item)}>
            {meta && getPromoProductDisplayName(meta.promo_product_types, item.categoryBK, item.subCategoryBK)}
          </BorderedGridItem>
          {item.itemCountryCode && <BorderedGridItem>{item.itemCountryCode}</BorderedGridItem>}
          {item.numberOfNights && <BorderedGridItem>N:{item.numberOfNights}</BorderedGridItem>}
          {item.numberOfAdults && <BorderedGridItem>A:{item.numberOfAdults}</BorderedGridItem>}
          {item.numberOfChildren && <BorderedGridItem>C:{item.numberOfChildren}</BorderedGridItem>}
        </Grid>
      ))}
    </Box>
  );
}

export { PromoOrderTableCell };
