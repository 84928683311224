import React from 'react';

import { Grid, List, ListItem, Stack, Typography } from '@mui/material';

import { definitions } from '@luxuryescapes/contract-svc-promo';

import BorderedGridItem from '~/components/Common/BorderedGridItem';

import { getPromoProductDisplayName } from '~/services/PromoService';

import currencyFormatter from '~/utils/currencyFormatter';

type PromoResponseItemProps = {
  item: definitions['Discount Response']['promo']['items'][0];
  currencyCode: string;
  handleGridClick: (item: definitions['Discount Response']['promo']['items'][0]) => void;
  meta: definitions['PromoMeta'];
  isDev: boolean;
};

function PromoResponseItem({ item, currencyCode, handleGridClick, meta, isDev }: PromoResponseItemProps) {
  const hasDiscount = item.discountAmount > 0;
  const isLuxPlusPrice = item.luxPlusPrice < item.discountableTotal;
  return (
    <Grid
      container
      key={`${item.offerId || item.itemId}`}
      spacing={1}
      sx={{
        marginBottom: 1,
        border: '1px solid',
        borderColor: 'grey.400',
      }}
      onClick={() => handleGridClick(item)}
      title={`${JSON.stringify(item, null, 4)}`}
    >
      <BorderedGridItem>
        <Stack direction="row">
          {hasDiscount && (
            <Typography component="span" color={isLuxPlusPrice ? 'blue' : 'text.primary'}>
              {currencyFormatter(currencyCode, item.discountableTotal - item.discountAmount)}
            </Typography>
          )}
          <Typography
            sx={{
              textDecoration: hasDiscount ? 'line-through' : '',
            }}
            component="span"
            color={isLuxPlusPrice ? 'blue' : 'text.primary'}
          >
            {currencyFormatter(currencyCode, item.discountableTotal)}
          </Typography>
          {hasDiscount && (
            <Typography color="green" component="span">
              ({currencyFormatter(currencyCode, item.discountAmount)})
            </Typography>
          )}
        </Stack>
      </BorderedGridItem>
      <BorderedGridItem>
        <Typography component="span" color="text.primary">
          {meta && getPromoProductDisplayName(meta.promo_product_types, item.categoryBK, item.subCategoryBK)}
          {item.reservationType ? ` / ${item.reservationType}` : ''}
          {item.warning && ` - ${item.warning}`}
        </Typography>
      </BorderedGridItem>
      {item.itemCountryCode && (
        <BorderedGridItem xs title="Item Country Code">
          <Typography component="span" color="text.primary">
            {item.itemCountryCode}
          </Typography>
        </BorderedGridItem>
      )}
      {item.numberOfNights && <BorderedGridItem title="Number of Nights">N:{item.numberOfNights}</BorderedGridItem>}
      {item.numberOfAdults ||
        (item.numberOfChildren && (
          <BorderedGridItem title="Occupancy">
            A:{item.numberOfAdults},{item.numberOfChildren ? `C:${item.numberOfChildren}` : ''}
          </BorderedGridItem>
        ))}
      {isDev && item.travellers?.length && (
        <BorderedGridItem>
          <List>
            {item.travellers.map((t, i) => (
              <ListItem key={i}>
                {t.firstName} {t.lastName}
              </ListItem>
            ))}
          </List>
        </BorderedGridItem>
      )}
    </Grid>
  );
}

export { PromoResponseItem };
