import React, { Fragment, useMemo, useState } from 'react';

import { CheckCircleOutline, DeleteForever } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  Divider,
  FormControlLabel,
  Paper,
  Radio,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import { chipColorByStatus } from '../common';

import RoomSourceContentModal from './RoomSourceContentModal';
import ToBeMappedRoomElement from './ToBeMappedRoomElement';
import type { MappedRoom, MappingChange, PropertyRoom, SourceRoom, Suppliers } from './types';
import { generateOccupancyText, generateRoomSizeText, generateViewsText } from './util';

interface Props {
  room: PropertyRoom;
  onSelect: (room: PropertyRoom, supplier: Suppliers) => void;
  isSelected: boolean;
  onRemoveMappedRoom: (room: MappedRoom) => void;
  verifyMappedRoomModel: (room: MappedRoom) => void;
  newMappings: Array<MappingChange>;
  appliedButNotSavedRooms: Array<SourceRoom>;
  clearNewMappings: () => void;
  toRemove: App.Bedbank.SupplierRoomMappingPayload;
}

export default function ControlRoomElement({
  room,
  onSelect,
  isSelected,
  onRemoveMappedRoom,
  verifyMappedRoomModel,
  newMappings,
  appliedButNotSavedRooms,
  clearNewMappings,
  toRemove,
}: Props) {
  const [confirmDelOpen, setConfirmDelOpen] = useState<MappedRoom | null>(null);
  // Used to keep track of items marked as applied but not saved (We want to remove those items from the to be verified list)
  const [hiddenCurrentMappings, setHiddenCurrentMappings] = useState<MappedRoom[]>([]);

  const currentMappings = useMemo(() => {
    const filteredRooms = room.mappedRooms.filter(
      (sp) =>
        sp.supplier !== 'expedia' &&
        !toRemove?.find((tr) => sp.externalId === tr.supplierRoomId && sp.supplier === tr.supplier),
    );

    // remove all rooms that have the same externalId as the hiddenCurrentMappings
    const filteredHiddenCurrentMappings = filteredRooms.filter(
      (sp) => !hiddenCurrentMappings.find((hcm) => hcm.externalId === sp.externalId),
    );

    return filteredHiddenCurrentMappings;
  }, [room.mappedRooms, toRemove, hiddenCurrentMappings]);

  const handleVerifyMappedRoomModel = (mappedRoom: MappedRoom) => {
    setHiddenCurrentMappings([...hiddenCurrentMappings, mappedRoom]);
    verifyMappedRoomModel(mappedRoom);
  };

  const handleClearNewMappings = () => {
    setHiddenCurrentMappings([]);
    clearNewMappings();
  };

  const expediaMappedRoom = room.mappedRooms.find((r) => r.supplier === 'expedia')?.sourceContent;

  const views = generateViewsText(expediaMappedRoom);

  const bedGroups = expediaMappedRoom?.bedGroups.map((x) => x.description).join(', ');
  const amenities = expediaMappedRoom?.amenities ?? [];

  const roomSizeSQM = expediaMappedRoom?.sourceContent?.area?.square_meters;
  const roomSizeSFT = expediaMappedRoom?.sourceContent?.area?.square_feet;
  let roomSizeText = roomSizeSQM ? `${roomSizeSQM} m²` : '';
  if (roomSizeSFT) {
    roomSizeText += roomSizeText ? ` / ${roomSizeSFT} ft² ` : `${roomSizeSFT} ft² `;
  }

  if (!roomSizeText) {
    roomSizeText = expediaMappedRoom?.roomSize?.toString() || 'N/A';
  }

  // We use descriptions from source content as html tags are preserved
  const descriptions = expediaMappedRoom?.sourceContent?.descriptions?.overview;
  const { occupancyAge, occupancyAllowed } = generateOccupancyText(expediaMappedRoom);

  const verifyRemoveMappedRoom = (mappedRoom: MappedRoom) => {
    if (!mappedRoom.isVerified) {
      onRemoveMappedRoom(mappedRoom);
    } else {
      setConfirmDelOpen(mappedRoom);
    }
  };

  const confirmMappedRoomDelete = () => {
    onRemoveMappedRoom(confirmDelOpen);
    setConfirmDelOpen(null);
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        p: 2,
        backgroundColor: !isSelected ? 'grey.100' : undefined,
        borderColor: isSelected ? 'info.light' : undefined,
      }}
    >
      <Stack direction="column" gap={1}>
        <FormControlLabel
          checked={isSelected}
          onChange={() => onSelect(room, 'expedia')}
          control={<Radio />}
          label={
            <Stack direction="row" justifyContent="space-between" width="100%" spacing={2} alignItems="center">
              <Typography variant="h6">{room.name}</Typography>
              {room.status && (
                <Chip
                  label={room.status}
                  color={chipColorByStatus[room.status] ?? 'default'}
                  size="small"
                  sx={{ justifySelf: 'flex-end' }}
                />
              )}
            </Stack>
          }
          componentsProps={{ typography: { variant: 'h6' } }}
        />
        <Stack direction="column" gap={1}>
          <Stack direction="column">
            {room.externalId && (
              <Typography>
                <strong>Supplier | External ID:</strong> {room.externalId}
              </Typography>
            )}
            {views && (
              <Typography>
                <strong>Views:</strong> {views}
              </Typography>
            )}
            {bedGroups && (
              <Typography>
                <strong>Bed Groups:</strong> {bedGroups}
              </Typography>
            )}
            {roomSizeText && (
              <Typography>
                <strong>Room Size:</strong> {roomSizeText}
              </Typography>
            )}
            {occupancyAge && (
              <Typography>
                <strong>Occupancy Ages:</strong> {occupancyAge}
              </Typography>
            )}
            {occupancyAllowed && (
              <Typography>
                <strong>Occupancy Allowed:</strong> {occupancyAllowed}
              </Typography>
            )}
          </Stack>
          <Stack direction="column">
            {descriptions && (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>Description</AccordionSummary>
                <AccordionDetails>
                  <div dangerouslySetInnerHTML={{ __html: descriptions }}></div>
                </AccordionDetails>
              </Accordion>
            )}
            {amenities && (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>Amenities</AccordionSummary>
                <AccordionDetails>
                  <Box component="ul" m={0}>
                    {amenities.map((amenity) => (
                      <li key={amenity}>{amenity}</li>
                    ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            )}
          </Stack>
        </Stack>
        <Stack direction="column" gap={2}>
          <Stack direction="column">
            {currentMappings.map((mappedRoom) => (
              <Accordion
                key={mappedRoom.id}
                defaultExpanded={!mappedRoom.isVerified}
                sx={{
                  borderColor: mappedRoom.isVerified ? 'success.light' : 'warning.light',
                  borderWidth: '2px',
                  borderStyle: 'solid',
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Stack direction="row" gap={1}>
                    {mappedRoom.isVerified && <Chip label="Verified" color="success" size="small" />}
                    {!mappedRoom.isVerified && <Chip label="Unverified" color="warning" size="small" />}
                    <span>
                      ({mappedRoom.supplier}){' '}
                      {(mappedRoom.sourceContent?.description && mappedRoom.sourceContent?.description !== 'NA'
                        ? mappedRoom?.sourceContent?.description
                        : mappedRoom?.sourceContent?.name) ?? 'None Provided'}{' '}
                      - {mappedRoom.externalId}
                    </span>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction="column">
                    <Box>
                      <Typography>
                        <strong>Supplier | External ID:</strong> {`(${mappedRoom.supplier}) ${mappedRoom.externalId}`}
                      </Typography>
                      <Typography>
                        <strong>Views:</strong> {generateViewsText(mappedRoom.sourceContent)}
                      </Typography>
                      <Typography>
                        <strong>Room Size:</strong> {generateRoomSizeText(mappedRoom.sourceContent)}
                      </Typography>
                      {
                        <Typography>
                          <strong>Occupancy Age:</strong>{' '}
                          {generateOccupancyText(mappedRoom.sourceContent).occupancyAge || 'N/A'}
                        </Typography>
                      }
                      {
                        <Typography>
                          <strong>Occupancy Allowed:</strong>{' '}
                          {generateOccupancyText(mappedRoom.sourceContent).occupancyAllowed || 'N/A'}
                        </Typography>
                      }

                      <Typography>
                        <strong>Description</strong> {mappedRoom.sourceContent?.description || 'N/A'}
                      </Typography>

                      {mappedRoom.mappingMethod && (
                        <Typography>
                          <strong>Mapping Method:</strong>{' '}
                          {mappedRoom.mappingMethod.length ? (
                            mappedRoom.mappingMethod?.join(', ')
                          ) : (
                            <em>None Provided</em>
                          )}
                        </Typography>
                      )}

                      <Typography>
                        <strong>Availability Score:</strong> {mappedRoom.sourceContent?.availabilityScore ?? 'N/A'}
                      </Typography>

                      <Typography>
                        <strong>Updated At:</strong>{' '}
                        {mappedRoom.updatedAt ? new Date(mappedRoom.updatedAt).toLocaleString() : 'No date found.'}
                      </Typography>
                    </Box>
                  </Stack>
                </AccordionDetails>
                <AccordionActions>
                  <RoomSourceContentModal sourceContent={mappedRoom.sourceContent} />
                  {mappedRoom.supplier !== 'expedia' && (
                    <Tooltip title="Remove Existing Mapping For Source Room">
                      <Button
                        startIcon={<DeleteForever />}
                        onClick={() => verifyRemoveMappedRoom(mappedRoom)}
                        variant="contained"
                        color="error"
                      >
                        Remove
                      </Button>
                    </Tooltip>
                  )}
                  {!mappedRoom.isVerified && (
                    <Tooltip title="Verify Existing Mapping For Source Room">
                      <Button
                        startIcon={<CheckCircleOutline />}
                        onClick={() => handleVerifyMappedRoomModel(mappedRoom)}
                        variant="contained"
                        color="success"
                      >
                        Verify
                      </Button>
                    </Tooltip>
                  )}
                </AccordionActions>
              </Accordion>
            ))}
          </Stack>
          {newMappings?.length > 0 && (
            <>
              <Divider />
              <Stack direction="column" gap={1}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="h6" color="info.main">
                    New Mappings
                  </Typography>
                  <Button
                    startIcon={<DeleteForever />}
                    onClick={handleClearNewMappings}
                    variant="contained"
                    color="error"
                  >
                    Clear new Mappings
                  </Button>
                </Stack>
                <Stack direction="column">
                  {newMappings.map((mapping) => (
                    <ToBeMappedRoomElement
                      key={`${mapping.supplier}-${mapping.externalId}`}
                      mapping={mapping}
                      appliedButNotSavedRooms={appliedButNotSavedRooms}
                    />
                  ))}
                </Stack>
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
      <Dialog open={Boolean(confirmDelOpen)} onClose={() => setConfirmDelOpen(null)}>
        {confirmDelOpen && (
          <>
            <DialogTitle>Are you sure you want to delete this verified mapping?</DialogTitle>
            <Stack direction="row" justifyContent="space-between" p={3}>
              <Button onClick={() => setConfirmDelOpen(null)} variant="outlined" color="primary">
                Cancel
              </Button>
              <Button onClick={confirmMappedRoomDelete} variant="contained" color="error">
                Confirm Delete
              </Button>
            </Stack>
          </>
        )}
      </Dialog>
    </Paper>
  );
}
