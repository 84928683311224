import React, { useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { RefundRequestRefundOptions, RefundRequestStatuses } from '~/consts/refundRequests';

import { formatDateOrdinalWithClock } from '~/services/TimeService';

import { getOrderDatesRequest, getRefundRequestsByOrderId } from '../../../services/OrdersService';
import Spinner from '../../Common/Spinner';

interface OrderDetailProps {
  orderId: string;
}

interface BookingRequest {
  type: string;
  id: string;
  created_at: string;
  updated_at: string;
  statusText: string;
  statusSeverity: string;
}

const typeFormatter = (cell) => {
  if (cell === 'bnbl') {
    return 'BNBL request';
  }
  return 'Change date request';
};

const mapOrderDatesToBookingRequest = (orderDates: any[]): BookingRequest[] => {
  const statusMap = {
    pending: 'warning',
    approved: 'success',
    rejected: 'error',
    customer_booked: 'success',
    customer_cancelled: 'error',
  };
  const textMap = {
    pending: 'Request sent',
    approved: 'Request approved',
    rejected: 'Request rejected',
    customer_booked: 'Customer booked',
    customer_cancelled: 'Customer cancelled',
  };

  return orderDates.map((orderDate) => ({
    type: typeFormatter(orderDate.type),
    id: orderDate.id_dates_request,
    created_at: orderDate.created_at,
    updated_at: orderDate.updated_at,
    statusText: textMap[orderDate.status],
    statusSeverity: statusMap[orderDate.status],
  }));
};

const getRefundRequestStatus = (status: string, refundOption?: string) => {
  if (status === RefundRequestStatuses.rejected) {
    if (refundOption === RefundRequestRefundOptions.letter) {
      return { text: 'Request Rejected - Insurance Letter Downloaded', severity: 'error' };
    } else {
      return { text: 'Request Rejected - Insurance Letter Presented', severity: 'error' };
    }
  }

  if (status === RefundRequestStatuses.approved) {
    if (refundOption === RefundRequestRefundOptions.credit) {
      return { text: '100% LE Credit Refund', severity: 'success' };
    } else if (refundOption === RefundRequestRefundOptions.backToOrigin) {
      return { text: '80% Original Payment', severity: 'success' };
    } else if (refundOption === RefundRequestRefundOptions.letter) {
      return { text: 'Insurance Letter Downloaded', severity: 'success' };
    } else {
      return { text: 'Request Approved - Pending Customer Response', severity: 'success' };
    }
  }

  if (status === RefundRequestStatuses.expired) {
    return { text: 'Refund Options Expired', severity: 'success' };
  }

  if (status === RefundRequestStatuses.pending) {
    return { text: 'Request Pending', severity: 'none' };
  }

  if (status === RefundRequestStatuses.cancelled) {
    return { text: 'Request Withdrawn', severity: 'none' };
  }

  return { text: 'Unknown Status', severity: 'info' };
};

const mapRefundRequestsToBookingRequest = (refundRequests: RefundRequest[]): BookingRequest[] => {
  return refundRequests.map((refundRequest) => {
    const { text: statusText, severity: statusSeverity } = getRefundRequestStatus(
      refundRequest.status,
      refundRequest.refund_option,
    );

    return {
      type: 'Refunds outside of policy request',
      id: refundRequest.id_refund_request,
      created_at: refundRequest.created_at,
      updated_at: refundRequest.updated_at,
      statusText,
      statusSeverity,
    };
  });
};

const columns: GridColDef[] = [
  {
    field: 'type',
    headerName: 'Type',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueFormatter: (value) => formatDateOrdinalWithClock(new Date(value)),
    display: 'flex',
  },
  {
    field: 'status',
    headerName: 'Action/Status',
    sortable: false,
    disableColumnMenu: true,
    flex: 2,
    renderCell: (params) => {
      return <Alert severity={params.row.statusSeverity}>{params.row.statusText}</Alert>;
    },
    display: 'flex',
  },
  {
    field: 'updated_at',
    headerName: 'Last Updated',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueFormatter: (value) => formatDateOrdinalWithClock(new Date(value)),
    display: 'flex',
  },
];

const OrderDetailBookingRequests: React.FC<OrderDetailProps> = ({ orderId }) => {
  const [bookingRequests, setBookingRequests] = useState<BookingRequest[]>([]);
  const [error, setError] = useState<string>();

  useEffect(() => {
    const fetchBookingRequests = async () => {
      try {
        const orderDates = await getOrderDatesRequest(orderId);
        const refundRequests = await getRefundRequestsByOrderId(orderId);

        // Map data to the booking requests structure
        const mappedOrderDates = mapOrderDatesToBookingRequest(orderDates);
        const mappedRefundRequests = mapRefundRequestsToBookingRequest(refundRequests);

        setBookingRequests([...mappedOrderDates, ...mappedRefundRequests]);
      } catch {
        setError('Could not load booking requests');
      }
    };

    fetchBookingRequests();
  }, [orderId]);

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }
  if (!bookingRequests) {
    return <Spinner />;
  }
  if (bookingRequests.length === 0) {
    return null;
  }

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        sx={{
          backgroundColor: 'grey.200',
          height: '60px',
        }}
      >
        <Typography>Booking Requests</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'created_at', sort: 'desc' }],
            },
          }}
          columns={columns}
          rows={bookingRequests}
          getRowId={(row) => `${row.type}-${row.id}`}
          autoHeight
          pageSizeOptions={[]}
          disableColumnMenu
          disableRowSelectionOnClick
          hideFooter
          getRowHeight={() => 'auto'}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default OrderDetailBookingRequests;
