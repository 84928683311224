export enum RefundRequestRefundOptions {
  letter = 'letter',
  backToOrigin = 'back_to_origin',
  credit = 'credit',
}

export enum RefundRequestStatuses {
  pending = 'pending',
  approved = 'approved',
  cancelled = 'cancelled',
  rejected = 'rejected',
  expired = 'expired',
}
